<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div class="item-box">
                <div class="item-title text-center">年齢確認</div>
                <div class="item-description" style="padding: 10px 40px">
                  年齢確認を行うため、公的証明書の写しをいずれか一つをご提出ください。<br />
                  ・運転免許証<br />
                  ・健康保険証<br />
                  ・パスポート<br />
                  ・住民基本台帳カード<br />
                  ・在留カード<br />
                  ・マイナンバーカー<br />
                  また提出の際は下記情報が確認できるようお願いします。<br />
                  ・氏名<br />
                  ・生年月日<br />
                  ・有効期限<br />
                  ・証明書種類<br />
                  ・発行元<br />
                  提出後24時間以内に確認をいたします。
                </div>
                <div style="padding-top: 10px">
                  <div v-if="imageUrl">
                    <img :src="imageUrl" class="img-icon" />
                  </div>
                  <div v-else>
                    <div
                      class="text-center"
                      style="
                        height: 300px;
                        width: auto;
                        background-color: #ddd;
                        padding-top: 120px;
                        border-radius: 10px;
                      "
                    >
                      <span
                        class="material-symbols-rounded"
                        style="color: #fff; font-size: 50px"
                      >
                        hide_image
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-center" style="padding: 10px 0">
                  <input
                    id="file-icon-upload"
                    type="file"
                    style="display: none"
                    @change="fileUpload"
                    ref="fileInput"
                  />
                  <a
                    type="button"
                    class="btn btn-secondary btn-sm"
                    for="file-icon-upload"
                    @click="this.$refs.fileInput.click()"
                  >
                    書類をアップする
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px; background-color: #fff">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="update"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db, storage } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { store, removeUserIconUrl } from "../../../plugins/store";
import imageCompression from "browser-image-compression";

export default {
  data() {
    return {
      uid: "",
      user: {},
      imageUrl: null,
      iconFile: null,
      img_urls: [],
      files: [],
      isUploadError: false,
      isEntry: true,
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = "提出する";

    this.user = store.user;
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  components: {
    AppHeader,
  },
  methods: {
    fileUpload(event) {
      const file = event.target.files[0];

      this.imageUrl = URL.createObjectURL(file);
      this.iconFile = file;
    },
    handleBeforeUnload(event) {
      // eslint-disable-next-line no-unused-vars
      event = null;
      removeUserIconUrl();
    },
    async update() {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(this.iconFile, options);
        const fileRef = storageRef(
          storage,
          `users/file/${this.user.uid}/identification.png`,
        );
        await uploadBytes(fileRef, compressedFile);

        const docUpdateUsersRef = doc(db, "users", this.user.uid);
        await updateDoc(docUpdateUsersRef, {
          isIdentificationSend: true,
        });

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/mypage/setting/completion");
        }
      } catch (error) {
        console.error("Error uploading file:", error);

        this.$toast.show("投稿時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.img-icon {
  width: 100%;
  height: auto;
}
</style>
