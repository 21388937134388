<template>
  <div class="subtitle-box">
    <div class="subtitle">性癖</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/seiheki/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div v-for="(question, i) in questions" :key="i">
    <div style="padding-left: 20px; padding-top: 8px; font-size: 14px">
      {{ question.name }}
    </div>
    <div class="line-body">
      <div
        class="line line-start"
        :style="
          question.value >= 1
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 2
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 3
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 4
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 5
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line line-end"
        :style="
          question.value >= 6
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      isMod: false,
      questions: [
        { key: "question36", value: 0, name: "SEX" },
        { key: "question37", value: 0, name: "キス・ハグ" },
        { key: "question38", value: 0, name: "フェラ・クンニ" },
        { key: "question39", value: 0, name: "イラマチオ" },
        { key: "question40", value: 0, name: "アナル" },
        { key: "question41", value: 0, name: "コスプレ" },
        { key: "question42", value: 0, name: "撮影" },
        { key: "question43", value: 0, name: "首絞め" },
        { key: "question44", value: 0, name: "噛み跡" },
        { key: "question45", value: 0, name: "露出願望" },
        { key: "question46", value: 0, name: "ボイジャー（覗きたい）" },
        {
          key: "question47",
          value: 0,
          name: "スイッチ（攻と受どちらも楽しめる）",
        },
        { key: "question48", value: 0, name: "実験家（新しい遊びをしたい）" },
        {
          key: "question49",
          value: 0,
          name: "エイジプレイヤー（年の差が好き）",
        },
        { key: "question50", value: 0, name: "バニラ（一般的なプレイが好き）" },
        {
          key: "question51",
          value: 0,
          name: "ノンモノガミスト（複数プレイが好き）",
        },
        {
          key: "question52",
          value: 0,
          name: "モノガミスト（一人の人とプレイしたい）",
        },
        {
          key: "question53",
          value: 0,
          name: "サピオシャス（知能が優れた人が好き）",
        },
        { key: "question54", value: 0, name: "鞭・打具を使うプレイ" },
        { key: "question55", value: 0, name: "拘束具を使うプレイ" },
        { key: "question56", value: 0, name: "玩具を使うプレイ" },
        { key: "question57", value: 0, name: "蝋燭を使うプレイ" },
        { key: "question58", value: 0, name: "緊縛プレイ" },
        { key: "question59", value: 0, name: "マミフィケーションプレイ" },
        { key: "question60", value: 0, name: "全身タイツプレイ" },
        { key: "question61", value: 0, name: "ぐりぐら" },
        {
          key: "question62",
          value: 0,
          name: "スローセックス・ポリネシアンセックス",
        },
        { key: "question63", value: 0, name: "スパンキング" },
      ],
      questionList: [
        "question36",
        "question37",
        "question38",
        "question39",
        "question40",
        "question41",
        "question42",
        "question43",
        "question44",
        "question45",
        "question46",
        "question47",
        "question48",
        "question49",
        "question50",
        "question51",
        "question52",
        "question53",
        "question54",
        "question55",
        "question56",
        "question57",
        "question58",
        "question59",
        "question60",
        "question61",
        "question62",
        "question63",
      ],
    };
  },
  props: ["data", "is_mod"],
  created() {
    this.user = this.data;
    this.isMod = this.is_mod;

    for (var q in this.questionList) {
      const item = this.questions.find(
        (item) => item.key === this.questionList[q],
      );
      item.value = this.user[this.questionList[q]];
    }
  },
  methods: {},
};
</script>

<style scoped>
.line-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3px 20px;
}
.line {
  width: calc(100% / 6);
  height: 20px;
  border: solid 1px #fff;
  background-color: rgb(225, 213, 236);
}
.line-start {
  border-radius: 4px 0 0 4px;
}
.line-end {
  border-radius: 0 4px 4px 0;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  margin-top: 30px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
a {
  color: #555 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #555 !important;
  text-decoration: underline !important;
}
</style>
