<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  あなたの好きな性癖・プレイを教えてください<br />
                  1:全く当てはまらない<br />
                  2:当てはまらない<br />
                  3:あまり当てはまらない<br />
                  4:やや当てはまる<br />
                  5:当てはまる<br />
                  6:とても当てはまる
                </div>
              </div>
              <div class="item-box">
                <hr />
                <div v-for="(item, i) in this.questionList" :key="i">
                  <div class="item-title text-center">{{ item.label }}</div>
                  <div style="padding: 10px 0">
                    <v-slider
                      v-model="item.value"
                      color="#784ba0"
                      :max="6"
                      :min="1"
                      show-ticks="always"
                      :ticks="tickLabels"
                      :step="1"
                    ></v-slider>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      sadistic: 1,
      tickLabels: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
      questionList: {
        question36: {
          label: "SEX",
          column: "question36",
          value: 1,
        },
        question37: {
          label: "キス・ハグ",
          column: "question37",
          value: 1,
        },
        question38: {
          label: "フェラ・クンニ",
          column: "question38",
          value: 1,
        },
        question39: {
          label: "イラマチオ",
          column: "question39",
          value: 1,
        },
        question40: {
          label: "アナル",
          column: "question40",
          value: 1,
        },
        question41: {
          label: "コスプレ",
          column: "question41",
          value: 1,
        },
        question42: {
          label: "撮影",
          column: "question42",
          value: 1,
        },
        question43: {
          label: "首絞め",
          column: "question43",
          value: 1,
        },
        question44: {
          label: "噛み跡",
          column: "question44",
          value: 1,
        },
        question45: {
          label: "露出願望",
          column: "question45",
          value: 1,
        },
        question46: {
          label: "ボイジャー（覗きたい）",
          column: "question46",
          value: 1,
        },
        question47: {
          label: "スイッチ（攻と受どちらも楽しめる）",
          column: "question47",
          value: 1,
        },
        question48: {
          label: "実験家（新しい遊びをしたい）",
          column: "question48",
          value: 1,
        },
        question49: {
          label: "エイジプレイヤー（年の差が好き）",
          column: "question49",
          value: 1,
        },
        question50: {
          label: "バニラ（一般的なプレイが好き）",
          column: "question50",
          value: 1,
        },
        question51: {
          label: "ノンモノガミスト（複数プレイが好き）",
          column: "question51",
          value: 1,
        },
        question52: {
          label: "モノガミスト（一人の人とプレイしたい）",
          column: "question52",
          value: 1,
        },
        question53: {
          label: "サピオシャス（知能が優れた人が好き）",
          column: "question53",
          value: 1,
        },
        question54: {
          label: "鞭・打具を使うプレイ",
          column: "question54",
          value: 1,
        },
        question55: {
          label: "拘束具を使うプレイ",
          column: "question55",
          value: 1,
        },
        question56: {
          label: "玩具を使うプレイ",
          column: "question56",
          value: 1,
        },
        question57: {
          label: "蝋燭を使うプレイ",
          column: "question57",
          value: 1,
        },
        question58: {
          label: "緊縛プレイ",
          column: "question58",
          value: 1,
        },
        question59: {
          label: "マミフィケーションプレイ",
          column: "question59",
          value: 1,
        },
        question60: {
          label: "全身タイツプレイ",
          column: "question60",
          value: 1,
        },
        question61: {
          label: "ぐりぐら",
          column: "question61",
          value: 1,
        },
        question69: {
          label: "ソフレ",
          column: "question69",
          value: 1,
        },
        question70: {
          label: "舐め犬",
          column: "question70",
          value: 1,
        },
        question62: {
          label: "スローセックス・ポリネシアンセックス",
          column: "question62",
          value: 1,
        },
        question63: {
          label: "スパンキング",
          column: "question63",
          value: 1,
        },
      },
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    if (this.$route.params.type == "mod") {
      this.questionList.question36.value1 =
        this.user.question36 == undefined ? 0 : this.user.question36;
      this.questionList.question37.value1 =
        this.user.question37 == undefined ? 0 : this.user.question37;
      this.questionList.question38.value1 =
        this.user.question38 == undefined ? 0 : this.user.question38;
      this.questionList.question39.value1 =
        this.user.question39 == undefined ? 0 : this.user.question39;
      this.questionList.question40.value1 =
        this.user.question40 == undefined ? 0 : this.user.question40;
      this.questionList.question41.value1 =
        this.user.question41 == undefined ? 0 : this.user.question41;
      this.questionList.question42.value1 =
        this.user.question42 == undefined ? 0 : this.user.question42;
      this.questionList.question43.value1 =
        this.user.question43 == undefined ? 0 : this.user.question43;
      this.questionList.question44.value1 =
        this.user.question44 == undefined ? 0 : this.user.question44;
      this.questionList.question45.value1 =
        this.user.question45 == undefined ? 0 : this.user.question45;
      this.questionList.question46.value1 =
        this.user.question46 == undefined ? 0 : this.user.question46;
      this.questionList.question47.value1 =
        this.user.question47 == undefined ? 0 : this.user.question47;
      this.questionList.question48.value1 =
        this.user.question48 == undefined ? 0 : this.user.question48;
      this.questionList.question49.value1 =
        this.user.question49 == undefined ? 0 : this.user.question49;
      this.questionList.question50.value1 =
        this.user.question50 == undefined ? 0 : this.user.question50;
      this.questionList.question51.value1 =
        this.user.question51 == undefined ? 0 : this.user.question51;
      this.questionList.question52.value1 =
        this.user.question52 == undefined ? 0 : this.user.question52;
      this.questionList.question53.value1 =
        this.user.question53 == undefined ? 0 : this.user.question53;
      this.questionList.question54.value1 =
        this.user.question54 == undefined ? 0 : this.user.question54;
      this.questionList.question55.value1 =
        this.user.question55 == undefined ? 0 : this.user.question55;
      this.questionList.question56.value1 =
        this.user.question56 == undefined ? 0 : this.user.question56;
      this.questionList.question57.value1 =
        this.user.question57 == undefined ? 0 : this.user.question57;
      this.questionList.question58.value1 =
        this.user.question58 == undefined ? 0 : this.user.question58;
      this.questionList.question59.value1 =
        this.user.question59 == undefined ? 0 : this.user.question59;
      this.questionList.question60.value1 =
        this.user.question60 == undefined ? 0 : this.user.question60;
      this.questionList.question61.value1 =
        this.user.question61 == undefined ? 0 : this.user.question61;
      this.questionList.question62.value1 =
        this.user.question62 == undefined ? 0 : this.user.question62;
      this.questionList.question63.value1 =
        this.user.question63 == undefined ? 0 : this.user.question63;
      this.questionList.question69.value1 =
        this.user.question69 == undefined ? 0 : this.user.question69;
      this.questionList.question70.value1 =
        this.user.question70 == undefined ? 0 : this.user.question70;

      this.questionList.question36.value = this.user.question36;
      this.questionList.question37.value = this.user.question37;
      this.questionList.question38.value = this.user.question38;
      this.questionList.question39.value = this.user.question39;
      this.questionList.question40.value = this.user.question40;
      this.questionList.question41.value = this.user.question41;
      this.questionList.question42.value = this.user.question42;
      this.questionList.question43.value = this.user.question43;
      this.questionList.question44.value = this.user.question44;
      this.questionList.question45.value = this.user.question45;
      this.questionList.question46.value = this.user.question46;
      this.questionList.question47.value = this.user.question47;
      this.questionList.question48.value = this.user.question48;
      this.questionList.question49.value = this.user.question49;
      this.questionList.question50.value = this.user.question50;
      this.questionList.question51.value = this.user.question51;
      this.questionList.question52.value = this.user.question52;
      this.questionList.question53.value = this.user.question53;
      this.questionList.question54.value = this.user.question54;
      this.questionList.question55.value = this.user.question55;
      this.questionList.question56.value = this.user.question56;
      this.questionList.question57.value = this.user.question57;
      this.questionList.question58.value = this.user.question58;
      this.questionList.question59.value = this.user.question59;
      this.questionList.question60.value = this.user.question60;
      this.questionList.question61.value = this.user.question61;
      this.questionList.question62.value = this.user.question62;
      this.questionList.question63.value = this.user.question63;
      this.questionList.question69.value = this.user.question69;
      this.questionList.question70.value = this.user.question70;
    }
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          question36: this.questionList.question36.value,
          question37: this.questionList.question37.value,
          question38: this.questionList.question38.value,
          question39: this.questionList.question39.value,
          question40: this.questionList.question40.value,
          question41: this.questionList.question41.value,
          question42: this.questionList.question42.value,
          question43: this.questionList.question43.value,
          question44: this.questionList.question44.value,
          question45: this.questionList.question45.value,
          question46: this.questionList.question46.value,
          question47: this.questionList.question47.value,
          question48: this.questionList.question48.value,
          question49: this.questionList.question49.value,
          question50: this.questionList.question50.value,
          question51: this.questionList.question51.value,
          question52: this.questionList.question52.value,
          question53: this.questionList.question53.value,
          question54: this.questionList.question54.value,
          question55: this.questionList.question55.value,
          question56: this.questionList.question56.value,
          question57: this.questionList.question57.value,
          question58: this.questionList.question58.value,
          question59: this.questionList.question59.value,
          question60: this.questionList.question60.value,
          question61: this.questionList.question61.value,
          question62: this.questionList.question62.value,
          question63: this.questionList.question63.value,
          question69: this.questionList.question69.value,
          question70: this.questionList.question70.value,
        });
        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          question36: this.questionList.question36.value,
          question37: this.questionList.question37.value,
          question38: this.questionList.question38.value,
          question39: this.questionList.question39.value,
          question40: this.questionList.question40.value,
          question41: this.questionList.question41.value,
          question42: this.questionList.question42.value,
          question43: this.questionList.question43.value,
          question44: this.questionList.question44.value,
          question45: this.questionList.question45.value,
          question46: this.questionList.question46.value,
          question47: this.questionList.question47.value,
          question48: this.questionList.question48.value,
          question49: this.questionList.question49.value,
          question50: this.questionList.question50.value,
          question51: this.questionList.question51.value,
          question52: this.questionList.question52.value,
          question53: this.questionList.question53.value,
          question54: this.questionList.question54.value,
          question55: this.questionList.question55.value,
          question56: this.questionList.question56.value,
          question57: this.questionList.question57.value,
          question58: this.questionList.question58.value,
          question59: this.questionList.question59.value,
          question60: this.questionList.question60.value,
          question61: this.questionList.question61.value,
          question62: this.questionList.question62.value,
          question63: this.questionList.question63.value,
          question69: this.questionList.question69.value,
          question70: this.questionList.question70.value,
        });

        this.user.question36 = this.questionList.question36.value;
        this.user.question37 = this.questionList.question37.value;
        this.user.question38 = this.questionList.question38.value;
        this.user.question39 = this.questionList.question39.value;
        this.user.question40 = this.questionList.question40.value;
        this.user.question41 = this.questionList.question41.value;
        this.user.question42 = this.questionList.question42.value;
        this.user.question43 = this.questionList.question43.value;
        this.user.question44 = this.questionList.question44.value;
        this.user.question45 = this.questionList.question45.value;
        this.user.question46 = this.questionList.question46.value;
        this.user.question47 = this.questionList.question47.value;
        this.user.question48 = this.questionList.question48.value;
        this.user.question49 = this.questionList.question49.value;
        this.user.question50 = this.questionList.question50.value;
        this.user.question51 = this.questionList.question51.value;
        this.user.question52 = this.questionList.question52.value;
        this.user.question53 = this.questionList.question53.value;
        this.user.question54 = this.questionList.question54.value;
        this.user.question55 = this.questionList.question55.value;
        this.user.question56 = this.questionList.question56.value;
        this.user.question57 = this.questionList.question57.value;
        this.user.question58 = this.questionList.question58.value;
        this.user.question59 = this.questionList.question59.value;
        this.user.question60 = this.questionList.question60.value;
        this.user.question61 = this.questionList.question61.value;
        this.user.question62 = this.questionList.question62.value;
        this.user.question63 = this.questionList.question63.value;
        this.user.question69 = this.questionList.question69.value;
        this.user.question70 = this.questionList.question70.value;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push(
            "/mypage/setting/identification/" + this.$route.params.type,
          );
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
