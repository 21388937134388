<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
    <div class="like-list">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div v-if="headerImg != null">
                <img
                  :src="headerImg"
                  style="width: 100%; height: 120px; object-fit: cover"
                />
              </div>
              <div style="padding: 10px 15px">
                <div>
                  <div style="font-size: 26px; padding-bottom: 10px">
                    {{ event.title }}
                  </div>
                  <div style="font-size: 12px">
                    <i
                      class="bi bi-clock"
                      style="margin-right: 5px; font-size: 14px"
                    ></i
                    >{{ event.eventAtView }}
                  </div>
                  <div style="font-size: 12px">
                    <i
                      class="bi bi-person"
                      style="margin-right: 3px; font-size: 16px"
                    ></i
                    >{{ event.userName }}
                  </div>
                  <div style="font-size: 12px; display: flex">
                    <i
                      class="bi bi-at"
                      style="margin-right: 3px; font-size: 16px"
                    ></i>
                    <div
                      style="width: 100%; padding-right: 15px; padding-top: 3px"
                      v-html="autoLink(event.contact)"
                    ></div>
                  </div>
                  <div style="font-size: 12px">
                    <i
                      class="bi bi-geo-alt"
                      style="margin-right: 3px; font-size: 16px"
                    ></i
                    >{{ event.area }}
                  </div>
                </div>
                <div class="tab-box">
                  <div
                    @click="tabIndex = 1"
                    class="tab-item text-center"
                    :style="
                      tabIndex == 1
                        ? 'background-color: #784ba0; color:#fff; font-weight:600'
                        : 'background-color: #efefef; color:#888; font-weight:400'
                    "
                  >
                    About
                  </div>
                  <div
                    @click="tabIndex = 2"
                    class="tab-item text-center"
                    :style="
                      tabIndex == 2
                        ? 'background-color: #784ba0; color:#fff; font-weight:600'
                        : 'background-color: #efefef; color:#888; font-weight:400'
                    "
                  >
                    Photo
                  </div>
                </div>
                <div
                  v-if="tabIndex == 1"
                  style="padding-top: 15px"
                  class="preserve-linebreak"
                >
                  <div v-html="autoLink(event.detail)"></div>
                </div>
                <div v-if="tabIndex == 2" class="row" style="padding: 0 10px">
                  <div
                    v-for="(image, i) in listImg"
                    :key="i"
                    class="col-6"
                    style="padding: 10px 5px 0px 5px"
                  >
                    <div @click="handleModalView(i, listImg)">
                      <img
                        :src="image"
                        style="width: 100%; height: 200px; object-fit: cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isModal" class="modalView">
      <span
        class="material-symbols-rounded"
        style="
          color: #fff;
          font-size: 30px;
          position: absolute;
          right: 20px;
          top: 10px;
          z-index: 99999;
        "
        @click="isModal = false"
      >
        close
      </span>
      <img :src="modalImageUrl" @click="nextModal" />
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      tabIndex: 1,
      event: {},
      headerImg: null,
      listImg: [],
      isModal: false,
      modalImageUrl: "",
      modalImageIndex: 0,
      imageUrl: [],
    };
  },
  created() {
    this.user = store.user;
    this.event = store.event;
  },
  async mounted() {
    if (this.event.header && this.event.header.length != 0) {
      const imageRef = storageRef(storage, this.event.header[0]);

      try {
        const url = await getDownloadURL(imageRef);
        this.headerImg = url;
      } catch (error) {
        console.log("---------- event/headerView ---------");
        console.error("Error fetching image URL:", error);
      }
    }
    //イメージ
    for (var i in this.event.url) {
      const photoRef = storageRef(storage, this.event.url[i]);
      try {
        const urlPhoto = await getDownloadURL(photoRef);
        this.listImg.push(urlPhoto);
      } catch (error) {
        console.log("---------- event/headerView1 ---------");
        console.error("Error fetching image URL:", error);
      }
    }
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    autoLink(text) {
      if (text == undefined) {
        return "";
      }

      const urlPattern =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

      return text.replace(urlPattern, function (url) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    handleModalView(i, images) {
      this.isModal = true;
      this.imageUrl = images;
      this.modalImageUrl = images[i];
      this.modalImageIndex = i;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
  },
};
</script>

<style scoped>
.like-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 55px;
}
.tab-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  padding: 5px 0;
}
.tab-item {
  width: calc(100% / 2);
  height: 40px;
  font-size: 13px;
  border: solid 3px #fff;
  border-radius: 6px;
  padding-top: 6px;
}
.modalView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.669);
  z-index: 99999;
  padding: 30px;
}
.modalView img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  object-position: 50% 50% !important;
}
</style>
