<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" title="" type="none" />
    <div class="sheet">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div class="subtitle-box">
                <div class="subtitle">お気に入りイベントリスト</div>
              </div>
              <div v-if="items.length">
                <div v-for="(item, i) in items" :key="i" class="item">
                  {{ item }}
                </div>
              </div>
              <div v-else>
                <div class="text-center" style="font-size: 12px">
                  <br /><br /><br />
                  イベントはありません
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../plugins/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      items: [],
      isCreated: false,
    };
  },
  async created() {
    this.user = store.user;

    try {
      const q = query(
        collection(db, "users/" + this.user.uid + "/eventBookmarks"),
        orderBy("eventAt", "desc"),
      );

      const querySnapshot = await getDocs(q);

      this.items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
    } catch (error) {
      console.error("データの取得に失敗しました:", error);
    } finally {
      this.isCreated = true;
    }
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.sheet {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 60px;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
</style>
