<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="like-list">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <div class="subtitle-box">
              <div class="subtitle" v-if="isEntry">イベント登録</div>
              <div class="subtitle" v-else>イベント更新</div>
            </div>
            <div class="item-box">
              <div style="padding: 15px">
                <div class="item-title">ヘッダー画像</div>
              </div>
              <div v-if="headerImg == null">
                <div
                  class="text-center"
                  style="
                    width: 100%;
                    height: 120px;
                    background-color: #ddd;
                    color: #fff;
                    padding-top: 40px;
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 40px"
                  >
                    no_photography
                  </span>
                </div>
              </div>
              <div v-else>
                <img
                  :src="headerImg"
                  style="width: 100%; height: 120px; object-fit: cover"
                />
              </div>
              <div class="text-center" style="padding: 10px 0">
                <input
                  id="file-icon-upload"
                  type="file"
                  style="display: none"
                  @change="fileUpload"
                  ref="fileInput"
                />
                <a
                  type="button"
                  class="btn btn-secondary btn-sm"
                  for="file-icon-upload"
                  @click="this.$refs.fileInput.click()"
                >
                  画像を選択する
                </a>
              </div>
            </div>
            <div style="padding: 15px">
              <div class="item-box">
                <div class="item-title">
                  タイトル
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="タイトル"
                    :style="event.title.length > 100 ? 'border-color: red' : ''"
                    v-model="event.title"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ event.title.length }}/100
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">説明</div>
                <div style="padding-top: 10px">
                  <textarea
                    class="form-control"
                    rows="15"
                    v-model="event.detail"
                    :style="
                      event.detail.length > 2000 ? 'border-color: red' : ''
                    "
                  ></textarea>
                  <div class="text-end" style="font-size: 10px">
                    {{ event.detail.length }}/2000
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">問い合わせ先</div>
                <div class="item-description">
                  問い合わせ先用のメールやSNSなどのURLをご記入ください
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="問い合わせ先"
                    :style="
                      event.contact.length > 300 ? 'border-color: red' : ''
                    "
                    v-model="event.contact"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ event.contact.length }}/300
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">開催場所</div>
                <div class="item-description">
                  開催される場所の記載をお願いします。<br />
                  シークレットな開催の場合は空白や「問い合わせまで」などでも大丈夫です
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="開催場所"
                    :style="event.area.length > 300 ? 'border-color: red' : ''"
                    v-model="event.area"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ event.area.length }}/300
                  </div>
                </div>
              </div>
              <div v-if="isEntry">
                <div class="item-box">
                  <div class="item-title">イベント開始日</div>
                  <div style="padding-top: 10px">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="イベント日時"
                      v-model="eventStartAt"
                    />
                  </div>
                </div>
                <div class="item-box">
                  <div class="item-title">イベント終了日</div>
                  <div style="padding-top: 10px">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="イベント日時"
                      v-model="eventEndAt"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="item-box">
                  <div class="item-title">イベント日時</div>
                  <div style="padding-top: 10px">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="イベント日時"
                      v-model="eventAt"
                    />
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">画像一覧</div>
                <div class="item-description">
                  最大で4枚の画像が登録できます
                </div>
                <div style="padding-top: 10px">
                  <div
                    v-if="listImg.length > 0"
                    style="
                      height: 210px;
                      display: flex;
                      overflow-x: scroll;
                      margin-bottom: 10px;
                    "
                  >
                    <div
                      v-for="(img_url, i) in listImg"
                      :key="i"
                      style="
                        float: left;
                        flex-shrink: 0;
                        width: auto;
                        height: 210px;
                      "
                    >
                      <div
                        style="
                          position: relative;
                          float: right;
                          right: 30px;
                          top: 5px;
                          text-shadow:
                            1px 1px 2px #ffffff,
                            -1px 1px 2px #ffffff,
                            1px -1px 2px #ffffff,
                            -1px -1px 2px #ffffff,
                            1px 0px 2px #ffffff,
                            0px 1px 2px #ffffff,
                            -1px 0px 2px #ffffff,
                            0px -1px 2px #ffffff;
                        "
                      >
                        <span
                          class="material-symbols-rounded"
                          style="color: red"
                          @click="removeImgs(i)"
                        >
                          close
                        </span>
                      </div>
                      <img
                        style="
                          width: auto;
                          height: 200px;
                          margin: 5px 5px 5px 0;
                          border-radius: 8px;
                          border: solid 1px #eee;
                        "
                        :src="img_url.url"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center" style="padding: 10px 0">
                  <input
                    id="file-images-upload"
                    type="file"
                    style="display: none"
                    @change="filesUpload"
                    ref="filesInput"
                  />
                  <a
                    type="button"
                    class="btn btn-secondary btn-sm"
                    for="file-images-upload"
                    @click="this.$refs.filesInput.click()"
                  >
                    画像を追加する
                  </a>
                </div>
              </div>
              <div class="item-box">
                <div style="padding-top: 10px; padding-left: 2px">
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 7px"
                      v-bind:checked="event.isOpen"
                      v-on:change="event.isOpen = !event.isOpen"
                    />
                    <label class="form-check-label" style="color: #784ba0">
                      イベントを公開する
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            v-if="isEntry"
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="entry()"
          >
            登録
          </button>
          <button
            v-else
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="update()"
          >
            更新
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { db, storage } from "../../../plugins/firebase";
import { doc, addDoc, collection, setDoc } from "firebase/firestore";
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { store } from "../../../plugins/store";
import imageCompression from "browser-image-compression";

export default {
  data() {
    return {
      user: {},
      event: {},
      headerImg: null,
      headerFile: null,

      listImg: [],
      files: [],
      removeUrls: [],
      isEntry: true,
      isUploadError: false,

      entryEvent: {
        area: "",
        contact: "",
        detail: "",
        eventAtView: "",
        eventId: "",
        header: [],
        isOpen: true,
        isSensitiveImageByTweet: false,
        roomId: "0",
        title: "",
        uid: "",
        url: [],
        userName: "",
      },

      eventAt: "",
      eventStartAt: "",
      eventEndAt: "",

      isEdit: false,
    };
  },
  created() {
    this.user = store.user;
    this.event = store.eventInfo == null ? this.entryEvent : store.eventInfo;
    this.isEntry = store.eventInfo == null;

    const now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();

    this.eventStartAt = `${year}-${month}-${day}`;
    this.eventEndAt = `${year}-${month}-${day}`;

    if (!this.isEntry) {
      const e = new Date(
        this.event.eventAt.seconds * 1000 +
          this.event.eventAt.nanoseconds / 1_000_000,
      );
      var eyear = e.getFullYear();
      var emonth = e.getMonth() + 1;
      var eday = e.getDate();
      this.eventAt = `${eyear}-${emonth}-${eday}`;
    }
  },
  async mounted() {
    const imageRef = storageRef(storage, this.event.header[0]);

    try {
      const url = await getDownloadURL(imageRef);
      this.headerImg = url;
    } catch (error) {
      console.log("---------- event/headerView ---------");
      console.error("Error fetching image URL:", error);
    }
    //イメージ
    for (var i in this.event.url) {
      const photoRef = storageRef(storage, this.event.url[i]);
      try {
        const urlPhoto = await getDownloadURL(photoRef);
        this.listImg.push({
          url: urlPhoto,
          type: "https",
          path: this.event.url[i],
        });
      } catch (error) {
        console.log("---------- event/headerView1 ---------");
        console.error("Error fetching image URL:", error);
      }
    }
  },
  components: {
    AppHeader,
  },
  methods: {
    fileUpload(event) {
      const file = event.target.files[0];

      this.headerImg = URL.createObjectURL(file);
      this.headerFile = file;
    },
    filesUpload(event) {
      if (this.listImg.length >= 4) {
        this.$toast.show("画像は4枚までです", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
      } else {
        const file = event.target.files[0];

        this.listImg.push({ url: URL.createObjectURL(file), type: "blob" });
        this.files.push(file);
      }
    },
    removeImgs(i) {
      this.isUploadError = false;
      if (this.listImg[i].type == "https") {
        this.removeUrls.push(this.listImg[i].path);
      }
      this.listImg.splice(i, 1);
      this.files.splice(i, 1);
    },
    autoLink(text) {
      if (text == undefined) {
        return "";
      }

      const urlPattern =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

      return text.replace(urlPattern, function (url) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    handleModalView(i, images) {
      this.isModal = true;
      this.imageUrl = images;
      this.modalImageUrl = images[i];
      this.modalImageIndex = i;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async entry() {
      this.isEdit = true;
      // チェック
      if (this.event.title == "" || this.event.title.length > 100) {
        this.isEdit = false;
        this.$toast.show("タイトルを入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isEdit = false;
        return;
      }
      var calculatedDates = [];
      if (this.eventStartAt && this.eventEndAt) {
        const startDate = new Date(this.eventStartAt);
        const endDate = new Date(this.eventEndAt);

        if (startDate > endDate) {
          this.$toast.show("イベント日付の入力がエラーとなります", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          this.isEdit = false;
          return;
        } else {
          const daysBetween =
            Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

          for (let i = 0; i < daysBetween; i++) {
            const newDate = new Date(startDate);
            newDate.setDate(newDate.getDate() + i); // i日加算
            calculatedDates.push(newDate.toISOString().split("T")[0]);
          }
        }
      } else {
        this.$toast.show("日付を入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isEdit = false;
        return;
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        var eventId = "";

        const docRef = await addDoc(collection(db, "events"), {
          uid: this.user.uid,
        });
        eventId = docRef.id;

        // ヘッダー
        if (this.headerFile != null) {
          const compressedFile = await imageCompression(
            this.headerFile,
            options,
          );
          const fileRef = storageRef(
            storage,
            `event/${eventId}/event_header.png`,
          );
          await uploadBytes(fileRef, compressedFile);
          this.event.header.push(`event/${eventId}/event_header.png`);
        }

        // 画像追加
        if (this.files.length > 0) {
          for (var i in this.files) {
            var imageId = "";
            const docImageRef = await addDoc(
              collection(db, `events/${eventId}/images`),
              {
                uid: this.user.uid,
              },
            );
            imageId = docImageRef.id;

            const compressedSubFile = await imageCompression(
              this.files[i],
              options,
            );
            const fileSubRef = storageRef(
              storage,
              `events/images/${imageId}/${compressedSubFile.name}`,
            );
            await uploadBytes(fileSubRef, compressedSubFile);

            this.event.url.push(
              `events/images/${imageId}/${compressedSubFile.name}`,
            );
          }
        }

        calculatedDates.forEach(async (item) => {
          const date = new Date(item);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          await setDoc(doc(db, "events", eventId), {
            area: this.event.area,
            contact: this.event.contact,
            detail: this.event.detail,
            eventAt: date,
            eventAtView: `${year}年${month}月${day}日`,
            eventId: eventId,
            header: this.event.header,
            isOpen: this.event.isOpen,
            isSensitiveImageByTweet: this.event.isSensitiveImageByTweet,
            roomId: this.event.roomId,
            title: this.event.title,
            uid: this.user.uid,
            url: this.event.url,
            userName: this.user.name,
            createdAt: new Date(),
          });
        });
      } catch (error) {
        this.isEdit = false;
        console.error("Error uploading file:", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isEdit = false;
        return;
      } finally {
        this.$toast.show("登録が完了しました", {
          position: "bottom",
          duration: 2000,
        });
        this.delay(1000);
        this.$router.go(-1);
        this.isEdit = false;
      }
    },
    async update() {
      this.isEdit = true;
      // チェック
      if (this.event.title == "" || this.event.title.length > 100) {
        this.isEdit = false;
        this.$toast.show("タイトルを入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isEdit = false;
        return;
      }
      if (!this.eventAt) {
        this.$toast.show("日付を入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isEdit = false;
        return;
      }
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        // ヘッダー
        if (this.headerFile != null) {
          const compressedFile = await imageCompression(
            this.headerFile,
            options,
          );
          const fileRef = storageRef(
            storage,
            `event/${this.event.eventId}/event_header.png`,
          );
          await uploadBytes(fileRef, compressedFile);
          this.event.header.push(
            `event/${this.event.eventId}/event_header.png`,
          );
        }

        var imagesList = this.user.imageList ? this.user.imageList : [];

        if (this.files.length > 0) {
          // 画像追加
          for (var i in this.files) {
            var imageId = "";
            const docImageRef = await addDoc(
              collection(db, `users/${this.user.uid}/images`),
              {
                uid: this.user.uid,
              },
            );
            imageId = docImageRef.id;

            const compressedSubFile = await imageCompression(
              this.files[i],
              options,
            );
            const fileSubRef = storageRef(
              storage,
              `users/images/${imageId}/${compressedSubFile.name}`,
            );
            await uploadBytes(fileSubRef, compressedSubFile);

            imagesList.push(
              `events/images/${imageId}/${compressedSubFile.name}`,
            );
          }
        }
        // 画像削除
        if (this.removeUrls.length > 0) {
          for (var r in this.removeUrls) {
            console.log(this.removeUrls[r]);
            const index = imagesList.indexOf(this.removeUrls[r]);
            if (index !== -1) {
              imagesList.splice(index, 1); // 配列から削除
            }

            const fileRef = storageRef(storage, this.removeUrls[r]);
            await deleteObject(fileRef);
          }
        }
        const date = new Date(this.eventAt);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        await setDoc(doc(db, "events", this.event.eventId), {
          area: this.event.area,
          contact: this.event.contact,
          detail: this.event.detail,
          eventAt: date,
          eventAtView: `${year}年${month}月${day}日`,
          eventId: this.event.eventId,
          header: this.event.header,
          isOpen: this.event.isOpen,
          isSensitiveImageByTweet: this.event.isSensitiveImageByTweet,
          roomId: this.event.roomId,
          title: this.event.title,
          uid: this.user.uid,
          url: this.event.url,
          userName: this.user.name,
          createdAt: new Date(),
        });
      } catch (error) {
        this.isEdit = false;
        console.error("Error uploading file:", error);

        this.$toast.show("投稿時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      } finally {
        this.$toast.show("更新が完了しました", {
          position: "bottom",
          duration: 2000,
        });
        this.delay(1000);
        this.$router.go(-1);
        this.isEdit = false;
      }
    },
  },
};
</script>

<style scoped>
.like-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 55px;
  padding-bottom: 100px;
}
.tab-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  padding: 5px 0;
}
.tab-item {
  width: calc(100% / 2);
  height: 40px;
  font-size: 13px;
  border: solid 3px #fff;
  border-radius: 6px;
  padding-top: 6px;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
