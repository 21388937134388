<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="true" :is_back="false" :title="''" :type="week" />
    <div class="week">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div
                v-if="nextWeekTimelineList.length != 0"
                class="text-center"
                style="padding: 5px 0"
              >
                <span class="reload" @click="reload">一覧データを更新</span>
              </div>
              <div v-for="item in weekTimelineList" :key="item.id">
                <div @click="linkUser(item.data)">
                  <table class="table table-borderless" style="margin: 0">
                    <tbody>
                      <tr style="padding: 0">
                        <td
                          style="
                            vertical-align: top;
                            text-align: end;
                            padding: 0;
                          "
                          width="50"
                        >
                          <listIcon
                            :uid="item.data.uid"
                            :is_icon="item.data.isIconUrl"
                          />
                        </td>
                        <td style="word-break: break-word; padding: 0 15px">
                          <div>
                            <div>
                              {{ item.data.name }}
                            </div>
                            <div
                              style="font-size: 12px; color: #777"
                              v-if="item.data.isAgeView"
                            >
                              {{ item.data.age + "歳/" + item.data.sex }}
                            </div>
                            <div style="font-size: 12px; color: #777" v-else>
                              {{ "秘密/" + item.data.sex }}
                            </div>
                            <div style="padding-top: 4px">
                              <div
                                style="
                                  overflow: hidden;
                                  display: flex;
                                  flex-wrap: wrap;
                                "
                              >
                                <div
                                  style="
                                    padding: 0px 13px 5px 13px;
                                    background-color: #784ba0;
                                    color: #fff;
                                    margin-right: 5px;
                                    margin-bottom: 3px;
                                    white-space: nowrap;
                                    border-radius: 20px;
                                  "
                                  v-for="(tag, i) in item.data.selectTag"
                                  :key="i"
                                >
                                  <span style="font-size: 12px">{{ tag }}</span>
                                </div>
                              </div>
                            </div>
                            <div style="padding-top: 4px">
                              <div style="font-size: 14px">
                                {{ truncateText(item.data.profile) }}
                              </div>
                            </div>
                            <div style="padding-top: 10px">
                              <div style="width: 100%; height: 30px">
                                <div
                                  style="
                                    width: 30px;
                                    font-size: 14px;
                                    float: left;
                                    margin-top: -2px;
                                  "
                                >
                                  S度
                                </div>
                                <div
                                  style="width: calc(100% - 30px); float: left"
                                >
                                  <div class="progress">
                                    <div
                                      style="background-color: #543fdd"
                                      class="progress-bar"
                                      role="progressbar"
                                      :style="
                                        'width: ' + item.data.sadistic + '%'
                                      "
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {{ item.data.sadistic }}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%; height: 30px">
                                <div
                                  style="
                                    width: 30px;
                                    font-size: 14px;
                                    float: left;
                                    margin-top: -2px;
                                  "
                                >
                                  M度
                                </div>
                                <div
                                  style="width: calc(100% - 30px); float: left"
                                >
                                  <div class="progress">
                                    <div
                                      style="background-color: #d85992"
                                      class="progress-bar"
                                      role="progressbar"
                                      :style="
                                        'width: ' + item.data.masochist + '%'
                                      "
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {{ item.data.masochist }}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="padding-top: 10px">
                              <div class="speech-bubble">
                                {{ item.data.comment }}
                              </div>
                            </div>
                            <div
                              style="font-size: 10px; padding-top: 5px"
                              class="text-end"
                            >
                              最終ログイン：{{ setDateView(item.data.loginAt) }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style="
                    margin: 25px 0;
                    border: 1px;
                    border-top: solid 1px #f3f3f3;
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter :index="4" />
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import listIcon from "@/components/timeline/listIconView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import { db } from "../../plugins/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      user: [],
      weekTimelineList: [],
      nextWeekTimelineList: [],
      initNo: 0,
      search_tag: "",
      unsubscribe: null,
      usersCollection: null,
    };
  },
  async created() {
    this.user = store.user;

    this.usersCollection = collection(db, "users");
    const timelineQuery = query(
      this.usersCollection,
      where("isEntry", "==", true),
      orderBy("loginAt", "desc"),
    );
    this.unsubscribe = onSnapshot(timelineQuery, (snapshot) => {
      if (this.initNo == 0) {
        this.initNo = 1;
        this.weekTimelineList = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
      } else {
        this.nextWeekTimelineList = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
      }
    });
  },
  components: {
    AppHeader,
    listIcon,
    AppFooter,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.delay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    linkUser(user) {
      setUserDetail(user);
      this.$router.push({
        name: "user-detail",
      });
    },
    reload() {
      this.weekTimelineList = this.nextWeekTimelineList;
      this.nextWeekTimelineList = [];
    },
    truncateText(text) {
      if (text == undefined) {
        return "";
      }
      const maxLength = 60; // 最大文字数を指定
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return "--";
      }
    },
  },
};
</script>

<style scoped>
ul {
  display: inline;
}
li {
  list-style: none;
}
.week {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 100px;
}
.reload {
  color: #784ba0;
}
.speech-bubble {
  position: relative;
  background: #784ba0;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  width: 100%;
  font-size: 14px;
}
.speech-bubble::after {
  content: "";
  position: absolute;
  top: -13px;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #784ba0 transparent;
}
</style>
