import { db } from "./firebase";
import { addDoc, collection } from "firebase/firestore";

/**
 * サービス内通知
 * 通知のタイミング
 * ・いいねしたorされた
 * ・マッチングが成立した
 * ・メッセージが送信された
 * ・グループチャットにメッセージがきた
 * @param {ユーザID} uid
 * @param {メッセージ} message
 * @param {通知タイプ} type
 * none：ページ遷移なし、user,dm,group：ページ遷移あり、url：外部リンク
 * @param {遷移先} code
 * @param {パラメータ} value
 * （遷移先がユーザ詳細の場合はuid
 * @param {push通知用のトークン} token
 */
export async function setInformation(uid, message, type, code, value, token) {
  await addDoc(collection(db, `users/${uid}/information`), {
    message: message,
    type: type,
    code: code,
    value: value,
    isView: false,
    createdAt: new Date(),
  });

  if (token != "") {
    console.log(token);
  }
}
