import { reactive, watch } from "vue";

const savedData = JSON.parse(localStorage.getItem("storeData"));

export const store = reactive(
  savedData || {
    user: {},
    uid: "",
    userIconUrl: "",
    isAuth: false,
    // 投稿
    note: {},
    noteLineHistory: {},
    // ユーザ詳細
    userDetail: {},
    // トークン（push通知）
    token: "",
    // 共有用
    shareUserId: "",
    // イベント
    event: {},
    // 更新用
    eventInfo: null,
    // マッチング情報
    matching: {},
    // 最終ログイン時間（退避用
    lastLoginDate: null,
  },
);

export function setUserIconUrl(url) {
  store.userIconUrl = url;
}
export function setUser(u, id) {
  store.user = u;
  store.user.isIconUrl = u.isIconUrl ? u.isIconUrl : false;
  store.user.blockedUID = u.blockedUID ? u.blockedUID : [];
  store.user.noteGroupUID = u.noteGroupUID ? u.noteGroupUID : [];
  store.user.likedUID = u.likedUID ? u.likedUID : [];
  store.user.likeUID = u.likeUID ? u.likeUID : [];
  store.user.followerUID = u.followerUID ? u.followerUID : [];
  store.user.matchingUID = u.matchingUID ? u.matchingUID : [];
  /**
   * ストレージに持たせない情報
   * ・決済代行ID
   */
  store.user.sendid = null;

  store.uid = id;
  store.isAuth = true;
}
export function modUser(u) {
  store.user = u;
  store.user.isIconUrl = u.isIconUrl ? u.isIconUrl : false;
  store.user.blockedUID = u.blockedUID ? u.blockedUID : [];
  store.user.noteGroupUID = u.noteGroupUID ? u.noteGroupUID : [];
  store.user.likedUID = u.likedUID ? u.likedUID : [];
  store.user.likeUID = u.likeUID ? u.likeUID : [];
  store.user.followerUID = u.followerUID ? u.followerUID : [];
  store.user.matchingUID = u.matchingUID ? u.matchingUID : [];
  store.user.sendid = null;
}
export function removeUser() {
  store.user = {};
  store.uid = "";
  store.userIconUrl = "";
  store.isAuth = false;
}
export function removeUserIconUrl() {
  store.userIconUrl = "";
}
export function setNote(newNote) {
  store.note = newNote;
}
export function setNoteLineHistory(lineHistory) {
  store.noteLineHistory = lineHistory;
}
export function setToken(t) {
  store.token = t;
}
export function removeNote() {
  store.note = {};
}
export function removeNoteLineHistory() {
  store.noteLineHistory = {};
}
export function setUserDetail(u) {
  store.userDetail = u;
}
export function setShareUserId(id) {
  store.shareUserId = id;
}
export function removeUserDetail() {
  store.userDetail = {};
}
export function setEvent(e) {
  store.event = e;
}
export function setEventInfo(e) {
  store.eventInfo = e;
}
export function setLastLognAt(d) {
  store.lastLoginDate = d;
}
/**
 *
 * @param {いいねされた側} like_uid
 * @param {いいねした側} liked_uid
 */
export function setMatching(like_uid, liked_uid) {
  store.matching = {
    likeUID: like_uid,
    likedUID: liked_uid,
  };
}
export function removeAll() {
  store.lastLoginDate = undefined;
  store.user = {};
  store.uid = "";
  store.userIconUrl = "";
  store.isAuth = false;
  store.note = {};
  store.noteLineHistory = {};
  store.userDetail = {};
  store.token = "";
  store.shareUserId = "";
  store.event = {};
  store.matching = {};
}

watch(
  store,
  (newValue) => {
    localStorage.setItem("storeData", JSON.stringify(newValue));
  },
  { deep: true },
);
