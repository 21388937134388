<template>
  <div
    style="padding: 10px 15px; border-bottom: solid 1px #ddd; display: flex"
    @click="linkUser"
  >
    <div v-if="imageUrl">
      <img :src="imageUrl" />
    </div>
    <div v-else>
      <img :src="require('@/assets/icon_user.webp')" />
    </div>
    <div style="padding-left: 10px">
      {{ user.name }}
      <div>
        <span
          v-if="isFollow"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >フォロー中</span
        >
        <span
          v-if="isFollower"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >フォロワー</span
        >
        <span
          v-if="isLiked"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >いいねしてます</span
        >
        <span
          v-if="isLike"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >いいねされています</span
        >
        <span
          v-if="isMatching"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >マッチング中</span
        >
        <span
          v-if="isGroup"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >投稿グループ</span
        >
        <span
          v-if="isBookmark"
          class="badge"
          style="
            background-color: #ddd;
            font-size: 10px;
            color: #888;
            margin-right: 4px;
          "
          >お気に入り</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      imageUrl: null,
      userId: "",
      name: "",
      isIconUrl: false,
      isFollow: false,
      isFollower: false,
      isLike: false,
      isLiked: false,
      isMatching: false,
      isGroup: false,
      isBookmark: false,
    };
  },
  props: ["item"],
  async mounted() {
    this.user = this.item;
    this.loginUser = store.user;

    this.user.isIconUrl =
      this.user.isIconUrl == undefined ? true : this.user.isIconUrl;

    if (this.user.isIconUrl) {
      this.getUserIcon();
    }

    // フォローしてるかチェック
    if (this.loginUser.followerUID) {
      if (this.loginUser.followerUID.includes(this.user.uid)) {
        this.isFollow = true;
      }
    }
    // フォロワーかチェック
    if (this.loginUser.followeredUID) {
      if (this.loginUser.followeredUID.includes(this.user.uid)) {
        this.isFollower = true;
      }
    }
    // いいねしたかチェック
    if (this.loginUser.likedUID) {
      if (this.loginUser.likedUID.includes(this.user.uid)) {
        this.isLiked = true;
      }
    }
    // いいねされてるかチェック
    if (this.loginUser.likeUID) {
      if (this.loginUser.likeUID.includes(this.user.uid)) {
        this.isLike = true;
      }
    }
    // マッチングしてるかチェック
    if (this.loginUser.matchingUID) {
      if (this.loginUser.matchingUID.includes(this.user.uid)) {
        this.isMatching = true;
      }
    }
    // グループに入れてるかチェック
    if (this.loginUser.noteGroupUID) {
      if (this.loginUser.noteGroupUID.includes(this.user.uid)) {
        this.isGroup = true;
      }
    }
    // お気に入りに追加しているかチェック
    if (this.loginUser.bookmarks) {
      if (this.loginUser.bookmarks.includes(this.user.uid)) {
        this.isBookmark = true;
      }
    }
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      const docUserRef = doc(db, "users", this.user.uid);
      const docUserSnap = await getDoc(docUserRef);
      setUserDetail(docUserSnap.data());
      this.$router.push({
        name: "user-detail",
      });
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.list-sub-tag {
  font-size: 10px;
  white-space: nowrap;
}
</style>
