import { createRouter, createWebHistory } from "vue-router";

import TopView from "../views/TopView.vue";

// デバッグ
import Debug from "../views/DebugView.vue";
import DebugImage from "../views/DebugImageView.vue";

// Entry
import EntryTop from "../views/entry/TopView.vue";
import PasswordView from "../views/entry/PasswordView.vue";

// Auth
import AuthTop from "../views/auth/TopView.vue";
import AuthConf from "../views/auth/ConfView.vue";

// Home
import HomeTimeline from "../views/home/TimelineView.vue";
import HomeTimelineDetail from "../views/home/TimelineDetail.vue";
import HomeTimelineEdit from "../views/home/TimelineEdit.vue";
import HomeTimelineReply from "../views/home/TimelineReply.vue";
import HomeTimelineHistory from "../views/home/TimelineHistory.vue";

// mypage
import MypageIndex from "../views/mypage/IndexView.vue";
import MypageFollow from "../views/mypage/followList.vue";
import MypageBookmark from "../views/mypage/bookmarkList.vue";
import MypageGroup from "../views/mypage/groupList.vue";
import MypageEventList from "../views/mypage/event/ListView.vue";
import MypageEventEdit from "../views/mypage/event/EditView.vue";
import MypageWeekSetting from "../views/mypage/week/SettingView.vue";

import Withdrawal from "../views/mypage/withdrawalView.vue";
import Subscription from "../views/mypage/SubscriptionView.vue";
import PlanCancel from "../views/mypage/PlanCancelView.vue";

// mypage/setting
import MypageSettingIdentification from "../views/mypage/setting/userIdentification.vue";
import MypageSettingCompletion from "../views/mypage/setting/entryCompletion.vue";
import MypageSettingPWA from "../views/mypage/setting/slidePWA.vue";

// matching
import MatchingIndex from "../views/matching/indexView.vue";

// event
import EventIndex from "../views/event/IndexView.vue";
import EventBookmark from "../views/event/BookmarkView.vue";
import EventDetail from "../views/event/DetailView.vue";

// week
import WeekIndex from "../views/week/indexView.vue";

// like
import LikeList from "../views/like/ListView.vue";

// message
import MessageList from "../views/message/ListView.vue";
import MessagePerson from "../views/message/PersonView.vue";
import MessageAppea from "../views/message/AppealView.vue";
import MessageGroup from "../views/message/GroupView.vue";

// user
import UserDetail from "../views/user/DetailView.vue";
import UserPreview from "../views/user/PreView.vue";
// user/edit
import UserEditInfo from "../views/user/edit/UserInfo.vue";
import UserEditIcon from "../views/user/edit/UserIcon.vue";
import UserEditProfile from "../views/user/edit/UserProfile.vue";
import UserEditDetail from "../views/user/edit/UserDetail.vue";
import UserEditFetishism from "../views/user/edit/UserFetishism.vue";
import UserEditBDSM from "../views/user/edit/UserBDSM.vue";
import UserEditSeiheki from "../views/user/edit/UserSeiheki.vue";
import UserEditKaiwai from "../views/user/edit/UserKaiwai.vue";

// search
import SearchMatchingView from "../views/search/MatchingView.vue";

// share
import ShareUserProfile from "../views/share/UserProfileView.vue";

const routes = [
  {
    path: "/debug",
    name: "debug",
    component: Debug,
  },
  {
    path: "/debug/image",
    name: "debug-image",
    component: DebugImage,
  },
  {
    path: "/",
    name: "top",
    component: TopView,
  },
  {
    path: "/entry/top",
    name: "entry",
    component: EntryTop,
  },
  {
    path: "/entry/password",
    name: "entry-password",
    component: PasswordView,
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthTop,
  },
  {
    path: "/auth/conf",
    name: "auth-conf",
    component: AuthConf,
  },
  {
    path: "/home",
    name: "home-timeline",
    component: HomeTimeline,
  },
  {
    path: "/timeline/detail",
    name: "home-timeline-detail",
    component: HomeTimelineDetail,
  },
  {
    path: "/timeline/edit",
    name: "home-timeline-edit",
    component: HomeTimelineEdit,
  },
  {
    path: "/timeline/reply",
    name: "home-timeline-reply",
    component: HomeTimelineReply,
  },
  {
    path: "/timeline/history/:noteid",
    name: "home-timeline-history",
    component: HomeTimelineHistory,
  },
  {
    path: "/mypage",
    name: "mypage",
    component: MypageIndex,
  },
  {
    path: "/mypage/withdrawal",
    name: "mypage-withdrawal",
    component: Withdrawal,
  },
  {
    path: "/mypage/subscription",
    name: "mypage-subscription",
    component: Subscription,
  },
  {
    path: "/mypage/plan/cancel",
    name: "mypage-plan-cancel",
    component: PlanCancel,
  },
  {
    path: "/:page/list",
    name: "follow-list",
    component: MypageFollow,
  },
  {
    path: "/bookmark",
    name: "bookmarek-list",
    component: MypageBookmark,
  },
  {
    path: "/group/note/list",
    name: "group-list",
    component: MypageGroup,
  },
  {
    path: "/mypage/setting/identification/:type",
    name: "mypage-setting-identification",
    component: MypageSettingIdentification,
  },
  {
    path: "/mypage/setting/completion",
    name: "mypage-setting-completion",
    component: MypageSettingCompletion,
  },
  {
    path: "/mypage/setting/pwa",
    name: "mypage-setting-pwa",
    component: MypageSettingPWA,
  },
  {
    path: "/mypage/event/list",
    name: "mypage-event-list",
    component: MypageEventList,
  },
  {
    path: "/mypage/event/edit",
    name: "mypage-event-edit",
    component: MypageEventEdit,
  },
  {
    path: "/mypage/week/setting",
    name: "mypage-week-setting",
    component: MypageWeekSetting,
  },
  {
    path: "/matching",
    name: "matching",
    component: MatchingIndex,
  },
  {
    path: "/event",
    name: "event",
    component: EventIndex,
  },
  {
    path: "/event/bookmark",
    name: "event-bookmark",
    component: EventBookmark,
  },
  {
    path: "/event/detail",
    name: "event-detail",
    component: EventDetail,
  },
  {
    path: "/week",
    name: "week",
    component: WeekIndex,
  },
  {
    path: "/like/list",
    name: "like-list",
    component: LikeList,
  },
  {
    path: "/message/list",
    name: "message-list",
    component: MessageList,
  },
  {
    path: "/message/person/:matching_id",
    name: "message-person",
    component: MessagePerson,
  },
  {
    path: "/message/appea/:matching_id",
    name: "message-appea",
    component: MessageAppea,
  },
  {
    path: "/message/group/:matching_id",
    name: "message-group",
    component: MessageGroup,
  },
  {
    path: "/user/detail",
    name: "user-detail",
    component: UserDetail,
  },
  {
    path: "/user/preview",
    name: "user-preview",
    component: UserPreview,
  },
  {
    path: "/user/edit/icon/:type",
    name: "user-edit-icon",
    component: UserEditIcon,
  },
  {
    path: "/user/edit/info/:type",
    name: "user-edit-info",
    component: UserEditInfo,
  },
  {
    path: "/user/edit/profile/:type",
    name: "user-edit-profile",
    component: UserEditProfile,
  },
  {
    path: "/user/edit/detail/:type",
    name: "user-edit-detail",
    component: UserEditDetail,
  },
  {
    path: "/user/edit/fetishism/:type",
    name: "user-edit-fetishism",
    component: UserEditFetishism,
  },
  {
    path: "/user/edit/bdsm/:type",
    name: "user-edit-bdsm",
    component: UserEditBDSM,
  },
  {
    path: "/user/edit/seiheki/:type",
    name: "user-edit-seiheki",
    component: UserEditSeiheki,
  },
  {
    path: "/user/edit/kaiwai/:type",
    name: "user-edit-kaiwai",
    component: UserEditKaiwai,
  },
  {
    path: "/search/matching",
    name: "search-matching",
    component: SearchMatchingView,
  },
  {
    path: "/share/profile/:uid",
    name: "share-user-profile",
    component: ShareUserProfile,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
