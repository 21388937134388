<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="true" :is_back="false" :title="''" type="none" />
    <div class="message-list">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <!-- <div class="tab-box">
                <div
                  @click="tabIndex = 1"
                  class="tab-item text-center"
                  :style="
                    tabIndex == 1
                      ? 'background-color: #784ba0; color:#fff; font-weight:600'
                      : 'background-color: #efefef; color:#888; font-weight:400'
                  "
                >
                  メッセージ
                </div>
                <div
                  @click="tabIndex = 2"
                  class="tab-item text-center"
                  :style="
                    tabIndex == 2
                      ? 'background-color: #784ba0; color:#fff; font-weight:600'
                      : 'background-color: #efefef; color:#888; font-weight:400'
                  "
                >
                  グループチャット
                </div>
              </div> -->
              <div v-if="tabIndex == 1">
                <div v-if="machingUsers.length">
                  <div v-for="(item, i) in machingUsers" :key="i" class="item">
                    <div>
                      <ListForMatchingView
                        :item="item.data"
                        type="matching"
                        :like_uid="user.uid"
                        :liked_uid="item.data.uid"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center" style="font-size: 12px">
                    <br /><br /><br />
                    まだマッチングしていません
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-left" v-if="tabIndex == 2">
      <router-link to="/timeline/edit">
        <div
          style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #784ba0;
            color: #fff;
            padding-top: 15px;
            padding-left: 15px;
          "
        >
          <span class="material-symbols-rounded" style="font-size: 31px">
            add_comment
          </span>
        </div>
      </router-link>
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
    <AppFooter :index="6" />
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import ListForMatchingView from "@/components/user/ListForMatchingView.vue";
import { store, setUser } from "../../plugins/store";
import { db } from "../../plugins/firebase";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";

export default {
  data() {
    return {
      user: {},
      tabIndex: 1,
      machingUsers: [],
      groupChats: [],
    };
  },
  async created() {
    const docRef = doc(db, "authLink", store.user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docUserRef = doc(db, "users", docSnap.data().uid);
      const docUserSnap = await getDoc(docUserRef);

      setUser(docUserSnap.data(), docSnap.data().uid);
    }
    this.user = store.user;

    const queryLike = query(
      collection(db, `users/${this.user.uid}/matchingUsers`),
      orderBy("updatedAt", "desc"),
    );

    const queryLikeSnapshot = await getDocs(queryLike);

    this.machingUsers = queryLikeSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const uid = doc.id;
        if (!this.user.blockedUID.includes(uid)) {
          return {
            id: uid,
            data: data,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    const queryGroupChat = query(
      collection(db, `users/${this.user.uid}/groupChats`),
      orderBy("updatedAt", "desc"),
    );

    const queryGroupChatSnapshot = await getDocs(queryGroupChat);

    this.groupChats = queryGroupChatSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const uid = doc.id;
        if (!this.user.blockedUID.includes(uid)) {
          return {
            id: uid,
            data: data,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);
  },
  components: {
    AppHeader,
    AppFooter,
    VPullToRefresh,
    ListForMatchingView,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.message-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 60px;
}
.tab-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 35px;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.tab-item {
  width: calc(100% / 2);
  height: 40px;
  font-size: 13px;
  border: solid 3px #fff;
  border-radius: 6px;
  padding-top: 6px;
}
.footer-left {
  position: fixed;
  right: 15px;
  bottom: 90px;
  z-index: 99;
}
</style>
