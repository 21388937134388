<template>
  <div class="subtitle-box">
    <div class="subtitle">基本情報</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/profile/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div style="font-size: 24px; font-weight: 600">{{ user.name }}</div>
    <span
      v-if="isFollowered"
      style="
        font-size: 10px;
        font-weight: 400;
        color: #888;
        padding: 1px 8px;
        background-color: #efefef;
        border-radius: 6px;
      "
    >
      フォローされています
    </span>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div class="speech-bubble">
      {{ user.comment }}
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div class="label-box">
      <div
        class="label-item"
        :style="
          user.isTagGreen
            ? 'background-color: rgb(76, 175, 80)'
            : 'background-color: #efefef'
        "
      ></div>
      <div
        class="label-item"
        :style="
          user.isTagYellow
            ? 'background-color: rgb(255, 235, 59)'
            : 'background-color: #efefef'
        "
      ></div>
      <div
        class="label-item"
        :style="
          user.isTagCyan
            ? 'background-color: rgb(0, 188, 212)'
            : 'background-color: #efefef'
        "
      ></div>
      <div
        class="label-item"
        :style="
          user.isTagBlue
            ? 'background-color: rgb(33, 150, 243)'
            : 'background-color: #efefef'
        "
      ></div>
    </div>
    <div class="label-box">
      <div
        class="label-item"
        :style="
          user.isTagPurple
            ? 'background-color: rgb(156, 39, 176)'
            : 'background-color: #efefef'
        "
      ></div>
      <div
        class="label-item"
        :style="
          user.isTagOrange
            ? 'background-color: rgb(255, 152, 0)'
            : 'background-color: #efefef'
        "
      ></div>
      <div
        class="label-item"
        :style="
          user.isTagRed
            ? 'background-color: rgb(244, 67, 54)'
            : 'background-color: #efefef'
        "
      ></div>
      <div class="label-item text-center">
        <span class="material-symbols-rounded"> help </span>
      </div>
    </div>
  </div>
  <div style="padding: 20px 30px 0 30px">
    <div style="overflow: hidden; display: flex; flex-wrap: wrap">
      <div
        style="
          padding: 0px 13px 5px 13px;
          background-color: #784ba0;
          color: #fff;
          margin-right: 5px;
          margin-bottom: 3px;
          white-space: nowrap;
          border-radius: 20px;
        "
        v-for="(tag, i) in user.selectTag"
        :key="i"
      >
        <span style="font-size: 14px; font-weight: 500">{{ tag }}</span>
      </div>
    </div>
  </div>
  <div style="padding: 20px 30px 0 30px">
    <div style="width: 100%; height: 30px">
      <div style="width: 30px; font-size: 14px; float: left; margin-top: -2px">
        S度
      </div>
      <div style="width: calc(100% - 30px); float: left">
        <div class="progress">
          <div
            style="background-color: #543fdd"
            class="progress-bar"
            role="progressbar"
            :style="'width: ' + user.sadistic + '%'"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ user.sadistic }}%
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 30px">
      <div style="width: 30px; font-size: 14px; float: left; margin-top: -2px">
        M度
      </div>
      <div style="width: calc(100% - 30px); float: left">
        <div class="progress">
          <div
            style="background-color: #d85992"
            class="progress-bar"
            role="progressbar"
            :style="'width: ' + user.masochist + '%'"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ user.masochist }}%
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex">
      url:
      <div
        style="width: 100%; padding-right: 15px"
        v-html="autoLink(user.weburl, true)"
      ></div>
    </div>
    <br />
    <div v-if="isShare == false">
      <div
        v-if="isFollower"
        class="text-center"
        style="
          font-size: 12px;
          background-color: #efefef;
          color: #888;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
        "
        @click="setFollor(false)"
      >
        フォロー中
      </div>
      <div
        v-else-if="isLoginUser == false"
        class="text-center"
        style="
          font-size: 12px;
          background-color: #fff;
          border: solid 1px #888;
          color: #888;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
        "
        @click="setFollor(true)"
      >
        フォローする
      </div>
    </div>
  </div>
  <div class="subtitle-box">
    <div class="subtitle">自己紹介</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/detail/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div v-html="autoLink(user.profile, false)"></div>
  </div>
  <div class="subtitle-box">
    <div class="subtitle">性的指向</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/profile/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div style="overflow: hidden; display: flex; flex-wrap: wrap">
      <div
        style="
          padding: 0px 13px 5px 13px;
          background-color: rgb(215, 201, 227);
          color: rgb(120, 75, 160);
          margin-right: 5px;
          margin-bottom: 3px;
          white-space: nowrap;
          border-radius: 20px;
        "
        v-for="(tag, i) in user.selectedDirectional"
        :key="i"
      >
        <span style="font-size: 14px">{{ tag }}</span>
      </div>
    </div>
  </div>
  <div class="subtitle-box">
    <div class="subtitle">プロフィール</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/profile/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div style="overflow: hidden; display: flex; flex-wrap: wrap">
      <div
        style="
          padding: 0px 13px 5px 13px;
          background-color: rgb(215, 201, 227);
          color: rgb(120, 75, 160);
          margin-right: 5px;
          margin-bottom: 3px;
          white-space: nowrap;
          border-radius: 20px;
        "
        v-for="(tag, i) in profileList"
        :key="i"
      >
        <span style="font-size: 14px">{{ tag }}</span>
      </div>
    </div>
  </div>
  <div class="subtitle-box">
    <div class="subtitle">嗜好</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/detail/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div v-html="autoLink(user.like, false)"></div>
  </div>
  <div class="subtitle-box">
    <div class="subtitle">NG</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/detail/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 10px 30px 0 30px">
    <div v-html="autoLink(user.ng, false)"></div>
  </div>
</template>

<script>
import { db } from "../../plugins/firebase";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { store, setUser } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      isMod: false,
      profileList: [],
      isFollower: false, //自分がフォローしてるか
      isFollowered: false, //相手にフォローされてるか
      isLoginUser: false,
      isShare: false,
    };
  },
  props: ["data", "is_mod", "is_share"],
  created() {
    this.user = this.data;
    this.isMod = this.is_mod;
    this.isShare = this.is_share;
    this.loginUser = store.user;

    this.isLoginUser = this.user.uid == this.loginUser.uid;

    // 自分がフォローされてるかチェック
    if (this.loginUser.followeredUID) {
      if (this.loginUser.followeredUID.includes(this.user.uid)) {
        this.isFollowered = true;
      }
    }

    // 相手をフォローしているかチェック
    if (this.loginUser.followerUID) {
      if (this.loginUser.followerUID.includes(this.user.uid)) {
        this.isFollower = true;
      }
    }

    var list = [];
    // 年齢
    if (this.user.isAgeView == undefined) {
      list.push(this.user.age + "歳");
    } else if (this.user.isAgeView) {
      list.push(this.user.age + "歳");
    }
    list.push(this.user.sex);

    const list0 = list.concat(this.user.area);
    const list1 = list0.concat(this.user.selectedBodyType);
    const list2 = list1.concat(this.user.selectedBodyHight);
    const list3 = list2.concat(this.user.selectedAlcohol);
    const list4 = list3.concat(this.user.selectedSmoking);
    const list5 = list4.concat(this.user.selectedGender);
    const list6 = list5.concat(this.user.selectedPersonality);
    const list7 = list6.concat(this.user.selectedBloods);
    const list8 = list7.concat(this.user.selectedDirectional);

    this.profileList = list8;
  },
  methods: {
    autoLink(text, t) {
      if (text == undefined) {
        return "";
      } else if (text == "") {
        return "";
      }
      t = true;
      var cl = "";
      if (t) {
        cl =
          "style='display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'";
      }

      const urlPattern =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

      return text.replace(urlPattern, function (url) {
        return `<a ${cl} href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    async setFollor(t) {
      const loginUserRef = doc(db, "users", this.loginUser.uid);
      const userRef = doc(db, "users", this.user.uid);

      if (t) {
        // フォローする
        var followerUID = this.loginUser.followerUID
          ? this.loginUser.followerUID
          : [];
        followerUID.push(this.user.uid);

        await updateDoc(loginUserRef, {
          followerUID: arrayUnion(this.user.uid),
        });
        await updateDoc(userRef, {
          followeredUID: arrayUnion(this.loginUser.uid),
        });
        this.isFollower = true;

        this.loginUser.followerUID = followerUID;
        setUser(this.loginUser, this.loginUser.uid);
      } else {
        // フォロー外す
        var rmfollowerUID = this.loginUser.followerUID
          ? this.loginUser.followerUID
          : [];
        const index = rmfollowerUID.indexOf(this.user.uid);
        if (index !== -1) {
          rmfollowerUID.splice(index, 1); // 配列から削除
        }
        await updateDoc(loginUserRef, {
          followerUID: arrayRemove(this.user.uid),
        });
        await updateDoc(userRef, {
          followeredUID: arrayRemove(this.loginUser.uid),
        });
        this.isFollower = false;

        this.loginUser.followerUID = rmfollowerUID;
        setUser(this.loginUser, this.loginUser.uid);
      }
    },
  },
};
</script>

<style scoped>
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  margin-top: 30px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
a {
  color: #555 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #555 !important;
  text-decoration: underline !important;
}
.label-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 29px;
  padding: 5px 0;
}
.label-item {
  width: calc(100% / 4);
  height: 29px;
  font-size: 12px;
  border: solid 3px #fff;
  border-radius: 6px;
}
.speech-bubble {
  position: relative;
  background: #784ba0;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
.speech-bubble::after {
  content: "";
  position: absolute;
  top: -13px;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #784ba0 transparent;
}
</style>
