<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="true"
      :is_back="false"
      :title="''"
      type="matching"
      @header-action-click="headerActionClick"
    />
    <div class="matching">
      <div class="page">
        <div
          v-if="isInit"
          style="padding-top: 200px; width: 100%; text-align: center"
        >
          <VueSpinnerDots size="50" color="#784ba0" />
        </div>
        <div v-else class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div v-if="matchingTimelineList.length">
                <div v-for="item in matchingTimelineList" :key="item.id">
                  <div @click="linkUser(item.data)">
                    <table class="table table-borderless" style="margin: 0">
                      <tbody>
                        <tr style="padding: 0">
                          <td
                            style="
                              vertical-align: top;
                              text-align: end;
                              padding: 0;
                            "
                            width="50"
                          >
                            <listIcon
                              :uid="item.data.uid"
                              :is_icon="item.data.isIconUrl"
                            />
                          </td>
                          <td style="word-break: break-word; padding: 0 15px">
                            <div>
                              <div>
                                {{ item.data.name }}
                              </div>
                              <div
                                style="font-size: 12px; color: #777"
                                v-if="item.data.isAgeView"
                              >
                                {{ item.data.age + "歳/" + item.data.sex }}
                              </div>
                              <div style="font-size: 12px; color: #777" v-else>
                                {{ "秘密/" + item.data.sex }}
                              </div>
                              <div style="padding-top: 4px">
                                <div
                                  style="
                                    overflow: hidden;
                                    display: flex;
                                    flex-wrap: wrap;
                                  "
                                >
                                  <div
                                    style="
                                      padding: 0px 13px 5px 13px;
                                      background-color: #784ba0;
                                      color: #fff;
                                      margin-right: 5px;
                                      margin-bottom: 3px;
                                      white-space: nowrap;
                                      border-radius: 20px;
                                    "
                                    v-for="(tag, i) in item.data.selectTag"
                                    :key="i"
                                  >
                                    <span style="font-size: 12px">{{
                                      tag
                                    }}</span>
                                  </div>
                                  <div
                                    style="
                                      padding: 0px 13px 5px 13px;
                                      background-color: #784ba0;
                                      color: #fff;
                                      margin-right: 5px;
                                      margin-bottom: 3px;
                                      white-space: nowrap;
                                      border-radius: 20px;
                                    "
                                    v-for="(tag, i) in item.data.selectedKaiwai"
                                    :key="i"
                                  >
                                    <span style="font-size: 12px">{{
                                      tag
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                              <div style="padding-top: 4px">
                                <div style="font-size: 14px">
                                  {{ truncateText(item.data.profile) }}
                                </div>
                              </div>
                              <div style="padding-top: 10px">
                                <div style="width: 100%; height: 30px">
                                  <div
                                    style="
                                      width: 30px;
                                      font-size: 14px;
                                      float: left;
                                      margin-top: -2px;
                                    "
                                  >
                                    S度
                                  </div>
                                  <div
                                    style="
                                      width: calc(100% - 30px);
                                      float: left;
                                    "
                                  >
                                    <div class="progress">
                                      <div
                                        style="background-color: #543fdd"
                                        class="progress-bar"
                                        role="progressbar"
                                        :style="
                                          'width: ' + item.data.sadistic + '%'
                                        "
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {{ item.data.sadistic }}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style="width: 100%; height: 30px">
                                  <div
                                    style="
                                      width: 30px;
                                      font-size: 14px;
                                      float: left;
                                      margin-top: -2px;
                                    "
                                  >
                                    M度
                                  </div>
                                  <div
                                    style="
                                      width: calc(100% - 30px);
                                      float: left;
                                    "
                                  >
                                    <div class="progress">
                                      <div
                                        style="background-color: #d85992"
                                        class="progress-bar"
                                        role="progressbar"
                                        :style="
                                          'width: ' + item.data.masochist + '%'
                                        "
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {{ item.data.masochist }}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="padding-top: 10px">
                                <div class="speech-bubble">
                                  {{ item.data.comment }}
                                </div>
                              </div>
                              <div
                                style="font-size: 10px; padding-top: 5px"
                                class="text-end"
                              >
                                最終ログイン：{{
                                  setDateView(item.data.loginAt)
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style="
                      margin: 25px 0;
                      border: 1px;
                      border-top: solid 1px #f3f3f3;
                    "
                  ></div>
                </div>
                <div class="text-center" style="margin-top: 20px">
                  <button
                    v-if="matchingTimelineList.length"
                    style="
                      font-size: 12px;
                      background-color: #bbb;
                      border: solid 1px #bbb;
                      color: #fff;
                      padding: 3px 20px;
                      border-radius: 20px;
                      cursor: pointer;
                    "
                    @click="loadNextPage"
                  >
                    次のユーザーへ
                  </button>
                  <div v-if="isNextAction">
                    <VueSpinnerDots size="20" color="#784ba0" />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-center" style="font-size: 12px">
                  <br /><br /><br />
                  対象のユーザーがいません<br />検索条件を変更してみてください
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter :index="2" />
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import listIcon from "@/components/timeline/listIconView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import { db } from "../../plugins/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      user: [],
      matchingTimelineList: [],
      nextMatchingTimelineList: [],
      initNo: 0,
      search_tag: "",
      unsubscribe: null,
      usersCollection: null,
      isInit: true,
      matchingSearch: {},
      // 条件値
      sex: null,
      fromAge: 18,
      toAge: 100,
      area: [],
      question1: 0,
      question2: 0,
      question3: 0,
      question4: 0,
      question5: 0,
      question6: 0,
      question7: 0,
      question8: 0,
      question9: 0,

      question10: 0,
      question11: 0,
      question12: 0,
      question13: 0,
      question14: 0,
      question15: 0,
      question16: 0,
      question17: 0,
      question18: 0,
      question19: 0,
      question20: 0,

      question21: 0,
      question22: 0,
      question23: 0,
      question24: 0,
      question25: 0,
      question26: 0,
      question27: 0,
      question28: 0,
      question29: 0,
      question30: 0,

      question31: 0,
      question32: 0,
      question33: 0,
      question34: 0,
      question35: 0,
      question36: 0,
      question37: 0,
      question38: 0,
      question39: 0,
      question40: 0,

      question41: 0,
      question42: 0,
      question43: 0,
      question44: 0,
      question45: 0,
      question46: 0,
      question47: 0,
      question48: 0,
      question49: 0,
      question50: 0,

      question51: 0,
      question52: 0,
      question53: 0,
      question54: 0,
      question55: 0,
      question56: 0,
      question57: 0,
      question58: 0,
      question59: 0,
      question60: 0,

      question61: 0,
      question62: 0,
      question63: 0,
      question64: 0,
      question65: 0,
      question66: 0,
      question67: 0,
      question68: 0,
      question69: 0,
      question70: 0,
      timelineQuery: null,
      isNextPage: false,
      lastVisible: null,
      isNextAction: false,
    };
  },
  async created() {
    this.isInit = true;

    this.user = store.user;

    if (this.user?.search) {
      this.fromAge = this.user.search.age_from;
      this.toAge = this.user.search.age_to;
      this.sex = this.user.search.sex;
      this.area = this.user.search.area;
    }

    this.usersCollection = collection(db, "users");

    const baseQuery =
      this.area.length && this.sex != null
        ? query(
            this.usersCollection,
            where("isEntry", "==", true),
            where("uid", "!=", this.user.uid),
            where("age", ">=", this.fromAge),
            where("age", "<=", this.toAge),
            where("sex", "==", this.sex),
            where("area", "array-contains-any", this.area),
            orderBy("loginAt", "desc"),
          )
        : this.area.length && this.sex == null
          ? query(
              this.usersCollection,
              where("isEntry", "==", true),
              where("uid", "!=", this.user.uid),
              where("age", ">=", this.fromAge),
              where("age", "<=", this.toAge),
              where("area", "array-contains-any", this.area),
              orderBy("loginAt", "desc"),
            )
          : this.area.length == 0 && this.sex != null
            ? query(
                this.usersCollection,
                where("isEntry", "==", true),
                where("uid", "!=", this.user.uid),
                where("age", ">=", this.fromAge),
                where("age", "<=", this.toAge),
                where("sex", "==", this.sex),
                orderBy("loginAt", "desc"),
              )
            : query(
                this.usersCollection,
                where("isEntry", "==", true),
                where("uid", "!=", this.user.uid),
                where("age", ">=", this.fromAge),
                where("age", "<=", this.toAge),
                orderBy("loginAt", "desc"),
              );
    this.timelineQuery = query(baseQuery, limit(10));

    this.loadTimeline();
  },
  components: {
    AppHeader,
    listIcon,
    AppFooter,
    VPullToRefresh,
  },
  methods: {
    async loadTimeline() {
      if (this.unsubscribe) this.unsubscribe();
      this.unsubscribe = onSnapshot(this.timelineQuery, (snapshot) => {
        const docs = snapshot.docs
          .map((doc) => {
            const data = doc.data();
            const uid = doc.id;
            if (
              data.question1 >= this.question1 &&
              data.question2 >= this.question2 &&
              data.question3 >= this.question3 &&
              data.question4 >= this.question4 &&
              data.question5 >= this.question5 &&
              data.question6 >= this.question6 &&
              data.question7 >= this.question7 &&
              data.question8 >= this.question8 &&
              data.question9 >= this.question9 &&
              data.question10 >= this.question10 &&
              data.question11 >= this.question11 &&
              data.question12 >= this.question12 &&
              data.question13 >= this.question13 &&
              data.question14 >= this.question14 &&
              data.question15 >= this.question15 &&
              data.question16 >= this.question16 &&
              data.question17 >= this.question17 &&
              data.question18 >= this.question18 &&
              data.question19 >= this.question19 &&
              data.question20 >= this.question20 &&
              data.question21 >= this.question21 &&
              data.question22 >= this.question22 &&
              data.question23 >= this.question23 &&
              data.question24 >= this.question24 &&
              data.question25 >= this.question25 &&
              data.question26 >= this.question26 &&
              data.question27 >= this.question27 &&
              data.question28 >= this.question28 &&
              data.question29 >= this.question29 &&
              data.question30 >= this.question30 &&
              data.question31 >= this.question31 &&
              data.question32 >= this.question32 &&
              data.question33 >= this.question33 &&
              data.question34 >= this.question34 &&
              data.question35 >= this.question35 &&
              data.question36 >= this.question36 &&
              data.question37 >= this.question37 &&
              data.question38 >= this.question38 &&
              data.question39 >= this.question39 &&
              data.question40 >= this.question40 &&
              data.question41 >= this.question41 &&
              data.question42 >= this.question42 &&
              data.question43 >= this.question43 &&
              data.question44 >= this.question44 &&
              data.question45 >= this.question45 &&
              data.question46 >= this.question46 &&
              data.question47 >= this.question47 &&
              data.question48 >= this.question48 &&
              data.question49 >= this.question49 &&
              data.question50 >= this.question50 &&
              data.question51 >= this.question51 &&
              data.question52 >= this.question52 &&
              data.question53 >= this.question53 &&
              data.question54 >= this.question54 &&
              data.question55 >= this.question55 &&
              data.question56 >= this.question56 &&
              data.question57 >= this.question57 &&
              data.question58 >= this.question58 &&
              data.question59 >= this.question59 &&
              data.question60 >= this.question60 &&
              data.question61 >= this.question61 &&
              data.question62 >= this.question62 &&
              data.question63 >= this.question63 &&
              data.question64 >= this.question64 &&
              data.question65 >= this.question65 &&
              data.question66 >= this.question66 &&
              data.question67 >= this.question67 &&
              data.question68 >= this.question68 &&
              !this.user.blockedUID.includes(uid) &&
              !this.user.matchingUID.includes(uid) &&
              !this.user.likedUID.includes(uid) &&
              !this.user.likeUID.includes(uid)
            ) {
              return {
                id: uid,
                data: data,
              };
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);

        if (docs.length > 0) {
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }

        if (this.isNextPage) {
          this.matchingTimelineList = [...this.matchingTimelineList, ...docs];
        } else {
          this.matchingTimelineList = docs;
        }

        this.isNextPage = false;
        this.isNextAction = false;
        this.isInit = false;
      });
    },
    async loadNextPage() {
      if (!this.lastVisible) return;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      this.isNextPage = true;
      this.isNextAction = true;

      // 次ページのクエリを作成
      const nextQuery = query(
        this.timelineQuery,
        startAfter(this.lastVisible),
        limit(10),
      );
      this.timelineQuery = nextQuery;

      this.loadTimeline(); // 次のページをロード
      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          behavior: "auto", // スムーズなスクロールを防ぐ
        });
      });
    },
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    headerActionClick(data) {
      if (data == "matching") {
        this.$router.push("/search/matching");
      }
    },
    linkUser(user) {
      setUserDetail(user);
      this.$router.push({
        name: "user-detail",
      });
    },
    truncateText(text) {
      if (text == undefined) {
        return "";
      }
      const maxLength = 60; // 最大文字数を指定
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return "--";
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
ul {
  display: inline;
}
li {
  list-style: none;
}
.matching {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 100px;
}
.reload {
  color: #784ba0;
}
.speech-bubble {
  position: relative;
  background: #784ba0;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  width: 100%;
  font-size: 14px;
}
.speech-bubble::after {
  content: "";
  position: absolute;
  top: -13px;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #784ba0 transparent;
}
</style>
