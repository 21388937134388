<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="true" :is_back="false" :title="''" type="none" />
    <div class="home-timeline">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div
                style="
                  padding-top: 12px;
                  padding-bottom: 15px;
                  padding-left: 15px;
                  padding-right: 15px;
                  background-color: #fff;
                "
              >
                <div class="form-group has-search">
                  <span
                    class="fa fa-search form-control-feedback"
                    style="color: #bcbcbc; font-size: 14px"
                  ></span>
                  <input
                    type="text"
                    class="form-control"
                    style="
                      background-color: #efefef;
                      border: 0;
                      color: #717171;
                      border-radius: 8px;
                      font-size: 14px;
                    "
                    placeholder="検索するタグを入力してください"
                    v-model="search_tag"
                    @keydown.enter="handleSetQuery(search_tag)"
                  />
                </div>
              </div>
              <div
                v-if="nextTimelineList.length != 0"
                class="text-center"
                style="padding: 5px 0"
              >
                <span class="reload" @click="reload">新しい投稿を読み込み</span>
              </div>
              <div v-if="isload">
                <div v-for="(item, index) in timelineList" :key="index">
                  <div v-if="user.blockedUID.includes(item.data.uid)">
                    <!-- blocked note -->
                  </div>
                  <div v-else-if="item.data.range == 'all'">
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div v-else-if="item.data.uid == user.uid">
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.data.range == 'like' &&
                      user.likedUID.includes(item.data.uid)
                    "
                  >
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.data.range == 'like' &&
                      user.likeUID.includes(item.data.uid)
                    "
                  >
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.data.range == 'group' &&
                      user.noteGroupUID.includes(item.data.uid)
                    "
                  >
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.data.range == 'follower' &&
                      user.followerUID.includes(item.data.uid)
                    "
                  >
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.data.range == 'matching' &&
                      user.matchingUID.includes(item.data.uid)
                    "
                  >
                    <noteView
                      :data="item.data"
                      :userData="user"
                      :mode="index === timelineList.length - 1 ? 1 : 0"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                </div>
                <div class="text-center" style="margin-top: 20px">
                  <button
                    v-if="timelineList.length"
                    style="
                      font-size: 12px;
                      background-color: #bbb;
                      border: solid 1px #bbb;
                      color: #fff;
                      padding: 3px 20px;
                      border-radius: 20px;
                      cursor: pointer;
                    "
                    @click="loadNextPage"
                  >
                    読み込み
                  </button>
                  <div v-if="isNextAction">
                    <VueSpinnerDots size="20" color="#784ba0" />
                  </div>
                </div>
              </div>
              <div class="text-center" style="padding-top: 50px" v-else>
                <VueSpinnerDots size="50" color="#784ba0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-left">
      <router-link to="/timeline/edit">
        <div
          style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #784ba0;
            color: #fff;
            padding-top: 15px;
            padding-left: 15px;
          "
        >
          <span class="material-symbols-rounded" style="font-size: 35px">
            edit_note
          </span>
        </div>
      </router-link>
    </div>
    <AppFooter :index="1" />
    <div v-if="isModal" class="modalView">
      <span
        class="material-symbols-rounded"
        style="
          color: #fff;
          font-size: 30px;
          position: absolute;
          right: 20px;
          top: 10px;
          z-index: 99999;
        "
        @click="isModal = false"
      >
        close
      </span>
      <img :src="modalImageUrl" @click="nextModal" />
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import noteView from "@/components/timeline/noteView.vue";
import { db } from "../../plugins/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      isload: true,
      user: [],
      timelineList: [],
      nextTimelineList: [],
      initNo: 0,
      search_tag: "",
      unsubscribe: null,
      todosCollection: null,
      isModal: false,
      modalImageUrl: "",
      modalImageIndex: 0,
      imageUrl: [],
      timelineQuery: null,
      isNextPage: false,
      lastVisible: null,
      isNextAction: false,
    };
  },
  async created() {
    this.user = store.user;

    this.todosCollection = collection(db, "notes");
    const baseQuery =
      this.search_tag != ""
        ? query(
            this.todosCollection,
            where("tags", "array-contains", this.search_tag.replace("#", "")),
            orderBy("createdAt", "desc"),
          )
        : query(this.todosCollection, orderBy("createdAt", "desc"));

    this.timelineQuery = query(baseQuery, limit(20));

    this.loadTimeline();
  },
  components: {
    AppHeader,
    AppFooter,
    noteView,
    VPullToRefresh,
  },
  watch: {
    data(newValue, oldValue) {
      console.log(`Data changed from ${oldValue} to ${newValue}`);
    },
  },
  methods: {
    async loadTimeline() {
      if (this.unsubscribe) this.unsubscribe();

      this.unsubscribe = onSnapshot(this.timelineQuery, async (snapshot) => {
        const docs = snapshot.docs.map((doc) => {
          const data = doc.data();
          const uid = doc.id;
          return {
            id: uid,
            data: data,
          };
        });

        /* if (this.initNo == 0) {
          this.initNo = 1;
          this.timelineList = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
        } else {
          var isChange = false;
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              isChange = true;
            }
          });

          if (isChange) {
            this.nextTimelineList = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));
          } else {
            this.timelineList = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));
          }
        } */
        if (docs.length > 0) {
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }

        if (this.isNextPage) {
          this.timelineList = [...this.timelineList, ...docs];
        } else {
          this.timelineList = docs;
        }

        this.isNextPage = false;
        this.isNextAction = false;
      });
    },
    async loadNextPage() {
      if (!this.lastVisible) return;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      this.isNextPage = true;
      this.isNextAction = true;

      // 次ページのクエリを作成
      const nextQuery = query(
        this.timelineQuery,
        startAfter(this.lastVisible),
        limit(20),
      );
      this.timelineQuery = nextQuery;

      this.loadTimeline(); // 次のページをロード
      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          behavior: "auto", // スムーズなスクロールを防ぐ
        });
      });
    },
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async reload() {
      this.isload = false;
      this.timelineList = this.nextTimelineList;
      this.nextTimelineList = [];
      await this.delay(1000);
      this.isload = true;
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleModalView(i, images) {
      this.isModal = true;
      this.imageUrl = images;
      this.modalImageUrl = images[i];
      this.modalImageIndex = i;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
    handleSetQuery(data) {
      this.search_tag = data;

      this.initNo = 0;
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      const baseQuery =
        this.search_tag != ""
          ? query(
              this.todosCollection,
              where("tags", "array-contains", this.search_tag.replace("#", "")),
              orderBy("createdAt", "desc"),
            )
          : query(this.todosCollection, orderBy("createdAt", "desc"));

      this.timelineList = [];

      this.timelineQuery = query(baseQuery, limit(20));

      this.loadTimeline();
    },
  },
};
</script>

<style scoped>
.reload {
  color: #784ba0;
}
label {
  font-size: 12px;
}
.home-timeline {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
}
.page {
  padding-top: 60px;
  padding-bottom: 200px;
}
.has-search .form-control {
  padding-left: 2.2rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.form-control::placeholder {
  color: #bcbcbc;
  font-size: 13px;
}
.footer-left {
  position: fixed;
  right: 15px;
  bottom: 90px;
  z-index: 99;
}
.modalView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.669);
  z-index: 99999;
  padding: 30px;
}
.modalView img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  object-position: 50% 50% !important;
}
</style>
