<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="false"
      :is_back="true"
      :title="''"
      type="none"
      @header-action-click="headerActionClick"
    />
    <div class="matching">
      <div class="page">
        <div
          v-if="isInit"
          style="padding-top: 200px; width: 100%; text-align: center"
        >
          <VueSpinnerDots size="50" color="#784ba0" />
        </div>
        <div v-else class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div class="subtitle-box">
                <div class="subtitle">イベント管理</div>
              </div>
              <div v-if="eventTimelineList.length">
                <div
                  v-for="item in eventTimelineList"
                  :key="item.id"
                  @click="editer(item.data)"
                >
                  <div
                    style="padding: 10px 20px; border-bottom: solid 1px #ddd"
                  >
                    <div style="padding-top: 10px">{{ item.data.title }}</div>
                    <div class="text-end" style="font-size: 12px">
                      {{ item.data.eventAtView }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-center" style="font-size: 12px">
                  <br /><br /><br />
                  イベントを登録してください
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-left">
      <div
        @click="editer(null)"
        style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #784ba0;
          color: #fff;
          padding-top: 15px;
          padding-left: 15px;
        "
      >
        <span class="material-symbols-rounded" style="font-size: 30px">
          edit_calendar
        </span>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { store, setEventInfo } from "../../../plugins/store";

export default {
  data() {
    return {
      user: [],
      eventTimelineList: [],
      initNo: 0,
      search_tag: "",
      unsubscribe: null,
      eventsCollection: null,
      isInit: true,
    };
  },
  async created() {
    this.user = store.user;

    this.eventsCollection = collection(db, "events");

    const timelineQuery = query(
      this.eventsCollection,
      where("uid", "==", this.user.uid),
      orderBy("eventAt", "desc"),
    );

    onSnapshot(timelineQuery, (snapshot) => {
      this.eventTimelineList = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
    });

    await this.delay(1000);
    this.isInit = false;
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    editer(event) {
      setEventInfo(event);
      this.$router.push({
        name: "mypage-event-edit",
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
ul {
  display: inline;
}
li {
  list-style: none;
}
.matching {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
}
.page {
  padding-top: 55px;
}
.reload {
  color: #784ba0;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
.footer-left {
  position: fixed;
  right: 15px;
  bottom: 20px;
  z-index: 99;
}
</style>
