<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div class="item-box" v-if="!isEntry">
                <div class="item-title">
                  名前・ニックネーム
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  なんてお呼びすればよいですか？（20文字まで）
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="名前・ニックネーム"
                    :style="name.length > 20 ? 'border-color: red' : ''"
                    v-model="name"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ name.length }}/20
                  </div>
                </div>
              </div>
              <div class="item-box" v-if="!isEntry">
                <div class="item-title">
                  居住地
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  現在住んでいる場所、または拠点にしている都道府県を選択してください（最大で５つ選択できます）
                </div>
                <div style="padding-top: 10px">
                  <v-select
                    :options="optionsArea"
                    v-model="selectedArea"
                    :selectable="
                      function () {
                        return selectedArea.length < 5;
                      }
                    "
                    :close-on-select="false"
                    placeholder="居住地"
                    multiple
                  />
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">プロフィール情報</div>
                <div class="item-description" style="padding-bottom: 10px">
                  あなたに当てはまる項目はなんですか？
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">url</div>
                  <div style="padding-bottom: 15px">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="サイトURLなど"
                      v-model="weburl"
                    />
                  </div>
                  <div class="item-subtitle">血液型</div>
                  <div style="padding-top: 5px">
                    <button
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBloods('A型')
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBloods('A型')"
                    >
                      A型
                    </button>
                    <button
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBloods('B型')
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBloods('B型')"
                    >
                      B型
                    </button>
                    <button
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBloods('O型')
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBloods('O型')"
                    >
                      O型
                    </button>
                    <button
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBloods('AB型')
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBloods('AB型')"
                    >
                      AB型
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">体格</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.bodyType"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBodyType(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBodyType(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">身長</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.bodyHight"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedBodyHight(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedBodyHight(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">お酒</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.alcohol"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedAlcohol(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedAlcohol(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">タバコ</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.smoking"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedSmoking(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedSmoking(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">ジェンダー</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.gender"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedGender(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedGender(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">性格</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.personality"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedPersonality(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedPersonality(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
                <div style="padding-bottom: 15px">
                  <div class="item-subtitle">性的指向</div>
                  <div style="padding-top: 5px">
                    <button
                      v-for="(item, i) in this.directional"
                      :key="i"
                      type="button"
                      style="margin-right: 5px; margin-bottom: 5px"
                      :class="
                        isSelectedDirectional(item)
                          ? 'btn btn-kinkyer m-plus-medium'
                          : 'btn btn-kinkyer-outline m-plus-medium'
                      "
                      @click="clickSelectedDirectional(item)"
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">募集タグ</div>
                <div class="item-description">
                  どのような相手を探しているか選択しましょう
                </div>
                <div style="padding-top: 5px">
                  <button
                    v-for="(item, i) in this.tags"
                    :key="i"
                    type="button"
                    style="margin-right: 5px; margin-bottom: 5px"
                    :class="
                      isSelectedTags(item)
                        ? 'btn btn-kinkyer m-plus-medium'
                        : 'btn btn-kinkyer-outline m-plus-medium'
                    "
                    @click="clickSelectedTags(item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
              <div class="item-box" style="padding-bottom: 0">
                <div class="item-title">S度【{{ sadistic }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="sadistic"
                    color="#543FDD"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">M度【{{ masochist }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="masochist"
                    color="#D85992"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">アピールタグ</div>
                <div class="item-description">
                  特にこの話題で交流したいタグがあれば選択しましょう<br />
                  ※必ず決められたタグで話す必要はありません
                </div>
                <div style="padding-top: 5px">
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagGreen"
                      v-on:change="isTagGreen = !isTagGreen"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(76, 175, 80)"
                      ></i
                      >&nbsp;Kinky世界の初心者
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagYellow"
                      v-on:change="isTagYellow = !isTagYellow"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 235, 59)"
                      ></i
                      >&nbsp;気軽に話しかけてね
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagCyan"
                      v-on:change="isTagCyan = !isTagCyan"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(0, 188, 212)"
                      ></i
                      >&nbsp;イベントやお店に行きたい
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagBlue"
                      v-on:change="isTagBlue = !isTagBlue"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(33, 150, 243)"
                      ></i
                      >&nbsp;何かおすすめ紹介して
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagPurple"
                      v-on:change="isTagPurple = !isTagPurple"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(156, 39, 176)"
                      ></i
                      >&nbsp;パートナー募集中
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagOrange"
                      v-on:change="isTagOrange = !isTagOrange"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 152, 0)"
                      ></i
                      >&nbsp;みんなで猥談しましょう
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagRed"
                      v-on:change="isTagRed = !isTagRed"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(244, 67, 54)"
                      ></i
                      >&nbsp;お悩み相談したい
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      uid: "",
      user: {},
      isEntry: true,
      btnName: "",
      name: "",
      weburl: "",
      selectedArea: [],
      selectedBloods: [],
      selectedBodyType: [],
      selectedBodyHight: [],
      selectedAlcohol: [],
      selectedSmoking: [],
      /// ここから複数選択
      selectedGender: [],
      selectedPersonality: [],
      selectedDirectional: [],
      selectedTags: [],

      sadistic: 50,
      masochist: 50,
      isTagGreen: false,
      isTagYellow: false,
      isTagCyan: false,
      isTagBlue: false,
      isTagPurple: false,
      isTagOrange: false,
      isTagRed: false,

      /// オプション設定
      optionsArea: [
        "北海道（札幌）",
        "北海道（道東）",
        "北海道（道南）",
        "北海道（道北）",
        "北海道（道央）",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
        "海外",
        "ネットのみ",
      ],
      bodyType: [
        "スリム",
        "やや細め",
        "普通",
        "ややぽっちゃり",
        "ぽっちゃり",
        "細マッチョ",
        "マッチョ",
        "がっしり",
      ],
      bodyHight: [
        "〜140cm",
        "141cm〜145cm",
        "146cm〜150cm",
        "151cm〜155cm",
        "156cm〜160cm",
        "161cm〜165cm",
        "166cm〜170cm",
        "171cm〜175cm",
        "176cm〜180cm",
        "181cm〜185cm",
        "186cm〜190cm",
        "191cm〜195cm",
        "196cm〜200cm",
        "201cm〜",
      ],
      alcohol: [
        "飲む",
        "時々飲む",
        "機会があれば飲む",
        "付き合い程度に飲む",
        "全く飲まない",
      ],
      smoking: [
        "吸う",
        "吸う（電子タバコ）",
        "非喫煙者の前では吸わない",
        "吸わない",
      ],
      gender: [
        "異性愛",
        "同性愛",
        "バイセクシャル",
        "アセクシャル",
        "アロマンティック",
        "モノガミー",
        "ポリアモリー",
        "クエスチョニング",
        "Xジェンダー",
        "FtM",
        "FtX",
        "MtF",
        "MtX",
        "女装子",
        "男の娘",
      ],
      personality: [
        "社交的",
        "ネガティブ気味",
        "寂しがり屋",
        "気分屋",
        "天然かも",
        "人懐っこい",
        "人見知り",
        "刺激求む！",
        "むっつり",
        "エロ全開",
        "甘えたがり",
        "世話焼き",
        "聞き上手",
        "話し上手",
        "好奇心旺盛",
        "フットワーク軽い",
        "マイペース",
        "ポジティブ",
        "インドア",
        "アウトドア",
        "真面目・几帳面",
        "落ち着いた雰囲気",
        "面白い",
        "優しい",
        "明るい",
        "一途な面もある",
        "尽くしがち",
        "ドライな一面あり",
      ],
      directional: [
        "#攻めタイプ",
        "#受けタイプ",
        "#スイッチタイプ",
        "#自分の欲に忠実タイプ",
        "#なんとなく自覚",
        "#どんなタイプが探し中",
        "#性癖迷子",
        "#シチュエーション萌え",
        "#BDSM好き",
        "#緊縛好き",
        "#鞭好き",
        "#蝋燭好き",
        "#ぐりぐら好き",
        "#スパンキング好き",
        "#フェティッシュ好き",
        "#相手の反応を見るのが好き",
        "#相手が楽しそうにしてるのが好き",
        "#育成・調教が好き",
        "#粘膜接種は控えめ",
        "#同性に遊ばれたい・遊びたい",
        "#異性に遊ばれたい・遊びたい",
        "#加虐欲",
        "#被虐欲",
        "#支配・所有欲",
        "#ドミ・サブ向けSM主義",
        "#プレイのみの快楽主義",
        "#女性上位主義",
        "#精神的に屈服されたい",
        "#肉体的に負かされたい",
        "#一緒に性癖を育みたい",
        "#日常と非日常の線引きはしっかりと",
        "#プライベートは互いに大事に",
        "#甘えたい",
        "#甘やかしたい",
        "#主導権を持ちたい",
        "#相手に委ねたい",
        "#相手の欲求に応えたい",
        "#主導権は持ちたい",
        "#相手に委ねたい",
      ],
      tags: [
        "恋人募集",
        "日常的なパートナー募集",
        "一時的なプレイパートナー募集",
        "友達募集",
        "趣味友や仲間募集",
        "真剣交際",
        "既婚・恋人がいます",
        "他にパートナーいます",
        "ペット・従者がいます",
        "お仕えする主がいます",
        "フリーです",
        "まずはDMでじっくり話したい",
        "カフェなどでお話しする",
        "すぐ会いたい",
        "デートしたい",
        "写真は交換したい",
        "電話や通話をしたい",
        "界隈でお仕事してます",
      ],
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    this.name = this.user.name == undefined ? "" : this.user.name;
    this.weburl = this.user.weburl == undefined ? "" : this.user.weburl;
    if (this.$route.params.type == "mod") {
      this.selectedArea = this.user.area == undefined ? [] : this.user.area;
      this.selectedBloods =
        this.user.selectedBloods == undefined ? [] : this.user.selectedBloods;
      this.selectedBodyType =
        this.user.selectedBodyType == undefined
          ? []
          : this.user.selectedBodyType;
      this.selectedBodyHight =
        this.user.selectedBodyHight == undefined
          ? []
          : this.user.selectedBodyHight;
      this.selectedAlcohol =
        this.user.selectedAlcohol == undefined ? [] : this.user.selectedAlcohol;
      this.selectedSmoking =
        this.user.selectedSmoking == undefined ? [] : this.user.selectedSmoking;
      this.selectedGender =
        this.user.selectedGender == undefined ? [] : this.user.selectedGender;
      this.selectedPersonality =
        this.user.selectedPersonality == undefined
          ? []
          : this.user.selectedPersonality;
      this.selectedDirectional =
        this.user.selectedDirectional == undefined
          ? []
          : this.user.selectedDirectional;
      this.selectedTags =
        this.user.selectTag == undefined ? [] : this.user.selectTag;
      this.sadistic = this.user.sadistic == undefined ? 50 : this.user.sadistic;
      this.masochist =
        this.user.masochist == undefined ? 50 : this.user.masochist;
      this.isTagGreen =
        this.user.isTagGreen == undefined ? false : this.user.isTagGreen;
      this.isTagYellow =
        this.user.isTagYellow == undefined ? false : this.user.isTagYellow;
      this.isTagCyan =
        this.user.isTagCyan == undefined ? false : this.user.isTagCyan;
      this.isTagBlue =
        this.user.isTagBlue == undefined ? false : this.user.isTagBlue;
      this.isTagPurple =
        this.user.isTagPurple == undefined ? false : this.user.isTagPurple;
      this.isTagOrange =
        this.user.isTagOrange == undefined ? false : this.user.isTagOrange;
      this.isTagRed =
        this.user.isTagRed == undefined ? false : this.user.isTagRed;
    }
  },
  components: {
    AppHeader,
    vSelect,
  },
  methods: {
    async update() {
      try {
        if ((this.name == "" || this.name.length > 20) && !this.isEntry) {
          this.$toast.show("名前・ニックネームを入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        } else if (this.selectedArea.length == 0 && !this.isEntry) {
          this.$toast.show("居住地を入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        }

        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          name: this.name,
          weburl: this.weburl,
          selectedBloods: this.selectedBloods,
          selectedBodyType: this.selectedBodyType,
          selectedBodyHight: this.selectedBodyHight,
          selectedAlcohol: this.selectedAlcohol,
          selectedSmoking: this.selectedSmoking,
          selectedGender: this.selectedGender,
          selectedPersonality: this.selectedPersonality,
          selectedDirectional: this.selectedDirectional,
          selectTag: this.selectedTags,
          sadistic: this.sadistic,
          masochist: this.masochist,
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
        });

        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          name: this.name,
          weburl: this.weburl,
          selectedBloods: this.selectedBloods,
          selectedBodyType: this.selectedBodyType,
          selectedBodyHight: this.selectedBodyHight,
          selectedAlcohol: this.selectedAlcohol,
          selectedSmoking: this.selectedSmoking,
          selectedGender: this.selectedGender,
          selectedPersonality: this.selectedPersonality,
          selectedDirectional: this.selectedDirectional,
          selectTag: this.selectedTags,
          sadistic: this.sadistic,
          masochist: this.masochist,
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
        });

        this.user.name = this.name;
        this.user.weburl = this.weburl;
        this.user.selectedBloods = this.selectedBloods;
        this.user.selectedBodyType = this.selectedBodyType;
        this.user.selectedBodyHight = this.selectedBodyHight;
        this.user.selectedAlcohol = this.selectedAlcohol;
        this.user.selectedSmoking = this.selectedSmoking;
        this.user.selectedGender = this.selectedGender;
        this.user.selectedPersonality = this.selectedPersonality;
        this.user.selectedDirectional = this.selectedDirectional;
        this.user.selectTag = this.selectedTags;
        this.user.sadistic = this.sadistic;
        this.user.masochist = this.masochist;
        this.user.isTagGreen = this.isTagGreen;
        this.user.isTagYellow = this.isTagYellow;
        this.user.isTagCyan = this.isTagCyan;
        this.user.isTagBlue = this.isTagBlue;
        this.user.isTagPurple = this.isTagPurple;
        this.user.isTagOrange = this.isTagOrange;
        this.user.isTagRed = this.isTagRed;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/detail/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
    isSelectedBloods(value) {
      return this.selectedBloods.includes(value);
    },
    clickSelectedBloods(value) {
      if (this.isSelectedBloods(value)) {
        this.selectedBloods.length = 0;
      } else {
        this.selectedBloods.length = 0;
        this.selectedBloods.push(value);
      }
    },
    isSelectedBodyType(value) {
      return this.selectedBodyType.includes(value);
    },
    clickSelectedBodyType(value) {
      if (this.isSelectedBodyType(value)) {
        this.selectedBodyType.length = 0;
      } else {
        this.selectedBodyType.length = 0;
        this.selectedBodyType.push(value);
      }
    },
    isSelectedBodyHight(value) {
      return this.selectedBodyHight.includes(value);
    },
    clickSelectedBodyHight(value) {
      if (this.isSelectedBodyHight(value)) {
        this.selectedBodyHight.length = 0;
      } else {
        this.selectedBodyHight.length = 0;
        this.selectedBodyHight.push(value);
      }
    },
    isSelectedAlcohol(value) {
      return this.selectedAlcohol.includes(value);
    },
    clickSelectedAlcohol(value) {
      if (this.isSelectedAlcohol(value)) {
        this.selectedAlcohol.length = 0;
      } else {
        this.selectedAlcohol.length = 0;
        this.selectedAlcohol.push(value);
      }
    },
    isSelectedSmoking(value) {
      return this.selectedSmoking.includes(value);
    },
    clickSelectedSmoking(value) {
      if (this.isSelectedSmoking(value)) {
        this.selectedSmoking.length = 0;
      } else {
        this.selectedSmoking.length = 0;
        this.selectedSmoking.push(value);
      }
    },
    isSelectedGender(value) {
      return this.selectedGender.includes(value);
    },
    clickSelectedGender(value) {
      if (this.isSelectedGender(value)) {
        const index = this.selectedGender.indexOf(value);
        if (index !== -1) {
          this.selectedGender.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedGender.push(value);
      }
    },
    isSelectedPersonality(value) {
      return this.selectedPersonality.includes(value);
    },
    clickSelectedPersonality(value) {
      if (this.isSelectedPersonality(value)) {
        const index = this.selectedPersonality.indexOf(value);
        if (index !== -1) {
          this.selectedPersonality.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedPersonality.push(value);
      }
    },
    isSelectedDirectional(value) {
      return this.selectedDirectional.includes(value);
    },
    clickSelectedDirectional(value) {
      if (this.isSelectedDirectional(value)) {
        const index = this.selectedDirectional.indexOf(value);
        if (index !== -1) {
          this.selectedDirectional.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedDirectional.push(value);
      }
    },
    isSelectedTags(value) {
      return this.selectedTags.includes(value);
    },
    clickSelectedTags(value) {
      if (this.isSelectedTags(value)) {
        const index = this.selectedTags.indexOf(value);
        if (index !== -1) {
          this.selectedTags.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedTags.push(value);
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
