<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="message-list">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="text-center" style="padding: 10px 0; font-weight: 600">
              ホーム画面への追加
            </div>
            <div class="tab-box">
              <div
                @click="tabIndex = 1"
                class="tab-item text-center"
                :style="
                  tabIndex == 1
                    ? 'cursor: pointer; background-color: #784ba0; color:#fff; font-weight:600'
                    : 'cursor: pointer; background-color: #efefef; color:#888; font-weight:400'
                "
              >
                Androidの方
              </div>
              <div
                @click="tabIndex = 2"
                class="tab-item text-center"
                :style="
                  tabIndex == 2
                    ? 'cursor: pointer; background-color: #784ba0; color:#fff; font-weight:600'
                    : 'cursor: pointer; background-color: #efefef; color:#888; font-weight:400'
                "
              >
                iPhoneの方
              </div>
            </div>
            <div class="text-center" v-if="tabIndex == 1">
              <div
                class="text-start"
                style="font-size: 12px; padding: 10px 20px"
              >
                Chromeでアクセス後に表示されたメッセージ、もしくはメニューより「アプリをインストール」にて追加します
              </div>
              <img
                :src="
                  require(
                    '@/assets/images/pwa_android_' + androidIndex + '.webp',
                  )
                "
                style="
                  width: 55%;
                  position: relative;
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              />
              <div>
                <span
                  class="material-symbols-rounded"
                  :style="
                    androidIndex == 1
                      ? 'cursor: pointer; font-size: 15px; color: #784ba0'
                      : 'cursor: pointer; font-size: 15px; color: #aaa'
                  "
                  @click="androidIndex = 1"
                >
                  radio_button_unchecked </span
                >&nbsp;&nbsp;
                <span
                  class="material-symbols-rounded"
                  :style="
                    androidIndex == 2
                      ? 'cursor: pointer; font-size: 15px; color: #784ba0'
                      : 'cursor: pointer; font-size: 15px; color: #aaa'
                  "
                  @click="androidIndex = 2"
                >
                  radio_button_unchecked
                </span>
              </div>
            </div>
            <div class="text-center" v-if="tabIndex == 2">
              <div
                class="text-start"
                style="font-size: 12px; padding: 10px 20px"
              >
                Safariでアクセスし、下にある共有アイコンをタップ後に「ホーム画面に追加」を押下します
              </div>
              <img
                :src="
                  require('@/assets/images/pwa_iphone_' + iphoneIndex + '.webp')
                "
                style="
                  width: 55%;
                  position: relative;
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              />
              <div>
                <span
                  class="material-symbols-rounded"
                  :style="
                    iphoneIndex == 1
                      ? 'cursor: pointer; font-size: 15px; color: #784ba0'
                      : 'cursor: pointer; font-size: 15px; color: #aaa'
                  "
                  @click="iphoneIndex = 1"
                >
                  radio_button_unchecked </span
                >&nbsp;&nbsp;
                <span
                  class="material-symbols-rounded"
                  :style="
                    iphoneIndex == 2
                      ? 'cursor: pointer; font-size: 15px; color: #784ba0'
                      : 'cursor: pointer; font-size: 15px; color: #aaa'
                  "
                  @click="iphoneIndex = 2"
                >
                  radio_button_unchecked </span
                >&nbsp;&nbsp;
                <span
                  class="material-symbols-rounded"
                  :style="
                    iphoneIndex == 3
                      ? 'cursor: pointer; font-size: 15px; color: #784ba0'
                      : 'cursor: pointer; font-size: 15px; color: #aaa'
                  "
                  @click="iphoneIndex = 3"
                >
                  radio_button_unchecked
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";

export default {
  data() {
    return {
      uid: "",
      tabIndex: 1,
      androidIndex: 1,
      iphoneIndex: 1,
    };
  },
  created() {},
  components: {
    AppHeader,
  },
  methods: {},
};
</script>

<style scoped>
.message-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 60px;
}
.tab-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  padding: 5px 0;
}
.tab-item {
  width: calc(100% / 2);
  height: 40px;
  font-size: 13px;
  border: solid 3px #fff;
  border-radius: 6px;
  padding-top: 6px;
}
</style>
