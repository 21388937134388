<template>
  <div class="container-box">
    <div v-for="(image, i) in imageUrl" :key="i" class="item">
      <div @click="(isModal = !isModal), setModal(i)">
        <img :src="image" class="thumbnail" />
      </div>
    </div>
  </div>
  <div v-if="isModal" class="modalSubImageView">
    <span
      class="material-symbols-rounded"
      style="
        color: #fff;
        font-size: 30px;
        position: absolute;
        right: 20px;
        top: 10px;
        z-index: 99999999;
      "
      @click="isModal = fallse"
    >
      close
    </span>
    <img :src="modalImageUrl" @click="nextModal" />
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";

export default {
  data() {
    return {
      imageUrl: [],
      userIds: [],
      isModal: false,
      modalImageUrl: "",
      modalImageIndex: 0,
    };
  },
  props: ["uids"],
  created() {
    this.userIds = this.uids;
    this.getUserIcon();
  },
  methods: {
    setModal(index) {
      this.modalImageUrl = this.imageUrl[index];
      this.modalImageIndex = index;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
    async getUserIcon() {
      for (var i in this.userIds) {
        const imageRef = storageRef(storage, this.userIds[i]);
        try {
          const url = await getDownloadURL(imageRef);
          this.imageUrl.push(url);
        } catch (error) {
          console.log(
            "---------- components/user/list_profile_image ---------",
          );
          console.error("Error fetching image URL:", error);
        }
      }
      /* const imageRef = storageRef(
        storage,
        `users/icon/${this.userId}/user_icon1.png`
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
      } */
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: solid 1px #eee;
}
.container-box {
  display: flex; /* 横に並べる */
  overflow-x: auto; /* 横スクロールを許可 */
  white-space: nowrap; /* 折り返しを防止 */
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.item {
  min-width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSubImageView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.669);
  z-index: 9999999;
  padding: 30px;
}
.modalSubImageView img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  object-position: 50% 50% !important;
}
</style>
