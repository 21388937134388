<template>
  <AppHeader :is_icon="false" :is_back="true" :title="'subscription'" type="" />
  <div class="subscription-page">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="text-center" style="padding-top: 15px">
              ご利用中のプラン
              <div style="padding: 10px; font-weight: 600; font-size: 18px">
                <div v-if="user.isSubscription">Kinkyer+</div>
                <div v-else>Free</div>
              </div>
              <div style="padding: 10px">
                <div>
                  <div
                    class="text-start"
                    style="
                      padding: 10px 20px;
                      font-size: 14px;
                      font-weight: 700;
                      background-color: rgb(244, 245, 247);
                      border-radius: 6px;
                    "
                  >
                    Kinkyer＋プランの解約を行います<br />
                    プラン解約後はKinkyer＋の有効期限が過ぎたのちにFreeへと変更いたしますのでご注意ください<br /><br />
                    解約時は決済代行システムに必要な下記の情報を入力ください<br /><br />
                    会員番号：{{ user.sendid }}<br />
                    パスワード：NA<br /><br />
                    決済代行システムで解約後は再ログインをお願いいたします
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="
              openExternalLink(
                'https://secure.telecomcredit.co.jp/inetcredit/secure/member.pl?clientip=31728',
              )
            "
          >
            Kinkyer＋プラン解約へ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { store } from "../../plugins/store";
import { db } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: {},
      login: null,
      isEdit: false,
      isUserRemove: false,
    };
  },
  async created() {
    const docUserRef = doc(db, "users", store.user.uid);
    const docUserSnap = await getDoc(docUserRef);
    this.user = docUserSnap.data();
  },
  components: {
    AppHeader,
  },
  methods: {
    openExternalLink(url) {
      window.open(url, "_blank", "noopener,noreferrer");
    },
  },
};
</script>

<style scoped>
.subscription-page {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.box-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  width: 100%;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
