<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="false"
      :is_back="true"
      :title="'投稿リスト'"
      type="none"
    />
    <div
      class="home-timeline-history"
      ref="scrollContainer"
      style="overflow-y: auto"
    >
      <div class="page" :style="'padding-bottom: ' + browserHeight + 'px'">
        <div
          v-if="isInit"
          style="padding-top: 200px; width: 100%; text-align: center"
        >
          <VueSpinnerDots size="50" color="#784ba0" />
        </div>
        <div v-else class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                v-for="(item, index) in notes"
                :key="index"
                :style="index === notes.length - 1 ? 'padding-top: 15px;' : ''"
              >
                <a id="tag" v-if="index === notes.length - 1"></a>
                <div v-if="index === notes.length - 1">
                  <noteView
                    :data="item"
                    :userData="user"
                    mode="4"
                    click="true"
                    @handle-set-query="handleSetQuery"
                    @handle-modal-view="handleModalView"
                  />
                </div>
                <div style="display: flex" v-else>
                  <div style="width: 0px; display: flex">
                    <div
                      style="
                        border-left: 2px solid rgb(221, 221, 221);
                        margin-top: 50px;
                        margin-left: 30px;
                        margin-bottom: 20px;
                        position: relative;
                        top: 0;
                        bottom: 5;
                      "
                    ></div>
                  </div>
                  <div :style="'width: ' + browserWidth + 'px'">
                    <noteView
                      :data="item"
                      :userData="user"
                      mode="4"
                      click="true"
                      @handle-set-query="handleSetQuery"
                      @handle-modal-view="handleModalView"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter :index="1" />
    <div v-if="isModal" class="modalView">
      <span
        class="material-symbols-rounded"
        style="
          color: #fff;
          font-size: 30px;
          position: absolute;
          right: 20px;
          top: 10px;
          z-index: 99999;
        "
        @click="isModal = false"
      >
        close
      </span>
      <img :src="modalImageUrl" @click="nextModal" />
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import noteView from "@/components/timeline/noteView.vue";
import { db } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { store, setNoteLineHistory } from "../../plugins/store";

export default {
  data() {
    return {
      isInit: true,
      user: {},
      lineHistory: {},
      notes: [],
      isModal: false,
      modalImageUrl: "",
      modalImageIndex: 0,
      imageUrl: [],
      browserHeight: 0,
      browserWidth: 0,
    };
  },
  async created() {
    this.user = store.user;
    this.lineHistory =
      store.noteLineHistory == undefined ? {} : store.noteLineHistory;

    // eslint-disable-next-line no-prototype-builtins
    if (this.lineHistory.hasOwnProperty(this.$route.params.noteid)) {
      this.notes = this.lineHistory[this.$route.params.noteid];
    } else {
      // ない場合はstoreに格納する（firestoreのアクセスを少しでも効率的に
      const docNotesRef = doc(db, "notes", this.$route.params.noteid);
      const docNotesSnap = await getDoc(docNotesRef);
      const noteData = docNotesSnap.data();

      for (var i in noteData.lineHistory) {
        const docNotesRef = doc(db, "notes", noteData.lineHistory[i]);
        const docNotesSnap = await getDoc(docNotesRef);
        this.notes.push(docNotesSnap.data());
      }

      var key = this.$route.params.noteid;
      this.lineHistory[key] = this.notes;
      setNoteLineHistory(this.lineHistory);
    }

    await this.delay(100);
    this.isInit = false;
    this.$nextTick(() => {
      this.scrollToTag();
    });
  },
  mounted() {
    this.getBrowserHeight();
    this.getBrowserWidth();
    window.addEventListener("resize", this.getBrowserHeight);
    window.addEventListener("resize", this.getBrowserWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getBrowserHeight);
    window.removeEventListener("resize", this.getBrowserWidth);
  },
  components: {
    AppHeader,
    AppFooter,
    noteView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleModalView(i, images) {
      this.isModal = true;
      this.imageUrl = images;
      this.modalImageUrl = images[i];
      this.modalImageIndex = i;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleSetQuery(data) {
      // eslint-disable-next-line no-unused-vars
      data = null;
    },
    scrollToTag() {
      const scrollContainer = this.$refs.scrollContainer;
      const tagElement = document.getElementById("tag");
      if (tagElement && scrollContainer) {
        // tagの位置を取得
        const tagPosition =
          tagElement.getBoundingClientRect().top + scrollContainer.scrollTop;
        // 100px上にスクロール
        scrollContainer.scrollTo({
          top: tagPosition - 90,
          behavior: "smooth",
        });
      }
    },
    getBrowserHeight() {
      this.browserHeight = window.innerHeight - 220;
    },
    getBrowserWidth() {
      this.browserWidth = window.innerWidth - 30;
    },
  },
};
</script>

<style scoped>
.home-timeline-history {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
}
.modalView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.669);
  z-index: 99999;
  padding: 30px;
}
.modalView img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  object-position: 50% 50% !important;
}
</style>
