<template>
  <div class="header-back">
    <div class="header-item header-item-size" style="width: 30%">
      <div>
        <a @click="$router.go(-1)" style="cursor: pointer">
          <span class="material-symbols-rounded icon"> arrow_back </span>
        </a>
      </div>
    </div>
    <div class="text-center header-item" style="padding-top: 5px; width: 40%">
      <span class="title cherry-bomb-one-regular">Kinkyer</span>
    </div>
    <div class="header-item header-item-size" style="width: 30%"></div>
  </div>
  <div class="entry-top">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="text-center title">新規登録</div>
            <div class="text-center subtitle">
              認証を行なってもSNSや外部に情報が公開されることはありません
            </div>
            <br />
            <div class="text-center">
              <div style="padding-bottom: 10px">
                <button
                  type="button"
                  class="btn btn-kinkyer-outline m-plus-medium"
                  @click="setTwitter"
                >
                  X(Twitter)で続ける
                </button>
              </div>
              <div style="padding-bottom: 10px">
                <button
                  type="button"
                  class="btn btn-kinkyer-outline m-plus-medium"
                  @click="setPassword"
                >
                  メールアドレスで続ける
                </button>
              </div>
            </div>
            <br />
            <div style="width: 280px; margin: auto">
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckTerms"
                  v-on:change="isCheckTerms = !isCheckTerms"
                />
                <label class="form-check-label">
                  <a href="https://app.kinkyer.jp/terms/" target="_blank"
                    >利用規約</a
                  >
                </label>
              </div>
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckPrivacypolicy"
                  v-on:change="isCheckPrivacypolicy = !isCheckPrivacypolicy"
                />
                <label class="form-check-label">
                  <a
                    href="https://app.kinkyer.jp/privacypolicy/"
                    target="_blank"
                    >プライバシーポリシー</a
                  >
                </label>
              </div>
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckOver18"
                  v-on:change="isCheckOver18 = !isCheckOver18"
                />
                <label class="form-check-label" style="color: #784ba0">
                  18歳以上です
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isLoad"
        style="
          position: fixed;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: rgb(0 0 0 / 50%);
          margin: 0 auto;
          padding-top: 200px;
          text-align: center;
          z-index: 999;
        "
      >
        <div style="position: absolute; z-index: 9999; width: 100%">
          <VueSpinnerOval size="50" color="#fff" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "../../plugins/firebase";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { setUser } from "../../plugins/store";

export default {
  data() {
    return {
      isLoad: false,
      isCheckTerms: false,
      isCheckPrivacypolicy: false,
      isCheckOver18: false,
    };
  },
  methods: {
    setTwitter() {
      if (!this.isCheckTerms) {
        this.$toast.show("利用規約を確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckPrivacypolicy) {
        this.$toast.show("プライバシーポリシーを確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckOver18) {
        this.$toast.show("18歳未満のご利用はできません", {
          position: "bottom",
          duration: 2000,
        });
      } else {
        this.loginTwitter();
      }
    },
    setPassword() {
      if (!this.isCheckTerms) {
        this.$toast.show("利用規約を確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckPrivacypolicy) {
        this.$toast.show("プライバシーポリシーを確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckOver18) {
        this.$toast.show("18歳未満のご利用はできません", {
          position: "bottom",
          duration: 2000,
        });
      } else {
        this.$router.push("/entry/password");
      }
    },
    loginTwitter() {
      this.isLoad = true;
      const provider = new TwitterAuthProvider();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          if (user) {
            await setDoc(doc(db, "users", user.uid), {
              uid: user.uid,
              token: "",
              isEntry: false,
              isRegistration: false,
              isIdentification: false,
              isSubscription: false,
              isViewSTDtest: false,
              isViewLicense: false,
              isViewExchange: false,
              isAuthMail: true,
              isAuthTwitter: false,
              isPreUser: false,
              createdAt: new Date(),
              loginAt: new Date(),
              recommendCount: 3,
              lastRecommendAt: new Date(),
              maxGoodCount: 10,
              lastGoodAt: new Date(),
              isReplyGood: true,
              lastReplyGoodAt: new Date(),
              isDM: true,
              groupChatEntryCount: 3,
              groupChatCreateCount: 10,
              lastGroupChatEntryAt: new Date(),
              lastGroupChatCreateAt: new Date(),
              isAgeView: true,
              isSensitiveImage: true,
            });

            await setDoc(doc(db, "authLink", user.uid), {
              uid: user.uid,
              type: "twitter",
            });

            const docRef = doc(db, "authLink", user.uid);
            const docSnap = await getDoc(docRef);

            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            setUser(docUserSnap.data(), docSnap.data().uid);
            this.user = docUserSnap.data();
            this.uid = docSnap.data().uid;

            this.$router.push("/user/edit/info/entry");

            this.isLoad = false;
          } else {
            this.isLoad = false;
            // 失敗時処理
            this.$toast.show("有効なアカウントではありません", {
              type: "error",
              position: "bottom",
              duration: 2000,
            });
          }
        })
        .catch(() => {
          this.isLoad = false;
          // 失敗時処理
          this.$toast.show("有効なアカウントではありません", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style scoped>
a {
  color: #784ba0 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #784ba0 !important;
  text-decoration: underline !important;
}
.entry-top {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 60px;
}
.title {
  padding-top: 20px;
  font-weight: bold;
  font-size: 18px;
}
.subtitle {
  padding: 20px 0;
  font-size: 11px;
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  width: 280px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #784ba016;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #784ba016;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border-color: #784ba0;
}

.btn-kinkyer {
  color: #784ba0;
  border-radius: 8px;
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border-color: #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25);
}
.icon {
  color: #fff;
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #fff;
  font-size: 30px;
}

.header-back {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: -moz-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: -webkit-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background-color: #784ba0;
  z-index: 999;
}
</style>
