<template>
  <div
    style="padding: 10px 15px; border-bottom: solid 1px #ddd; display: flex"
    @click="setMessage"
  >
    <div v-if="imageUrl">
      <img :src="imageUrl" @click="linkUser" />
    </div>
    <div v-else>
      <img :src="require('@/assets/icon_user.webp')" @click="linkUser" />
    </div>
    <div style="padding-left: 10px; padding-top: 7px">
      {{ user.name }}
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      imageUrl: null,
      userId: "",
      name: "",
      pageType: "",
      likeUID: "",
      likedUID: "",
    };
  },
  props: ["item", "type", "like_uid", "liked_uid"],
  async mounted() {
    this.user = this.item;
    this.pageType = this.type;
    this.likeUID = this.like_uid;
    this.likedUID = this.liked_uid;
    this.loginUser = store.user;

    this.user.isIconUrl =
      this.user.isIconUrl == undefined ? true : this.user.isIconUrl;

    if (this.user.isIconUrl) {
      this.getUserIcon();
    }
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      const docUserRef = doc(db, "users", this.user.uid);
      const docUserSnap = await getDoc(docUserRef);
      setUserDetail(docUserSnap.data());
      this.$router.push({
        name: "user-detail",
      });
    },
    async setMessage() {
      if (this.pageType == "like") {
        this.$router.push("/message/appea/" + this.user.roomId);
      } else if (this.pageType == "matching") {
        this.$router.push("/message/person/" + this.user.matching_room);
      } else if (this.pageType == "group") {
        // グループチャット
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.list-sub-tag {
  font-size: 10px;
  white-space: nowrap;
}
</style>
