<template>
  <div class="header-back">
    <div class="header-item header-item-size" style="width: 30%">
      <div v-if="isIcon">
        <div v-if="imageUrl">
          <router-link to="/mypage">
            <img :src="imageUrl" />
          </router-link>
        </div>
        <div v-else>
          <router-link to="/mypage">
            <img :src="require('@/assets/icon_user.webp')" />
          </router-link>
        </div>
      </div>
      <div v-if="isBack">
        <a @click="$router.go(-1)" style="cursor: pointer">
          <span class="material-symbols-rounded icon"> arrow_back </span>
        </a>
      </div>
    </div>
    <div class="text-center header-item" style="padding-top: 5px; width: 40%">
      <span v-if="pageTitle == ''" class="title cherry-bomb-one-regular"
        >Kinkyer</span
      >
      <span
        v-else-if="pageTitle == 'subscription'"
        class="title cherry-bomb-one-regular"
        >Kinkyer+</span
      >
      <div
        v-else
        class="title m-plus-regular"
        style="font-size: 20px; padding-top: 10px"
      >
        {{ pageTitle }}
      </div>
    </div>
    <div class="header-item header-item-size" style="width: 30%">
      <div
        v-if="actionsType == 'skip_icon'"
        class="text-end"
        style="
          color: #fff;
          margin-right: 10px;
          font-size: 15px;
          font-weight: 700;
          margin-top: 5px;
        "
      >
        <a @click="headerActionClick('skip_icon')">スキップ</a>
      </div>
      <div
        v-if="actionsType == 'person'"
        class="text-end"
        style="color: #fff; margin-right: 5px"
      >
        <a @click="headerActionClick('person')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            account_circle
          </span>
        </a>
      </div>
      <div
        v-if="actionsType == 'event'"
        class="text-end"
        style="color: #fff; margin-right: 5px"
      >
        <a @click="headerActionClick('event_today')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            today
          </span>
        </a>
        <!-- &nbsp;
        <a @click="headerActionClick('event')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            confirmation_number
          </span>
        </a> -->
      </div>
      <div
        v-if="actionsType == 'matching'"
        class="text-end"
        style="color: #fff; margin-right: 5px"
      >
        <a @click="headerActionClick('matching')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            search
          </span>
        </a>
      </div>
      <div
        v-if="actionsType == 'userDetail'"
        class="text-end"
        style="color: #fff; margin-right: 5px"
      >
        <a @click="headerActionClick('userDetail-timeline')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            speaker
          </span>
        </a>
        &nbsp;
        <a @click="headerActionClick('userDetail-sheet')">
          <span class="material-symbols-rounded" style="font-size: 35px">
            more_vert
          </span>
        </a>
      </div>
    </div>
  </div>
  <div
    style="
      width: 20px;
      height: 20px;
      z-index: 1000;
      position: fixed;
      top: 49px;
      left: -1px;
    "
  >
    <img
      :src="require('@/assets/header_left.webp')"
      style="width: 20px; height: 20px; border-radius: 0"
    />
  </div>
  <div
    style="
      width: 20px;
      height: 20px;
      z-index: 1000;
      position: fixed;
      top: 49px;
      right: -1px;
    "
  >
    <img
      :src="require('@/assets/header_right.webp')"
      style="width: 20px; height: 20px; border-radius: 0"
    />
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "./../plugins/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import {
  store,
  setUser,
  setUserIconUrl,
  removeUserIconUrl,
  removeAll,
  setLastLognAt,
} from "./../plugins/store";

export default {
  data() {
    return {
      user: {},
      imageUrl: null,
      isIcon: false,
      isBack: false,
      pageTitle: "",
      actionsType: "",
    };
  },
  /**
   * type
   * search
   * info
   * celendar
   * user
   */
  props: ["is_icon", "is_back", "title", "type"],
  created() {
    this.user = store.user;
    this.isIcon = this.is_icon;
    this.isBack = this.is_back;
    this.pageTitle = this.title;
    this.actionsType = this.type;

    // ログイン情報チェック
    if (store.isAuth == false) {
      // ログイン情報取得
      onAuthStateChanged(auth, async (user) => {
        if (user == null) {
          // storeリセットしてトップページへ
          removeAll();
          this.$router.push("/");
        } else {
          const docRef = doc(db, "authLink", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            setUser(docUserSnap.data(), docSnap.data().uid);
          }
        }
      });
    }
    //リセット
    this.resetCount();
  },
  mounted() {
    if (this.user.isIconUrl) {
      if (store.userIconUrl == "") {
        this.getUserIcon();
      } else {
        this.imageUrl = store.userIconUrl;
      }
    }
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    /**
     * 1日1回だけ実行させる場所
     */
    async resetCount() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (store.lastLoginDate == undefined) {
        setLastLognAt(yesterday);
      }

      const loginUserRef = doc(db, "users", this.user.uid);

      const utcDate = new Date(store.lastLoginDate);
      if (utcDate > yesterday) {
        return;
      } else {
        console.log("reset");
        const docUserSnap = await getDoc(loginUserRef);
        this.user = docUserSnap.data();
        setUser(this.user, this.user.uid);

        setLastLognAt(today);

        /// いいね回数チェック
        const threeWeeksInMilliseconds = 21 * 24 * 60 * 60 * 1000; // 21日をミリ秒に変換

        // 自分がいいねした場合
        const queryLiked = query(
          collection(db, `users/${this.user.uid}/likedUsers`),
        );
        const queryLikedSnapshot = await getDocs(queryLiked);

        const liked = queryLikedSnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        for (var i in liked) {
          const timestampDate = new Date(liked[i].data.likeAt.seconds * 1000);

          if (
            now.getTime() - timestampDate.getTime() >
            threeWeeksInMilliseconds
          ) {
            // 自分
            const docRef1 = doc(
              db,
              "users",
              this.user.uid,
              "likedUsers",
              liked[i].data.uid,
            );
            await deleteDoc(docRef1);
            await updateDoc(loginUserRef, {
              likedUID: arrayRemove(liked[i].data.uid),
            });
            // 相手
            const docRef2 = doc(
              db,
              "users",
              liked[i].data.uid,
              "likeUsers",
              this.user.uid,
            );
            await deleteDoc(docRef2);

            const likedUSerRef = doc(db, "users", liked[i].data.uid);
            await updateDoc(likedUSerRef, {
              likeUID: arrayRemove(this.user.uid),
            });
          }
        }

        // 自分がいいねされた場合
        const queryLike = query(
          collection(db, `users/${this.user.uid}/likeUsers`),
        );
        const queryLikeSnapshot = await getDocs(queryLike);

        const like = queryLikeSnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        for (var n in like) {
          const timestampDate = new Date(like[n].data.likeAt.seconds * 1000);

          if (
            now.getTime() - timestampDate.getTime() >
            threeWeeksInMilliseconds
          ) {
            // 自分
            const docRef3 = doc(
              db,
              "users",
              this.user.uid,
              "likeUsers",
              like[n].data.uid,
            );
            await deleteDoc(docRef3);
            await updateDoc(loginUserRef, {
              likeUID: arrayRemove(like[n].data.uid),
            });
            // 相手
            const docRef4 = doc(
              db,
              "users",
              like[n].data.uid,
              "likedUsers",
              this.user.uid,
            );
            await deleteDoc(docRef4);

            const likeUSerRef = doc(db, "users", like[n].data.uid);
            await updateDoc(likeUSerRef, {
              likedUID: arrayRemove(this.user.uid),
            });
          }
        }
        /**
         * いいね回数回復、マッチング回数回復、レコメンド回復
         * いいね回数は女性もしくは課金なら10回でフリーは3回
         * マッチングは1回か無制限なのでbool
         * レコメンドは女性用だけど一緒くたに更新
         */
        await updateDoc(loginUserRef, {
          //いいね回数回復
          lastGoodAt: new Date(),
          maxGoodCount:
            this.user.isSubscription || this.user.sex == "女性" ? 10 : 3,
          //マッチング回数回復
          lastReplyGoodAt: new Date(),
          isReplyGood: true,
          //レコメンド
          lastRecommendAt: now,
          recommendCount: 5,
        });
      }

      const lastLoginAt = new Date();
      this.user.loginAt = lastLoginAt;
      setUser(this.user, this.user.uid);
      await updateDoc(loginUserRef, {
        loginAt: lastLoginAt,
      });
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${store.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
        setUserIconUrl(url);
      } catch (error) {
        console.log("---------- components/headerView ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    handleBeforeUnload(event) {
      // eslint-disable-next-line no-unused-vars
      event = null;
      removeUserIconUrl();
    },
    headerActionClick(t) {
      this.$emit("header-action-click", t);
    },
  },
};
</script>

<style scoped>
img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.icon {
  color: #fff;
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #fff;
  font-size: 30px;
}

.header-back {
  position: fixed;
  top: 0;
  width: 100%;
  height: 55px;
  background: -moz-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: -webkit-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background-color: #784ba0;
  z-index: 999;
}
</style>
