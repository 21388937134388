<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  あなたのフェチズムを教えてください<br />
                  1:全く当てはまらない<br />
                  2:当てはまらない<br />
                  3:あまり当てはまらない<br />
                  4:やや当てはまる<br />
                  5:当てはまる<br />
                  6:とても当てはまる
                </div>
              </div>
              <div class="item-box">
                <hr />
                <div v-for="(item, i) in this.questionList" :key="i">
                  <div class="item-title text-center">{{ item.label }}</div>
                  <div style="padding: 10px 0">
                    <v-slider
                      v-model="item.value"
                      color="#784ba0"
                      :max="6"
                      :min="1"
                      show-ticks="always"
                      :ticks="tickLabels"
                      :step="1"
                    ></v-slider>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      sadistic: 1,
      tickLabels: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
      questionList: {
        question1: {
          label: "匂い",
          column: "question1",
          value: 1,
        },
        question2: {
          label: "体液・血",
          column: "question2",
          value: 1,
        },
        question3: {
          label: "唇",
          column: "question3",
          value: 1,
        },
        question4: {
          label: "声",
          column: "question4",
          value: 1,
        },
        question5: {
          label: "お尻",
          column: "question5",
          value: 1,
        },
        question6: {
          label: "胸",
          column: "question6",
          value: 1,
        },
        question7: {
          label: "手・指",
          column: "question7",
          value: 1,
        },
        question8: {
          label: "腕",
          column: "question8",
          value: 1,
        },
        question9: {
          label: "太腿",
          column: "question9",
          value: 1,
        },
        question10: {
          label: "鼠蹊部",
          column: "question10",
          value: 1,
        },
        question11: {
          label: "腰",
          column: "question11",
          value: 1,
        },
        question12: {
          label: "耳",
          column: "question12",
          value: 1,
        },
        question13: {
          label: "首",
          column: "question13",
          value: 1,
        },
        question14: {
          label: "筋肉",
          column: "question14",
          value: 1,
        },
        question15: {
          label: "骨格・鎖骨",
          column: "question15",
          value: 1,
        },
        question16: {
          label: "髪の毛",
          column: "question16",
          value: 1,
        },
        question64: {
          label: "黒子（ほくろ）",
          column: "question64",
          value: 1,
        },
        question65: {
          label: "ラバー",
          column: "question65",
          value: 1,
        },
        question66: {
          label: "ウェット&メッシー",
          column: "question66",
          value: 1,
        },
        question67: {
          label: "ケモナー",
          column: "question67",
          value: 1,
        },
        question68: {
          label: "オブジェクトフィリア（対物性愛）",
          column: "question68",
          value: 1,
        },
      },
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    if (this.$route.params.type == "mod") {
      this.questionList.question1.value1 =
        this.user.question1 == undefined ? 0 : this.user.question1;
      this.questionList.question2.value1 =
        this.user.question2 == undefined ? 0 : this.user.question2;
      this.questionList.question3.value1 =
        this.user.question3 == undefined ? 0 : this.user.question3;
      this.questionList.question4.value1 =
        this.user.question4 == undefined ? 0 : this.user.question4;
      this.questionList.question5.value1 =
        this.user.question5 == undefined ? 0 : this.user.question5;
      this.questionList.question6.value1 =
        this.user.question6 == undefined ? 0 : this.user.question6;
      this.questionList.question7.value1 =
        this.user.question7 == undefined ? 0 : this.user.question7;
      this.questionList.question8.value1 =
        this.user.question8 == undefined ? 0 : this.user.question8;
      this.questionList.question9.value1 =
        this.user.question9 == undefined ? 0 : this.user.question9;
      this.questionList.question10.value1 =
        this.user.question10 == undefined ? 0 : this.user.question10;
      this.questionList.question11.value1 =
        this.user.question11 == undefined ? 0 : this.user.question11;
      this.questionList.question12.value1 =
        this.user.question12 == undefined ? 0 : this.user.question12;
      this.questionList.question13.value1 =
        this.user.question13 == undefined ? 0 : this.user.question13;
      this.questionList.question14.value1 =
        this.user.question14 == undefined ? 0 : this.user.question14;
      this.questionList.question15.value1 =
        this.user.question15 == undefined ? 0 : this.user.question15;
      this.questionList.question16.value1 =
        this.user.question16 == undefined ? 0 : this.user.question16;
      this.questionList.question64.value1 =
        this.user.question64 == undefined ? 0 : this.user.question64;
      this.questionList.question65.value1 =
        this.user.question65 == undefined ? 0 : this.user.question65;
      this.questionList.question66.value1 =
        this.user.question66 == undefined ? 0 : this.user.question66;
      this.questionList.question67.value1 =
        this.user.question67 == undefined ? 0 : this.user.question67;
      this.questionList.question68.value1 =
        this.user.question68 == undefined ? 0 : this.user.question68;

      this.questionList.question1.value = this.user.question1;
      this.questionList.question2.value = this.user.question2;
      this.questionList.question3.value = this.user.question3;
      this.questionList.question4.value = this.user.question4;
      this.questionList.question5.value = this.user.question5;
      this.questionList.question6.value = this.user.question6;
      this.questionList.question7.value = this.user.question7;
      this.questionList.question8.value = this.user.question8;
      this.questionList.question9.value = this.user.question9;
      this.questionList.question10.value = this.user.question10;
      this.questionList.question11.value = this.user.question11;
      this.questionList.question12.value = this.user.question12;
      this.questionList.question13.value = this.user.question13;
      this.questionList.question14.value = this.user.question14;
      this.questionList.question15.value = this.user.question15;
      this.questionList.question16.value = this.user.question16;
      this.questionList.question64.value = this.user.question64;
      this.questionList.question65.value = this.user.question65;
      this.questionList.question66.value = this.user.question66;
      this.questionList.question67.value = this.user.question67;
      this.questionList.question68.value = this.user.question68;
    }
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          question1: this.questionList.question1.value,
          question2: this.questionList.question2.value,
          question3: this.questionList.question3.value,
          question4: this.questionList.question4.value,
          question5: this.questionList.question5.value,
          question6: this.questionList.question6.value,
          question7: this.questionList.question7.value,
          question8: this.questionList.question8.value,
          question9: this.questionList.question9.value,
          question10: this.questionList.question10.value,
          question11: this.questionList.question11.value,
          question12: this.questionList.question12.value,
          question13: this.questionList.question13.value,
          question14: this.questionList.question14.value,
          question15: this.questionList.question15.value,
          question16: this.questionList.question16.value,
          question64: this.questionList.question64.value,
          question65: this.questionList.question65.value,
          question66: this.questionList.question66.value,
          question67: this.questionList.question67.value,
          question68: this.questionList.question68.value,
        });
        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          question1: this.questionList.question1.value,
          question2: this.questionList.question2.value,
          question3: this.questionList.question3.value,
          question4: this.questionList.question4.value,
          question5: this.questionList.question5.value,
          question6: this.questionList.question6.value,
          question7: this.questionList.question7.value,
          question8: this.questionList.question8.value,
          question9: this.questionList.question9.value,
          question10: this.questionList.question10.value,
          question11: this.questionList.question11.value,
          question12: this.questionList.question12.value,
          question13: this.questionList.question13.value,
          question14: this.questionList.question14.value,
          question15: this.questionList.question15.value,
          question16: this.questionList.question16.value,
          question64: this.questionList.question64.value,
          question65: this.questionList.question65.value,
          question66: this.questionList.question66.value,
          question67: this.questionList.question67.value,
          question68: this.questionList.question68.value,
        });
        this.user.question1 = this.questionList.question1.value;
        this.user.question2 = this.questionList.question2.value;
        this.user.question3 = this.questionList.question3.value;
        this.user.question4 = this.questionList.question4.value;
        this.user.question5 = this.questionList.question5.value;
        this.user.question6 = this.questionList.question6.value;
        this.user.question7 = this.questionList.question7.value;
        this.user.question8 = this.questionList.question8.value;
        this.user.question9 = this.questionList.question9.value;
        this.user.question10 = this.questionList.question10.value;
        this.user.question11 = this.questionList.question11.value;
        this.user.question12 = this.questionList.question12.value;
        this.user.question13 = this.questionList.question13.value;
        this.user.question14 = this.questionList.question14.value;
        this.user.question15 = this.questionList.question15.value;
        this.user.question16 = this.questionList.question16.value;
        this.user.question64 = this.questionList.question64.value;
        this.user.question65 = this.questionList.question65.value;
        this.user.question66 = this.questionList.question66.value;
        this.user.question67 = this.questionList.question67.value;
        this.user.question68 = this.questionList.question68.value;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/bdsm/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
