<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  あなたのBDSMを教えてください<br />
                  1:全く当てはまらない<br />
                  2:当てはまらない<br />
                  3:あまり当てはまらない<br />
                  4:やや当てはまる<br />
                  5:当てはまる<br />
                  6:とても当てはまる
                </div>
              </div>
              <div class="item-box">
                <hr />
                <div v-for="(item, i) in this.questionList" :key="i">
                  <div class="item-title text-center">{{ item.label }}</div>
                  <div style="padding: 10px 0">
                    <v-slider
                      v-model="item.value"
                      color="#784ba0"
                      :max="6"
                      :min="1"
                      show-ticks="always"
                      :ticks="tickLabels"
                      :step="1"
                    ></v-slider>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      sadistic: 1,
      tickLabels: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
      questionList: {
        question17: {
          label: "サディスト",
          column: "question17",
          value: 1,
        },
        question18: {
          label: "マゾヒスト",
          column: "question18",
          value: 1,
        },
        question19: {
          label: "ディシプリン",
          column: "question19",
          value: 1,
        },
        question20: {
          label: "主（ダディ・マミー）",
          column: "question20",
          value: 1,
        },
        question21: {
          label: "従（リトル・全てを委ねたい人）",
          column: "question21",
          value: 1,
        },
        question22: {
          label: "リガー（縛り手・拘束する人）",
          column: "question22",
          value: 1,
        },
        question23: {
          label: "ロープバニー（受け手）",
          column: "question23",
          value: 1,
        },
        question24: {
          label: "ドミナント",
          column: "question24",
          value: 1,
        },
        question25: {
          label: "サブミッシブ",
          column: "question25",
          value: 1,
        },
        question26: {
          label: "ブラットテイマー",
          column: "question26",
          value: 1,
        },
        question27: {
          label: "ブラット",
          column: "question27",
          value: 1,
        },
        question28: {
          label: "プライマルハンター（狩人）",
          column: "question28",
          value: 1,
        },
        question29: {
          label: "プライマルプリ（本能的なプレイを好む獲物側）",
          column: "question29",
          value: 1,
        },
        question30: {
          label: "デグレーダ（人間扱いをしない・モノ扱いする人）",
          column: "question30",
          value: 1,
        },
        question31: {
          label: "デグレード（人間家具やモノ扱いされる人）",
          column: "question31",
          value: 1,
        },
        question32: {
          label: "マスター",
          column: "question32",
          value: 1,
        },
        question33: {
          label: "スレーブ（奴隷）",
          column: "question33",
          value: 1,
        },
        question34: {
          label: "オーナー",
          column: "question34",
          value: 1,
        },
        question35: {
          label: "ペット",
          column: "question35",
          value: 1,
        },
      },
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    if (this.$route.params.type == "mod") {
      this.questionList.question17.value =
        this.user.question17 == undefined ? 0 : this.user.question17;
      this.questionList.question18.value =
        this.user.question18 == undefined ? 0 : this.user.question18;
      this.questionList.question19.value =
        this.user.question19 == undefined ? 0 : this.user.question19;
      this.questionList.question20.value =
        this.user.question20 == undefined ? 0 : this.user.question20;
      this.questionList.question21.value =
        this.user.question21 == undefined ? 0 : this.user.question21;
      this.questionList.question22.value =
        this.user.question22 == undefined ? 0 : this.user.question22;
      this.questionList.question23.value =
        this.user.question23 == undefined ? 0 : this.user.question23;
      this.questionList.question24.value =
        this.user.question24 == undefined ? 0 : this.user.question24;
      this.questionList.question25.value =
        this.user.question25 == undefined ? 0 : this.user.question25;
      this.questionList.question26.value =
        this.user.question26 == undefined ? 0 : this.user.question26;
      this.questionList.question27.value =
        this.user.question27 == undefined ? 0 : this.user.question27;
      this.questionList.question28.value =
        this.user.question28 == undefined ? 0 : this.user.question28;
      this.questionList.question29.value =
        this.user.question29 == undefined ? 0 : this.user.question29;
      this.questionList.question30.value =
        this.user.question30 == undefined ? 0 : this.user.question30;
      this.questionList.question31.value =
        this.user.question31 == undefined ? 0 : this.user.question31;
      this.questionList.question32.value =
        this.user.question32 == undefined ? 0 : this.user.question32;
      this.questionList.question33.value =
        this.user.question33 == undefined ? 0 : this.user.question33;
      this.questionList.question34.value =
        this.user.question34 == undefined ? 0 : this.user.question34;
      this.questionList.question35.value =
        this.user.question35 == undefined ? 0 : this.user.question35;
    }
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          question17: this.questionList.question17.value,
          question18: this.questionList.question18.value,
          question19: this.questionList.question19.value,
          question20: this.questionList.question20.value,
          question21: this.questionList.question21.value,
          question22: this.questionList.question22.value,
          question23: this.questionList.question23.value,
          question24: this.questionList.question24.value,
          question25: this.questionList.question25.value,
          question26: this.questionList.question26.value,
          question27: this.questionList.question27.value,
          question28: this.questionList.question28.value,
          question29: this.questionList.question29.value,
          question30: this.questionList.question30.value,
          question31: this.questionList.question31.value,
          question32: this.questionList.question32.value,
          question33: this.questionList.question33.value,
          question34: this.questionList.question34.value,
          question35: this.questionList.question35.value,
        });
        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          question17: this.questionList.question17.value,
          question18: this.questionList.question18.value,
          question19: this.questionList.question19.value,
          question20: this.questionList.question20.value,
          question21: this.questionList.question21.value,
          question22: this.questionList.question22.value,
          question23: this.questionList.question23.value,
          question24: this.questionList.question24.value,
          question25: this.questionList.question25.value,
          question26: this.questionList.question26.value,
          question27: this.questionList.question27.value,
          question28: this.questionList.question28.value,
          question29: this.questionList.question29.value,
          question30: this.questionList.question30.value,
          question31: this.questionList.question31.value,
          question32: this.questionList.question32.value,
          question33: this.questionList.question33.value,
          question34: this.questionList.question34.value,
          question35: this.questionList.question35.value,
        });
        this.user.question17 = this.questionList.question17.value;
        this.user.question18 = this.questionList.question18.value;
        this.user.question19 = this.questionList.question19.value;
        this.user.question20 = this.questionList.question20.value;
        this.user.question21 = this.questionList.question21.value;
        this.user.question22 = this.questionList.question22.value;
        this.user.question23 = this.questionList.question23.value;
        this.user.question24 = this.questionList.question24.value;
        this.user.question25 = this.questionList.question25.value;
        this.user.question26 = this.questionList.question26.value;
        this.user.question27 = this.questionList.question27.value;
        this.user.question28 = this.questionList.question28.value;
        this.user.question29 = this.questionList.question29.value;
        this.user.question30 = this.questionList.question30.value;
        this.user.question31 = this.questionList.question31.value;
        this.user.question32 = this.questionList.question32.value;
        this.user.question33 = this.questionList.question33.value;
        this.user.question34 = this.questionList.question34.value;
        this.user.question35 = this.questionList.question35.value;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/seiheki/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
