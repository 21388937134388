<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div class="item-box">
                <div class="item-title">一言コメント</div>
                <div class="item-description">
                  みんなにアピールしましょう！（30文字まで）
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="一言コメント"
                    :style="CommentText.length > 30 ? 'border-color: red' : ''"
                    v-model="CommentText"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ CommentText.length }}/30
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">自己紹介</div>
                <div class="item-description">
                  あなたの魅力をたっぷり伝えましょう
                </div>
                <div style="padding-top: 10px">
                  <textarea
                    class="form-control"
                    rows="8"
                    v-model="ProfileText"
                    :style="ProfileText.length > 500 ? 'border-color: red' : ''"
                  ></textarea>
                  <div class="text-end" style="font-size: 10px">
                    {{ ProfileText.length }}/500
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">好みについて</div>
                <div class="item-description">
                  好きなプレイや興味があることをおしえてください
                </div>
                <div style="padding-top: 10px">
                  <textarea
                    class="form-control"
                    rows="8"
                    v-model="LikeText"
                    :style="LikeText.length > 500 ? 'border-color: red' : ''"
                  ></textarea>
                  <div class="text-end" style="font-size: 10px">
                    {{ LikeText.length }}/500
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">NGについて</div>
                <div class="item-description">
                  あなたのNGラインを伝えましょう
                </div>
                <div style="padding-top: 10px">
                  <textarea
                    class="form-control"
                    rows="8"
                    v-model="NGText"
                    :style="NGText.length > 500 ? 'border-color: red' : ''"
                  ></textarea>
                  <div class="text-end" style="font-size: 10px">
                    {{ NGText.length }}/500
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      ProfileText: "",
      CommentText: "",
      LikeText: "",
      NGText: "",
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    if (this.$route.params.type == "mod") {
      this.CommentText =
        this.user.comment == undefined ? "" : this.user.comment;
      this.ProfileText =
        this.user.profile == undefined ? "" : this.user.profile;
      this.LikeText = this.user.like == undefined ? "" : this.user.like;
      this.NGText = this.user.ng == undefined ? "" : this.user.ng;
    }
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          comment: this.CommentText,
          profile: this.ProfileText,
          like: this.LikeText,
          ng: this.NGText,
        });

        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          comment: this.CommentText,
          profile: this.ProfileText,
          like: this.LikeText,
          ng: this.NGText,
        });

        this.user.comment = this.CommentText;
        this.user.profile = this.ProfileText;
        this.user.like = this.LikeText;
        this.user.ng = this.NGText;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/kaiwai/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
