<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="'退会ページ'"
    type="none"
  />
  <div class="withdrawal-page">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 16px;
                    font-weight: 700;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  退会をする場合、アカウントが削除され今までのマッチングやメッセージのデータが削除されます<br />
                  ご確認の上、退会するようお願いいたします。
                </div>
              </div>
              <br />
              <div class="item-box">
                <div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 7px"
                      v-bind:checked="isUserRemove"
                      v-on:change="isUserRemove = !isUserRemove"
                    />
                    <label class="form-check-label" style="color: #784ba0">
                      データが削除されることを許可します
                    </label>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8" style="height: 65px; background-color: #fff">
            <button
              v-if="isUserRemove"
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="remove()"
            >
              退会する
            </button>
            <button
              v-else
              type="button"
              style="
                width: 100%;
                height: 40px;
                background-color: #bbb;
                border-color: #bbb;
              "
              class="btn btn-kinkyer m-plus-medium"
            >
              退会する
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { store, removeAll } from "../../plugins/store";
import { db, auth } from "../../plugins/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { onAuthStateChanged, deleteUser } from "firebase/auth";

export default {
  data() {
    return {
      user: {},
      login: null,
      isEdit: false,
      isUserRemove: false,
    };
  },
  created() {
    this.user = store.user;
  },
  mounted() {
    // Firebase Authentication の状態を監視
    onAuthStateChanged(auth, (currentUser) => {
      this.login = currentUser;
    });
  },
  components: {
    AppHeader,
  },
  methods: {
    async remove() {
      /**
       * サブコレクションを削除
       * like
       * liked
       * matching
       */
      this.user.likeUID.forEach(async (element) => {
        const itemLikeDoc = doc(
          db,
          `users/${element}/likedUsers`,
          this.user.uid,
        );
        await deleteDoc(itemLikeDoc);
      });
      this.user.likedUID.forEach(async (element) => {
        const itemLikedDoc = doc(
          db,
          `users/${element}/likeUsers`,
          this.user.uid,
        );
        await deleteDoc(itemLikedDoc);
      });
      this.user.matchingUID.forEach(async (element) => {
        const itemMatchingDoc = doc(
          db,
          `users/${element}/matchingUsers`,
          this.user.uid,
        );
        await deleteDoc(itemMatchingDoc);
      });
      const itemUserDoc = doc(db, "users", this.user.uid);
      await deleteDoc(itemUserDoc);

      await deleteUser(this.login);
      removeAll();
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
.withdrawal-page {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
