<template>
  <div class="subtitle-box">
    <div class="subtitle">界隈</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/kaiwai/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div style="padding: 20px 15px 0 15px">
    <div style="overflow: hidden; display: flex; flex-wrap: wrap">
      <div
        style="
          padding: 0px 13px 5px 13px;
          background-color: #784ba0;
          color: #fff;
          margin-right: 5px;
          margin-bottom: 3px;
          white-space: nowrap;
          border-radius: 20px;
        "
        v-for="(tag, i) in user.selectedKaiwais"
        :key="i"
      >
        <span style="font-size: 14px; font-weight: 500">#{{ tag }}界隈</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      isMod: false,
    };
  },
  props: ["data", "is_mod"],
  created() {
    this.user = this.data;
    this.user.selectedKaiwais == undefined ? [] : this.user.selectedKaiwais;
    this.isMod = this.is_mod;
  },
};
</script>

<style scoped>
.line-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3px 20px;
}
.line {
  width: calc(100% / 6);
  height: 20px;
  border: solid 1px #fff;
  background-color: rgb(225, 213, 236);
}
.line-start {
  border-radius: 4px 0 0 4px;
}
.line-end {
  border-radius: 0 4px 4px 0;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  margin-top: 30px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
a {
  color: #555 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #555 !important;
  text-decoration: underline !important;
}
</style>
