<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div class="item-box">
                <div class="item-title">
                  名前・ニックネーム
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  なんてお呼びすればよいですか？（20文字まで）
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="名前・ニックネーム"
                    :style="name.length > 20 ? 'border-color: red' : ''"
                    v-model="name"
                  />
                  <div class="text-end" style="font-size: 10px">
                    {{ name.length }}/20
                  </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">
                  性別
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  戸籍上の性別を教えてください<br />
                  ※登録後は変更できないのでご注意ください
                </div>
                <div style="padding-top: 10px">
                  <v-select
                    :options="['男性', '女性']"
                    v-model="sex"
                    placeholder="性別"
                  />
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">
                  居住地
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  現在住んでいる場所、または拠点にしている都道府県を選択してください（最大で５つ選択できます）
                </div>
                <div style="padding-top: 10px">
                  <v-select
                    :options="optionsArea"
                    v-model="selectedArea"
                    :selectable="
                      function () {
                        return selectedArea.length < 5;
                      }
                    "
                    :close-on-select="false"
                    placeholder="居住地"
                    multiple
                  />
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">
                  生年月日
                  <span
                    class="badge bg-danger"
                    style="
                      font-size: 10px;
                      position: relative;
                      font-weight: 700;
                      bottom: 2px;
                    "
                    >必須</span
                  >
                </div>
                <div class="item-description">
                  誕生日を教えてください<br />
                  ※登録後は変更できないのでご注意ください
                </div>
                <div style="padding-top: 10px">
                  <input
                    type="date"
                    class="form-control"
                    placeholder="生年月日"
                    v-model="birthday"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 65px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="(isEdit = true), update()"
          >
            登録をはじめる
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      uid: "",
      user: {},
      sex: null,
      birthday: "2000-01-01",
      btnName: "",
      name: "",
      selectedArea: [],

      /// オプション設定
      optionsArea: [
        "北海道（札幌）",
        "北海道（道東）",
        "北海道（道南）",
        "北海道（道北）",
        "北海道（道央）",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
        "海外",
        "ネットのみ",
      ],
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.user = store.user;
  },
  components: {
    AppHeader,
    vSelect,
  },
  methods: {
    async update() {
      try {
        if (this.name == "" || this.name.length > 20) {
          this.isEdit = false;
          this.$toast.show("名前・ニックネームを入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        } else if (this.sex == null) {
          this.isEdit = false;
          this.$toast.show("性別を選択してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        } else if (this.birthday == "") {
          this.isEdit = false;
          this.$toast.show("生年月日を入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        } else if (this.selectedArea.length == 0) {
          this.isEdit = false;
          this.$toast.show("居住地を入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        }

        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        const birthDate = new Date(this.birthday);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }

        await updateDoc(docUpdateUsersRef, {
          name: this.name,
          birthday: new Date(this.birthday + " 00:00:00"),
          age: age,
          sex: this.sex,
          area: this.selectedArea,
          isIconImage: false,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          name: this.name,
          birthday: new Date(this.birthday + " 00:00:00"),
          age: age,
          sex: this.sex,
          area: this.selectedArea,
          isIconImage: false,
        });

        this.user.name = this.name;
        this.user.area = this.selectedArea;
        this.user.sex = this.sex;
        this.user.age = age;
        this.user.isIconImage = false;

        setUser(this.user, this.user.uid);

        this.$router.push("/user/edit/icon/" + this.$route.params.type);
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-outline {
  border: solid 1px #784ba0;
  color: #784ba0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer-outline:hover {
  border: solid 1px #784ba0;
  background-color: #ffffff28;
  color: #784ba0;
}
.btn-kinkyer-outline:active {
  border: solid 1px #784ba0;
  color: #784ba0;
  background-color: #ffffff43;
}
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  color: #784ba0;
  border: solid 1px #784ba0;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
