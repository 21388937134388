<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="true"
      :is_back="false"
      :title="''"
      type="event"
      @header-action-click="headerActionClick"
    />
    <div
      style="
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
        padding-top: 60px;
        background-color: #fff;
      "
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <div style="padding: 0 5px">
              <FullCalendar ref="calendar" :options="calendarOptions" />
            </div>
            <div
              style="
                font-size: 11px;
                color: #999;
                background-color: #eee;
                padding: 3px 10px;
              "
            >
              イベント一覧
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div v-if="todayEvent.length == 0">
                <div
                  class="text-center"
                  style="padding: 30px 0; font-size: 12px; color: #888"
                >
                  イベントはありません
                </div>
              </div>
              <div v-else>
                <div v-for="(event, i) in todayEvent" :key="i">
                  <div
                    style="
                      border-bottom: solid 1px #ddd;
                      padding: 12px 10px;
                      background-color: #fff;
                    "
                  >
                    <div
                      class="footer-container"
                      style="width: 100%"
                      @click="setDetail(event)"
                    >
                      <div class="text-start" style="width: 100%; padding: 8px">
                        {{ event.title }}
                        <div
                          class="text-start"
                          style="font-size: 12px; padding-top: 5px"
                        >
                          <i
                            class="bi bi-calendar"
                            style="margin-right: 2px"
                          ></i
                          >{{ event.eventAtView }} by
                          {{ event.userName }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 30px;
                          display: flex;
                          align-items: center;
                          padding: 5px 0;
                          color: #777;
                        "
                      >
                        <span
                          class="material-symbols-rounded"
                          style="font-size: 30px"
                        >
                          chevron_right
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-center"
      style="
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-bottom: 90px;
        z-index: 999;
      "
    >
      <div
        class="footer-container"
        style="
          border: solid 1px #ccc;
          background-color: #fff;
          width: 240px;
          margin: auto;
          border-radius: 20px;
        "
      >
        <div
          style="
            width: 50px;
            display: flex;
            align-items: flex-start;
            padding: 5px 5px;
            color: #777;
          "
        >
          <span
            class="material-symbols-rounded"
            style="font-size: 30px; cursor: pointer"
            @click="prevCalendar"
          >
            arrow_circle_left
          </span>
        </div>
        <div
          class="text-center"
          style="width: 100%; padding-top: 8px"
          @click="todayCalendar"
        >
          {{ y }}年{{ m }}月{{ d }}日
        </div>
        <div
          style="
            width: 50px;
            display: flex;
            align-items: flex-end;
            padding: 5px 5px;
            color: #777;
          "
        >
          <span
            class="material-symbols-rounded"
            style="font-size: 30px; cursor: pointer"
            @click="nextCalendar"
          >
            arrow_circle_right
          </span>
        </div>
      </div>
    </div>
    <AppFooter :index="3" />
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { db } from "../../plugins/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { setEvent } from "../../plugins/store";

export default {
  data() {
    return {
      uid: "",
      y: "",
      m: "",
      d: "",
      items: [],
      todayEvent: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        events: [],
        contentHeight: "auto",
        headerToolbar: false,
        views: {
          dayGridFourWeek: {
            type: "dayGrid",
            duration: { weeks: 4 },
          },
        },
        /* dayCellDidMount: (args) => {
          var year = args.date.getFullYear();
          var month = args.date.getMonth() + 1;
          var day = args.date.getDate();

          const days = `${year}-${month}-${day}`;
          const selectDays = `${this.y}-${this.m}-${this.d}`;
          console.log(selectDays);

          const dayNumberElement = args.el;
          if (days == selectDays) {
            console.log(dayNumberElement);
            dayNumberElement.style.backgroundColor = "lightblue";
          } else {
            dayNumberElement.style.backgroundColor = "#fff";
          }

          /* console.log(args.date.getDate());
          // 日付セルの内容をカスタマイズ
          const dayNumberElement = args.el.querySelector(
            ".fc-daygrid-day-number",
          );
          dayNumberElement.textContent = args.date.getDate();
          console.log(dayNumberElement.textContent);
          if (dayNumberElement) {
            //dayNumberElement.textContent = args.date.getDate(); // 日付をシンプルに表示
          }
        }, */
      },
    };
  },
  mounted() {
    let calendarApi = this.$refs.calendar.getApi();
    const currentDate = calendarApi.getDate();
    this.y = currentDate.getFullYear();
    this.m = currentDate.getMonth() + 1;
    this.d = currentDate.getDate();

    this.fetchEvent();
  },
  components: {
    AppHeader,
    AppFooter,
    FullCalendar,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async fetchEvent() {
      var from = new Date(`${this.y}-${this.m}-01`);

      var lastDay = new Date(this.y, this.m, 0).getDate();
      var to = new Date(`${this.y}-${this.m}-${lastDay}`);

      try {
        const q = query(
          collection(db, "events"),
          where("eventAt", ">=", from),
          where("eventAt", "<=", to),
        );

        const querySnapshot = await getDocs(q);

        this.items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        var tmp = [];
        this.items.forEach((item) => {
          var day = item.data.eventAtView
            .replace("年", "-")
            .replace("月", "-")
            .replace("日", "")
            .replace(" 00:00", "");
          tmp.push(day);
        });
        const uniqueArray = [...new Set(tmp)];

        this.calendarOptions.events = [];
        uniqueArray.forEach((element) => {
          this.calendarOptions.events.push({
            title: "",
            date: element,
          });
        });

        this.fetchTodayEvent();
      } catch (error) {
        console.error("データの取得に失敗しました:", error);
      } finally {
        this.isCreated = true;
      }
    },
    fetchTodayEvent() {
      this.todayEvent = [];
      //const today = `${this.y}-${this.m}-${this.d}`;
      const todayj1 = `${this.y}年${String(this.m).padStart(2, "0")}月${String(this.d).padStart(2, "0")}日 00:00`;
      const todayj2 = `${this.y}年${String(this.m).padStart(2, "0")}月${String(this.d).padStart(2, "0")}日`;
      this.items.forEach((item) => {
        console.log(item.data.eventAtView);
        if (item.data.eventAtView == todayj1) {
          this.todayEvent.push(item.data);
        } else if (item.data.eventAtView == todayj2) {
          this.todayEvent.push(item.data);
        }
      });
    },
    setDetail(event) {
      setEvent(event);
      this.$router.push({
        name: "event-detail",
      });
    },
    headerActionClick(data) {
      if (data == "event") {
        this.$router.push({
          name: "event-bookmark",
        });
      } else if (data == "event_today") {
        this.todayCalendar();
      }
    },
    handleDateClick: function (arg) {
      this.y = arg.date.getFullYear();
      this.m = arg.date.getMonth() + 1;
      this.d = arg.date.getDate();
      this.fetchTodayEvent();
    },
    nextCalendar() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.next();
      const currentDate = calendarApi.getDate();
      this.y = currentDate.getFullYear();
      this.m = currentDate.getMonth() + 1;
      this.d = currentDate.getDate();
      this.fetchEvent();
    },
    prevCalendar() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.prev();
      const currentDate = calendarApi.getDate();
      this.y = currentDate.getFullYear();
      this.m = currentDate.getMonth() + 1;
      this.d = currentDate.getDate();
      this.fetchEvent();
    },
    todayCalendar() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.today();
      const currentDate = calendarApi.getDate();
      this.y = currentDate.getFullYear();
      this.m = currentDate.getMonth() + 1;
      this.d = currentDate.getDate();
      this.fetchEvent();
    },
  },
};
</script>

<style scoped>
.event {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 300px;
  padding-bottom: 140px;
}
.footer-container {
  display: flex;
}
</style>
