<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="''" type="info" />
    <div class="mypage">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                ></div>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    style="padding: 8px 15px !important"
                  >
                    <div>
                      <div class="list-icon">
                        <div v-if="imageUrl">
                          <img
                            style="
                              width: 70px;
                              height: 70px;
                              object-fit: cover;
                              border-radius: 50%;
                            "
                            :src="imageUrl"
                          />
                        </div>
                        <div v-else>
                          <img
                            style="
                              width: 70px;
                              height: 70px;
                              object-fit: cover;
                              border-radius: 50%;
                            "
                            :src="require('@/assets/icon_user.webp')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="list-end">
                      <div class="text-end">
                        <div
                          style="
                            font-size: 25px;
                            font-weight: 500;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-text-overflow: ellipsis;
                            -o-text-overflow: ellipsis;
                            width: 200px;
                          "
                        >
                          {{ userInfo.name }}
                        </div>
                        <div style="font-size: 14px; font-weight: 500">
                          {{ userInfo.age }}歳/{{ userInfo.sex }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div style="padding: 10px">
                <div
                  v-if="!userInfo.isIdentification"
                  class="alert alert-primary text-center"
                  style="font-size: 14px"
                  role="alert"
                  @click="linkPage('/mypage/setting/identification/mod')"
                >
                  ご利用には本人確認が必要です
                  <i class="bi bi-box-arrow-up-right"></i>
                </div>
                <div
                  v-if="
                    !userInfo.isIdentification && userInfo.isIdentificationSend
                  "
                  class="alert alert-info text-center"
                  style="font-size: 14px"
                  role="alert"
                >
                  現在本人確認中です
                </div>
                <div
                  v-if="userInfo.isReIdentification"
                  class="alert alert-danger text-center"
                  style="font-size: 14px"
                  role="alert"
                >
                  再提出をお願いします
                  <i class="bi bi-box-arrow-up-right"></i>
                </div>
              </div>
              <div
                style="padding: 10px"
                v-if="!user.isSubscription && user.sex == '男性'"
              >
                <button
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="linkPage('/mypage/subscription')"
                >
                  <div class="cherry-bomb-one-regular" style="font-size: 28px">
                    Kinkyer+
                  </div>
                  <div
                    class="m-plus-regular"
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      padding-bottom: 6px;
                    "
                  >
                    マッチング率がアップ！<br />
                    まずはプラン内容を確認してみてください
                  </div>
                </button>
              </div>
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  ユーザー情報
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" style="cursor: pointer">
                    <div
                      @click="
                        (isLoad = true),
                          this.$router.push({
                            name: 'user-preview',
                          })
                      "
                    >
                      <div>
                        <div class="list-top">
                          <span class="material-symbols-rounded list-top-icon">
                            account_circle
                          </span>
                        </div>
                      </div>
                      <div
                        style="float: left; padding-top: 13px; font-size: 14px"
                      >
                        プロフィール編集
                      </div>
                      <div class="list-end">
                        <span class="material-symbols-rounded list-end-icon">
                          arrow_forward_ios
                        </span>
                      </div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="myBottomShareSheet = true"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          share
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      プロフィールを共有する
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    @click="setQRcode"
                    class="list-group-item"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          qr_code
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      QRコード
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    @click="linkPage('/bookmark')"
                    class="list-group-item"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          star
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      お気に入り一覧
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <!-- <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="linkPage('/mypage/week/setting')"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          tune
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      おでかけ設定
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li> -->
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="linkPage('/mypage/event/list')"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          calendar_month
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      イベント管理
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <!-- <li class="list-group-item" style="cursor: pointer">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          notifications_unread
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      通知について
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li> -->
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          visibility_off
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      年齢の表示
                    </div>
                    <div class="list-toggle">
                      <Toggle v-model="isAgeView" @change="chengeAgeView" />
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          hide_image
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      センシティブ画像の表示
                    </div>
                    <div class="list-toggle">
                      <Toggle v-model="isImageView" @change="chengeImageView" />
                    </div>
                  </li>
                  <!--  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          volunteer_activism
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      マッチング募集する
                    </div>
                    <div class="list-toggle">
                      <Toggle
                        v-model="isLikeBotton"
                        @change="chengeLikeBotton"
                      />
                    </div>
                  </li> -->
                </ul>
              </div>
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  投稿
                </div>
                <ul class="list-group list-group-flush">
                  <li
                    @click="linkPage('/follow/list')"
                    class="list-group-item"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          person_add
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      フォローリスト
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    @click="linkPage('/follower/list')"
                    class="list-group-item"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span
                          class="material-symbols-rounded list-top-icon"
                          style="transform: scaleX(-1)"
                        >
                          person_add
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      フォロワーリスト
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    @click="linkPage('/group/note/list')"
                    class="list-group-item"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          groups
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      グループ設定
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="myBottomNoteheet = true"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          co_present
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      プロフィールを投稿
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  認証情報
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" style="cursor: pointer">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          mail
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      メール認証
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">連携解除</div>
                    </div>
                  </li>
                  <li class="list-group-item" style="cursor: pointer">
                    <div>
                      <div
                        class="list-top"
                        style="
                          padding-top: 5px;
                          padding-left: 15px;
                          padding-right: 13px;
                          padding-bottom: 14px;
                        "
                      >
                        <img
                          :src="xicon"
                          alt="SVG Icon"
                          width="18"
                          height="18"
                        />
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 10px; font-size: 14px"
                    >
                      X（Twitter）認証
                    </div>
                    <div class="list-end" style="padding-top: 10px">
                      <div style="font-size: 14px">アカウントへ連携</div>
                    </div>
                  </li>
                </ul>
              </div> -->
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  ステータス
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          thumb_up
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      いいね、アピールいいね
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">
                        {{ userInfo.maxGoodCount }}回
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          mark_chat_unread
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      マッチング前メッセージ
                    </div>
                    <div class="list-end">
                      <div
                        style="font-size: 14px"
                        v-if="userInfo.isSubscription || user.sex == '女性'"
                      >
                        10回迄
                      </div>
                      <div style="font-size: 14px" v-else>3回迄</div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          favorite
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      マッチングいいね
                    </div>
                    <div class="list-end">
                      <div
                        style="font-size: 14px"
                        v-if="userInfo.isSubscription || user.sex == '女性'"
                      >
                        無制限
                      </div>
                      <div v-else>
                        <div
                          style="font-size: 14px"
                          v-if="
                            !userInfo.isSubscription && userInfo.isReplyGood
                          "
                        >
                          1回
                        </div>
                        <div style="font-size: 14px" v-else>0回</div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          local_cafe
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      おでかけマッチング
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px; color: #888">
                        <span
                          class="material-symbols-rounded"
                          v-if="userInfo.isSubscription || user.sex == '女性'"
                        >
                          check
                        </span>
                        <span class="material-symbols-rounded" v-else>
                          lock
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          mark_chat_unread
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      メッセージ
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px; color: #888">
                        <span
                          class="material-symbols-rounded"
                          v-if="userInfo.isSubscription || user.sex == '女性'"
                        >
                          check
                        </span>
                        <span class="material-symbols-rounded" v-else>
                          lock
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          forum
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      グループチャット
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px; color: #888">
                        <span
                          class="material-symbols-rounded"
                          v-if="userInfo.isSubscription || user.sex == '女性'"
                        >
                          check
                        </span>
                        <span class="material-symbols-rounded" v-else>
                          lock
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item" v-if="userInfo.sex == '女性'">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          editor_choice
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      レコメンド回数
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">
                        {{ userInfo.recommendCount }}回
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          badge
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      本人確認
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">
                        <div
                          style="font-size: 14px"
                          v-if="userInfo.isIdentification"
                        >
                          提出済み
                        </div>
                        <div style="font-size: 14px" v-else>未提出</div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          workspace_premium
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      会員ステータス
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">
                        <div
                          style="font-size: 14px"
                          v-if="userInfo.isSubscription || user.sex == '女性'"
                        >
                          Kinkyer+<span v-show="userInfo.sex == '女性'"
                            >&nbsp;[女性ユーザー]</span
                          >
                        </div>
                        <div style="font-size: 14px" v-else>Free</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                style="padding: 10px; font-size: 11px"
                v-show="userInfo.sex == '女性'"
              >
                女性ユーザー様は無料でKinkyer＋と同じ特典をご利用できます<br />
                こちらは公的書類上の性別によって判別しております
              </div>
              <div
                class="text-end"
                style="padding: 10px; font-size: 12px"
                v-if="user.isSubscription && user.sex == '男性'"
              >
                プランの解約は<a
                  style="
                    margin: 0 5px;
                    color: #784ba0;
                    font-weight: 600;
                    text-decoration: underline;
                  "
                  @click="linkPage('/mypage/plan/cancel')"
                  >こちら</a
                >から
              </div>
              <div
                style="padding: 10px"
                v-if="!user.isSubscription && user.sex == '男性'"
              >
                <button
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="linkPage('/mypage/subscription')"
                >
                  <div class="cherry-bomb-one-regular" style="font-size: 28px">
                    Kinkyer+
                  </div>
                  <div
                    class="m-plus-regular"
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      padding-bottom: 6px;
                    "
                  >
                    マッチング率がアップ！<br />
                    まずはプラン内容を確認してみてください
                  </div>
                </button>
              </div>
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  ヘルプ
                </div>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="linkPage('/mypage/setting/pwa')"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          smartphone
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      ホーム画面に追加する方法
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="myBottomPasswordSheet = true"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          send
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      パスワード再発行
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="
                      openExternalLink(
                        'https://docs.google.com/forms/d/e/1FAIpQLSeOo1tAvxXrv0kp7b4flSS7e0rEKEA-ERbbbZVGwiLLajut7Q/viewform',
                      )
                    "
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          apartment
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      お問い合わせ
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        open_in_new
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  運営情報
                </div>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    @click="openExternalLink('https://kinkyer.jp/')"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          web_asset
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      運営サイト
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        open_in_new
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="openExternalLink('https://app.kinkyer.jp/terms/')"
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          subject
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      利用規約
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        open_in_new
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="
                      openExternalLink('https://app.kinkyer.jp/privacypolicy/')
                    "
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          policy
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      プライバシーポリシー
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        open_in_new
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="
                      openExternalLink('https://app.kinkyer.jp/transactions/')
                    "
                    style="cursor: pointer"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          business_center
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      特定商法取引法に基づく表示
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        open_in_new
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div
                  style="
                    font-size: 12px;
                    padding: 3px 10px;
                    color: #777;
                    margin-top: 20px;
                  "
                >
                  その他
                </div>
                <ul class="list-group list-group-flush" style="cursor: pointer">
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="linkPage('/')"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          home
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      トップへ戻る
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item" @click="logout">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          logout
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      ログアウト
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    @click="linkPage('/mypage/withdrawal')"
                  >
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          disabled_by_default
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      退会
                    </div>
                    <div class="list-end">
                      <span class="material-symbols-rounded list-end-icon">
                        arrow_forward_ios
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div style="padding: 40px 0">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div>
                      <div class="list-top">
                        <span class="material-symbols-rounded list-top-icon">
                          info
                        </span>
                      </div>
                    </div>
                    <div
                      style="float: left; padding-top: 13px; font-size: 14px"
                    >
                      アプリのバージョン
                    </div>
                    <div class="list-end">
                      <div style="font-size: 14px">v2.1.8</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-bottom-sheet v-model="myBottomPasswordSheet">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="250px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomPasswordSheet = !myBottomPasswordSheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 200px; padding: 0 15px">
                <div>
                  <div style="padding-bottom: 5px">パスワード再発行</div>
                  <div
                    class="text-start"
                    style="font-size: 12px; padding-bottom: 10px"
                  >
                    登録したメールアドレスを入力してください。<br />パスワード再発行URLをお送りします。
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control form-control-reset"
                      aria-describedby="emailHelp"
                      placeholder="メールアドレス"
                      v-model="resetEmail"
                    />
                  </div>
                  <div style="padding-top: 10px">
                    <button
                      type="button"
                      style="width: 100%; border-radius: 4px"
                      @click="resetPassword"
                      class="btn btn-kinkyer-reset m-plus-medium"
                    >
                      パスワード再発行
                    </button>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="myBottomShareSheet">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="330px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomShareSheet = !myBottomShareSheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 300px; padding: 0 15px">
                <div class="text-start">
                  <div style="padding-bottom: 15px; font-weight: 600">
                    プロフィールの共有
                  </div>
                  <div style="display: flex">
                    <div style="width: 90px" class="text-center">
                      <img
                        :src="require('@/assets/images/icons/x_app_ios.webp')"
                        style="width: 80%"
                        @click="shareProfile('twitter')"
                      />
                      <div class="text-center" style="font-size: 10px">
                        Xで共有
                      </div>
                    </div>
                    <div style="width: 90px" class="text-center">
                      <img
                        :src="
                          require('@/assets/images/icons/LINE_APP_iOS.webp')
                        "
                        style="width: 80%"
                        @click="shareProfile('line')"
                      />
                      <div class="text-center" style="font-size: 10px">
                        LINEで共有
                      </div>
                    </div>
                    <div style="width: 90px" class="text-center">
                      <img
                        :src="
                          require('@/assets/images/icons/Facebook_app_ios.webp')
                        "
                        style="width: 80%"
                        @click="shareProfile('facebook')"
                      />
                      <div class="text-center" style="font-size: 10px">
                        Facebookで共有
                      </div>
                    </div>
                    <div style="width: 90px" class="text-center">
                      <img
                        :src="
                          require('@/assets/images/icons/threads_app_ios.webp')
                        "
                        style="width: 80%"
                        @click="shareProfile('threads')"
                      />
                      <div class="text-center" style="font-size: 10px">
                        Threadsで共有
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style="padding: 10px">
                    <button
                      type="button"
                      class="btn btn-light"
                      style="border-color: #ccc; width: 100%"
                      @click="shareProfile('copy')"
                    >
                      <span
                        class="material-symbols-rounded"
                        style="font-size: 20px; position: relative; top: 4px"
                      >
                        content_copy </span
                      >&nbsp;共有URLをコピーする
                    </button>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="myBottomNoteheet">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="330px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomNoteheet = !myBottomNoteheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 300px; padding: 0 15px">
                <div class="text-start">
                  <div style="padding-bottom: 15px; font-weight: 600">
                    プロフィールを投稿
                  </div>
                  <div>
                    <textarea
                      class="form-control"
                      rows="5"
                      v-model="noteProfileText"
                      placeholder="プロフィールを投稿しましょう"
                    ></textarea>
                    <div class="text-end" style="font-size: 10px">
                      {{ noteProfileText.length }}/300
                    </div>
                  </div>
                  <div style="padding: 10px 0">
                    <button
                      type="button"
                      style="width: 100%"
                      class="btn btn-kinkyer m-plus-medium"
                      @click="setNoteProfile"
                    >
                      投稿する
                    </button>
                  </div>
                  <div
                    class="text-center"
                    style="color: red; font-size: 12px"
                    v-if="noteProfileText.length > 300"
                  >
                    投稿は300文字までになります
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="myBottomQRcodeSheet">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="330px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomQRcodeSheet = !myBottomQRcodeSheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 300px; padding: 0 15px">
                <qrcode :value="qrcode" :size="200" />
                <div class="text-center">共有用URLが表示されます</div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <div
      v-show="isLoad"
      style="
        position: fixed;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
        margin: 0 auto;
        padding-top: 200px;
        text-align: center;
        z-index: 999;
      "
    >
      <div style="position: absolute; z-index: 9999; width: 100%">
        <VueSpinnerOval size="50" color="#fff" />
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { db, auth, storage } from "../../plugins/firebase";
import { signOut, sendPasswordResetEmail } from "firebase/auth";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { doc, collection, addDoc, setDoc, updateDoc } from "firebase/firestore";
import { store, setUser, removeAll, setUserIconUrl } from "../../plugins/store";
import Toggle from "@vueform/toggle";
import xicon from "@/assets/xlogo.svg";
import Qrcode from "@chenfengyuan/vue-qrcode";

export default {
  data() {
    return {
      isLoad: false,
      user: {},
      userInfo: {},
      imageUrl: null,
      isAgeView: false,
      isImageView: false,
      xicon,
      myBottomPasswordSheet: false,
      resetEmail: "",
      myBottomShareSheet: false,
      myBottomNoteheet: false,
      noteProfileText: "",
      isLikeBotton: true,
      myBottomQRcodeSheet: false,
      qrcode: "",
    };
  },
  async created() {
    try {
      this.user = store.user;
      this.userInfo = store.user;
      this.nowNote = store.note;
      this.userInfo.isLikeBotton = this.userInfo.isLikeBotton
        ? this.userInfo.isLikeBotton
        : true;
    } catch (error) {
      console.log("---------- mypage/index ---------");
      console.error("Error :", error);
    }
  },
  components: {
    AppHeader,
    Toggle,
    Qrcode,
    VPullToRefresh,
  },
  mounted() {
    document.body.style.backgroundColor = "#f2f2f7";
    if (store.userIconUrl != "none") {
      if (store.userIconUrl == "") {
        this.getUserIcon();
      } else {
        this.imageUrl = store.userIconUrl;
      }
    }
  },
  unmounted() {
    // コンポーネントがアンマウントされたときに背景色を元に戻す
    document.body.style.backgroundColor = "";
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
        setUserIconUrl(url);
      } catch (error) {
        console.log("---------- mypage/index ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    chengeAgeView() {
      console.log("年齢の表示変更:::" + this.isAgeView);
    },
    chengeImageView() {
      console.log("センシティブ画像の表示変更:::" + this.isImageView);
    },
    async chengeLikeBotton() {
      const docRef = doc(db, "users", this.userInfo.uid);
      await updateDoc(docRef, {
        isLikeBotton: this.isLikeBotton,
      });
      this.userInfo.isLikeBotton = this.isLikeBotton;
      setUser(this.userInfo, this.userInfo.uid);
    },
    async logout() {
      try {
        removeAll();
        await signOut(auth);
        this.$router.replace("/");
      } catch (error) {
        console.error("ログアウト中にエラーが発生しました:", error);
      }
    },
    linkPage(url) {
      this.$router.push(url);
    },
    openExternalLink(url) {
      window.open(url, "_blank", "noopener,noreferrer");
    },
    async resetPassword() {
      try {
        await sendPasswordResetEmail(auth, this.resetEmail);
        this.myBottomPasswordSheet = false;
        this.$toast.show("パスワード再発行メールを送信しました", {
          type: "success",
          position: "bottom",
          duration: 2000,
        });
      } catch (error) {
        var errorMessage = "";
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "無効なメールアドレスです。";
            break;
          case "auth/user-not-found":
            errorMessage = "メールアドレスは見つかりませんでした。";
            break;
          default:
            errorMessage = "パスワード再発行中にエラーが発生しました。";
        }

        this.$toast.show(errorMessage, {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
      }
    },
    async setNoteProfile() {
      if (this.noteProfileText.length > 300) return;

      var noteId = "";

      const docRef = await addDoc(
        collection(db, `users/${this.user.uid}/notes`),
        {
          uid: this.user.uid,
        },
      );
      noteId = docRef.id;

      const now = new Date();
      const yyyy = now.getFullYear();
      const mm = ("00" + (now.getMonth() + 1)).slice(-2);
      const dd = ("00" + now.getDate()).slice(-2);

      var lineList = [noteId];

      await setDoc(doc(db, "users", this.userInfo.uid, "notes", noteId), {
        noteId: noteId,
        uid: this.userInfo.uid,
        userName: this.userInfo.name,
        isIconUrl: this.userInfo.isIconUrl,
        detail: this.noteProfileText,
        likeUID: [],
        tags: ["Kinkyer自己紹介"],
        tagView: "Kinkyer自己紹介",
        roomId: "0",
        createdAt: new Date(),
        createdAtView: `${yyyy}年${mm}月${dd}日`,
        isReply: false,
        replyCount: 0,
        lineHistory: lineList,
        mentions: [],
        limit: 0,
        range: "all",
        isSensitiveImageByTweet: false,
        noteIndex: 1,
        isRemove: false,
        isProfileShare: true,
        shareUser: this.userInfo,
      });

      await setDoc(doc(db, "notes", noteId), {
        noteId: noteId,
        uid: this.userInfo.uid,
        userName: this.userInfo.name,
        isIconUrl: this.userInfo.isIconUrl,
        detail: this.noteProfileText,
        likeUID: [],
        tags: ["Kinkyer自己紹介"],
        tagView: "Kinkyer自己紹介",
        roomId: "0",
        createdAt: new Date(),
        createdAtView: `${yyyy}年${mm}月${dd}日`,
        isReply: false,
        replyCount: 0,
        lineHistory: lineList,
        mentions: [],
        limit: 0,
        range: "all",
        isSensitiveImageByTweet: false,
        noteIndex: 1,
        isRemove: false,
        isProfileShare: true,
        shareUser: this.userInfo,
      });
      this.noteProfileText = "";
      this.myBottomNoteheet = false;

      this.$toast.show("プロフィールを投稿しました", {
        position: "bottom",
        duration: 2000,
      });
    },
    async setQRcode() {
      this.qrcode = `https://service.kinkyer.jp/share/profile/${this.user.uid}`;
      this.myBottomQRcodeSheet = true;
    },
    async shareProfile(sns) {
      var url = "";
      if (sns == "twitter") {
        url = `https://twitter.com/intent/tweet?text=${this.user.name}さんのプロフィールです%0dKinkyerでチェックしてみませんか？%0d&hashtags=Kinkyer%0d%0d&via=_kinkyer_&url=https%3A%2F%2Fservice.kinkyer.jp%2Fshare%2Fprofile%2F${this.user.uid}%0d`;
        window.open(url, "_blank", "noopener,noreferrer");
      } else if (sns == "line") {
        url = `https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fservice.kinkyer.jp%2Fshare%2Fprofile%2F${this.user.uid}%2F&text=${this.user.name}さんのプロフィールです`;
        window.open(url, "_blank", "noopener,noreferrer");
      } else if (sns == "facebook") {
        url = `https://www.facebook.com/sharer.php?quote=${this.user.name}さんのプロフィールです&u=https%3A%2F%2Fservice.kinkyer.jp%2Fshare%2Fprofile%2F${this.user.uid}`;
        window.open(url, "_blank", "noopener,noreferrer");
      } else if (sns == "threads") {
        url = `https://www.threads.net/intent/post?text=${this.user.name}さんのプロフィールです%0dhttps%3A%2F%2Fservice.kinkyer.jp%2Fshare%2Fprofile%2F${this.user.uid}`;
        window.open(url, "_blank", "noopener,noreferrer");
      } else if (sns == "copy") {
        await navigator.clipboard.writeText(
          `https://service.kinkyer.jp/share/profile/${this.user.uid}`,
        );
        this.$toast.show("共有URLをコピーしました", {
          position: "bottom",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.mypage {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
  background-color: #f2f2f7;
}
.page {
  padding-top: 55px;
}
.list-top {
  float: inline-start;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 10px;
  padding-bottom: 6px;
}
.list-top-icon {
  font-size: 25px;
  color: #aaa;
}
.list-end {
  float: inline-end;
  padding-top: 13px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
}
.list-toggle {
  float: inline-end;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
}
.list-end-icon {
  font-size: 14px;
  color: #aaa;
}
.list-icon {
  float: inline-start;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer-reset {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 280px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer-reset:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer-reset:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer-reset:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-reset:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
