<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="'詳細'" type="none" />
    <div class="home-timeline-detail">
      <div class="page">
        <div
          v-if="isInit"
          style="padding-top: 200px; width: 100%; text-align: center"
        >
          <VueSpinnerDots size="50" color="#784ba0" />
        </div>
        <div v-else class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div style="padding-bottom: 40px">
                <noteView
                  :data="nowNote"
                  :userData="user"
                  mode="1"
                  click="false"
                  @handle-set-query="handleSetQuery"
                  @handle-modal-view="handleModalView"
                />
              </div>
              <div
                v-for="(item, index) in nowNote.replyList"
                :key="index"
                style="margin-left: 20px"
              >
                <noteView
                  :data="item"
                  :userData="user"
                  mode="3"
                  click="true"
                  @handle-set-query="handleSetQuery"
                  @handle-modal-view="handleModalView"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter :index="1" />
    <div v-if="isModal" class="modalView">
      <span
        class="material-symbols-rounded"
        style="
          color: #fff;
          font-size: 30px;
          position: absolute;
          right: 20px;
          top: 10px;
          z-index: 99999;
        "
        @click="isModal = false"
      >
        close
      </span>
      <img :src="modalImageUrl" @click="nextModal" />
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import noteView from "@/components/timeline/noteView.vue";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      isInit: true,
      user: {},
      nowNote: {},
      isModal: false,
      modalImageUrl: "",
      modalImageIndex: 0,
      imageUrl: [],
    };
  },
  async created() {
    this.user = store.user;
    this.nowNote = store.note;

    await this.delay(100);
    this.isInit = false;
  },
  components: {
    AppHeader,
    AppFooter,
    noteView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleModalView(i, images) {
      this.isModal = true;
      this.imageUrl = images;
      this.modalImageUrl = images[i];
      this.modalImageIndex = i;
    },
    nextModal() {
      this.modalImageIndex++;
      if (this.imageUrl.length <= this.modalImageIndex) {
        this.isModal = !this.isModal;
        this.modalImageIndex = 0;
      }
      this.modalImageUrl = this.imageUrl[this.modalImageIndex];
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleSetQuery(data) {
      // eslint-disable-next-line no-unused-vars
      data = null;
    },
  },
};
</script>

<style scoped>
.home-timeline-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 100px;
}
.modalView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.669);
  z-index: 99999;
  padding: 30px;
}
.modalView img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  object-position: 50% 50% !important;
}
</style>
