<template>
  <div class="subtitle-box">
    <div class="subtitle">フェチ</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/fetishism/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div v-for="(question, i) in questions" :key="i">
    <div style="padding-left: 20px; padding-top: 8px; font-size: 14px">
      {{ question.name }}
    </div>
    <div class="line-body">
      <div
        class="line line-start"
        :style="
          question.value >= 1
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 2
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 3
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 4
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 5
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line line-end"
        :style="
          question.value >= 6
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      isMod: false,
      questions: [
        { key: "question1", value: 0, name: "匂い" },
        { key: "question2", value: 0, name: "体液・血" },
        { key: "question3", value: 0, name: "唇" },
        { key: "question4", value: 0, name: "声" },
        { key: "question5", value: 0, name: "お尻" },
        { key: "question6", value: 0, name: "胸" },
        { key: "question7", value: 0, name: "手・指" },
        { key: "question8", value: 0, name: "腕" },
        { key: "question9", value: 0, name: "太腿" },
        { key: "question10", value: 0, name: "鼠蹊部" },
        { key: "question11", value: 0, name: "腰" },
        { key: "question12", value: 0, name: "耳" },
        { key: "question13", value: 0, name: "首" },
        { key: "question14", value: 0, name: "筋肉" },
        { key: "question15", value: 0, name: "骨格・鎖骨" },
        { key: "question16", value: 0, name: "髪の毛" },
        { key: "question64", value: 0, name: "黒子（ほくろ）" },
        { key: "question65", value: 0, name: "ラバー" },
        { key: "question66", value: 0, name: "ウェット" },
        { key: "question67", value: 0, name: "ケモナー" },
        {
          key: "question68",
          value: 0,
          name: "オブジェクトフィリア（対物性愛）",
        },
      ],
      questionList: [
        "question1",
        "question2",
        "question3",
        "question4",
        "question5",
        "question6",
        "question7",
        "question8",
        "question9",
        "question10",
        "question11",
        "question12",
        "question13",
        "question14",
        "question15",
        "question16",
        "question64",
        "question65",
        "question66",
        "question67",
        "question68",
      ],
    };
  },
  props: ["data", "is_mod"],
  created() {
    this.user = this.data;
    this.isMod = this.is_mod;

    for (var q in this.questionList) {
      const item = this.questions.find(
        (item) => item.key === this.questionList[q],
      );
      item.value = this.user[this.questionList[q]];
    }
  },
  methods: {},
};
</script>

<style scoped>
.line-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3px 20px;
}
.line {
  width: calc(100% / 6);
  height: 20px;
  border: solid 1px #fff;
  background-color: rgb(225, 213, 236);
}
.line-start {
  border-radius: 4px 0 0 4px;
}
.line-end {
  border-radius: 0 4px 4px 0;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  margin-top: 30px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
a {
  color: #555 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #555 !important;
  text-decoration: underline !important;
}
</style>
