<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="false"
      :is_back="true"
      :title="personName"
      type="none"
      @header-action-click="headerActionClick"
    />
    <div class="message-peson" ref="scrollContainer" style="overflow-y: auto">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                class="text-center"
                v-if="matchingStatus == 1 && !room.isSendLiked"
              >
                <div style="font-size: 20px; padding: 20px 0">
                  いいねを送ってます
                </div>
                <div style="font-size: 14px; padding-bottom: 20px">
                  {{
                    partner.name
                  }}さんが<br />あなたのプロフィールやメッセージを見ています。<br />
                  もうちょっと返事がくるまでお待ちください。
                </div>
                <img
                  :src="require('@/assets/22139497.png')"
                  style="width: 300px"
                />
              </div>
              <div v-else>
                <div class="text-center">
                  <div v-if="person_url">
                    <img :src="person_url" class="img-user-icon" />
                  </div>
                  <div v-else>
                    <img
                      :src="require('@/assets/icon_user.webp')"
                      class="img-user-icon"
                    />
                  </div>
                  <div>{{ partner.name }}</div>
                  <div style="font-size: 12px; color: #777">
                    {{ partner.age + "歳/" + partner.sex }}
                  </div>
                  <div
                    style="
                      display: inline-block;
                      max-width: 40ch;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      font-size: 14px;
                      color: #777;
                      padding: 5px 40px;
                    "
                  >
                    {{ partner.profile }}
                  </div>
                  <div>
                    <a
                      type="button"
                      class="btn btn-kinkyer-d"
                      @click="linkUserDetail"
                    >
                      プロフィールを参照する
                    </a>
                  </div>
                  <hr />
                </div>
                <div v-for="(message, i) in messages" :key="i">
                  <div
                    v-if="message.data.uid == user.uid"
                    class="row justify-content-end"
                  >
                    <div class="col-8 text-end">
                      <div
                        class="text-start from-message-box"
                        style="white-space: pre-wrap"
                      >
                        {{ message.data.text }}
                      </div>
                    </div>
                    <div
                      style="
                        font-size: 10px;
                        padding-top: 12px;
                        display: flex;
                        justify-content: flex-end;
                        position: relative;
                        top: -20px;
                      "
                      class="text-end"
                    >
                      {{ setDateView(message.data.createdAt) }}
                    </div>
                  </div>
                  <div v-else class="row justify-content-start">
                    <div class="col-8">
                      <div style="display: flex">
                        <div v-if="person_url" style="padding: 0 4px">
                          <img
                            :src="person_url"
                            class="img-user-message-icon"
                            @click="linkUserDetail"
                          />
                        </div>
                        <div v-else style="padding: 0 4px">
                          <img
                            :src="require('@/assets/icon_user.webp')"
                            class="img-user-message-icon"
                            @click="linkUserDetail"
                          />
                        </div>
                        <div style="display: block">
                          <div
                            class="to-message-box"
                            style="white-space: pre-wrap"
                          >
                            {{ message.data.text }}
                          </div>
                          <div
                            style="
                              font-size: 10px;
                              padding-top: 12px;
                              justify-content: flex-start;
                              position: relative;
                              top: -20px;
                            "
                            class="text-start"
                          >
                            {{ setDateView(message.data.createdAt) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a id="tag">&nbsp;</a>
        </div>
      </div>
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
    <div class="footer-send">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 mx-0 px-0">
            <div
              style="background-color: #784ba0; width: 100%; padding: 5px 10px"
            >
              <div
                class="footer-container"
                style="margin-top: 6px; margin-bottom: 12px; margin-left: 6px"
              >
                <div style="width: 100%">
                  <textarea
                    v-model="sendMessage"
                    @input="adjustTextareaHeight"
                    class="form-control"
                    ref="textarea"
                    rows="1"
                    placeholder="メッセージを入力..."
                    style="
                      resize: none;
                      overflow-y: auto;
                      width: 100%;
                      border: 0;
                      height: 24px;
                    "
                  ></textarea>
                </div>
                <div
                  style="
                    width: 50px;
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 5px;
                    padding-left: 10px;
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 35px; color: #fff"
                    @click="send"
                  >
                    send
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      partner: {},
      room: {},
      room_id: "",
      personName: "",
      person_url: null,
      sendMessage: "",
      maxRows: 5,
      // 1:liked,2:like
      matchingStatus: 0,
      unsubscribe: null,
      messageCollection: null,
      messages: [],
      isScrollInit: true,
      sendCount: 0,
    };
  },
  async created() {
    this.user = store.user;
    this.room_id = this.$route.params.matching_id;
    const docRef = doc(db, "chat_room", this.room_id);
    const docSnap = await getDoc(docRef);
    this.room = docSnap.data();

    var partner_uid = "";
    if (this.room.likeUid == this.user.uid) {
      this.matchingStatus = 2;
      partner_uid = this.room.likedUid;
      this.sendCount = this.room.sendCountLike;
    } else if (this.room.likedUid == this.user.uid) {
      this.matchingStatus = 1;
      partner_uid = this.room.likeUid;
      this.sendCount = this.room.sendCountLiked;
    }

    const docUserRef = doc(db, "users", partner_uid);
    const docUserSnap = await getDoc(docUserRef);
    this.partner = docUserSnap.data();
    this.getUserIcon();
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  mounted() {
    // 初期表示時に高さを調整
    this.adjustTextareaHeight();

    this.messageCollection = collection(
      db,
      `chat_room/${this.room_id}/contents`,
    );
    const messageQuery = query(
      this.messageCollection,
      orderBy("createdAt", "asc"),
    );
    this.unsubscribe = onSnapshot(messageQuery, async (snapshot) => {
      this.messages = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          data: data,
        };
      });
    });
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    linkUserDetail() {
      setUserDetail(this.partner);
      this.$router.push({
        name: "user-detail",
      });
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.partner.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.person_url = url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    },
    headerActionClick(data) {
      if (data == "person") {
        console.log("user");
      }
    },
    scrollToBottom() {
      if (this.isScrollInit) {
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer;
          const tagElement = document.getElementById("tag");
          if (tagElement && scrollContainer) {
            // tagの位置を取得
            const tagPosition =
              tagElement.getBoundingClientRect().bottom + window.scrollY; // ページ全体のスクロール量を考慮
            // スクロール位置を設定
            window.scrollTo({
              top: tagPosition - 220, // 100px上にスクロール
              behavior: "smooth", // スムーズスクロール
            });
            this.isScrollInit = false;
          }
        });
      }
    },
    adjustTextareaHeight() {
      const el = this.$refs.textarea;

      // 改行の数に基づき行数を計算
      const lineCount = el.value.split("\n").length;

      // 行数が maxRows 以下なら高さを自動調整
      if (lineCount <= this.maxRows) {
        el.style.height = "auto"; // 一度リセットしてから
        el.style.height = el.scrollHeight + "px"; // 内容に合わせて高さ設定
      } else {
        // 行数が maxRows を超えた場合は高さを固定
        const lineHeight = 24; // 行の高さ（ピクセル）を設定
        el.style.height = `${lineHeight * this.maxRows}px`;
      }
    },
    async send() {
      if (this.matchingStatus == 1 && !this.room.isSendLiked) {
        this.$toast.show("お相手からのメッセージをお待ちください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
      if (this.sendCount < 1) {
        this.$toast.show("メッセージ上限になりました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }

      if (this.sendMessage.length > 0) {
        const docRef = doc(db, "chat_room", this.room_id);

        var c = this.sendCount - 1;
        if (this.matchingStatus == 1) {
          // liked＝自分からいいねを送った
          await updateDoc(docRef, {
            isSendLiked: true,
            sendCountLiked: c,
          });
        } else {
          // liked＝相手からいいねをもらった
          await updateDoc(docRef, {
            isSendLiked: true,
            sendCountLike: c,
          });
        }
        this.sendCount = c;

        await addDoc(collection(db, `chat_room/${this.room_id}/contents`), {
          uid: this.user.uid,
          name: this.user.name,
          createdAt: new Date(),
          id: "",
          text: this.sendMessage,
          url: null,
          width: null,
        });
        this.sendMessage = "";
        const el = this.$refs.textarea;
        el.style.height = "24px";
      }
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.message-peson {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 70px;
  padding-bottom: 70px;
}
.footer-send {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer-container {
  display: flex;
}
.from-message-box {
  margin: 0 0 10px 0;
  padding: 6px 15px 12px 15px;
  border: 0px;
  border-radius: 10px 0 10px 10px;
  color: #fff;
  background: -moz-linear-gradient(-135deg, #784ba0, #885dab);
  background: -webkit-linear-gradient(-135deg, #784ba0, #885dab);
  background: linear-gradient(-135deg, #784ba0, #885dab);
  background-color: #784ba0;
  word-wrap: break-word;
  float: right;
  max-width: 100%;
}
.to-message-box {
  margin: 10px 0 20px 0;
  padding: 6px 15px 12px 15px;
  border: solid 1px #ccc;
  border-radius: 0 10px 10px 10px;
  word-wrap: break-word;
  float: left;
  max-width: 100%;
}
.img-user-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #ddd;
}
.img-user-message-icon {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #ddd;
}
.btn-kinkyer-d {
  border: solid 1px #adadad;
  background-color: #adadad;
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 20px;
}

.btn-kinkyer-d:hover {
  background-color: #adadad;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-d:active {
  background-color: #adadad;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-d:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-d:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #adadad;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
