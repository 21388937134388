<template>
  <div class="subtitle-box">
    <div class="subtitle">BDSM</div>
    <div class="subtitle text-end">
      <div v-if="isMod">
        <router-link to="/user/edit/bdsm/mod"> 編集 </router-link>
      </div>
    </div>
  </div>
  <div v-for="(question, i) in questions" :key="i">
    <div style="padding-left: 20px; padding-top: 8px; font-size: 14px">
      {{ question.name }}
    </div>
    <div class="line-body">
      <div
        class="line line-start"
        :style="
          question.value >= 1
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 2
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 3
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 4
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line"
        :style="
          question.value >= 5
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
      <div
        class="line line-end"
        :style="
          question.value >= 6
            ? 'background-color: rgb(120, 75, 160)'
            : 'background-color: rgb(225, 213, 236)'
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      isMod: false,
      questions: [
        { key: "question17", value: 0, name: "サディスト" },
        { key: "question18", value: 0, name: "マゾヒスト" },
        { key: "question19", value: 0, name: "ディシプリン" },
        { key: "question20", value: 0, name: "主（ダディ・マミー）" },
        { key: "question21", value: 0, name: "従（リトル・全てを委ねたい人）" },
        { key: "question22", value: 0, name: "リガー（縛り手・拘束する人）" },
        { key: "question23", value: 0, name: "ロープバニー（受け手）" },
        { key: "question24", value: 0, name: "ドミナント" },
        { key: "question25", value: 0, name: "サブミッシブ" },
        { key: "question26", value: 0, name: "ブラットテイマー" },
        { key: "question27", value: 0, name: "ブラット" },
        { key: "question28", value: 0, name: "プライマルハンター（狩人）" },
        {
          key: "question29",
          value: 0,
          name: "プライマルプリ（本能的なプレイを好む獲物側）",
        },
        {
          key: "question30",
          value: 0,
          name: "デグレーダ（人間扱いをしない・モノ扱いする人）",
        },
        {
          key: "question31",
          value: 0,
          name: "デグレード（人間家具やモノ扱いされる人）",
        },
        { key: "question32", value: 0, name: "マスター" },
        { key: "question33", value: 0, name: "スレーブ（奴隷）" },
        { key: "question34", value: 0, name: "オーナー" },
        { key: "question35", value: 0, name: "ペット" },
      ],
      questionList: [
        "question17",
        "question18",
        "question19",
        "question20",
        "question21",
        "question22",
        "question23",
        "question24",
        "question25",
        "question26",
        "question27",
        "question28",
        "question29",
        "question30",
        "question31",
        "question32",
        "question33",
        "question34",
        "question35",
      ],
    };
  },
  props: ["data", "is_mod"],
  created() {
    this.user = this.data;
    this.isMod = this.is_mod;

    for (var q in this.questionList) {
      const item = this.questions.find(
        (item) => item.key === this.questionList[q],
      );
      item.value = this.user[this.questionList[q]];
    }
  },
  methods: {},
};
</script>

<style scoped>
.line-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3px 20px;
}
.line {
  width: calc(100% / 6);
  height: 20px;
  border: solid 1px #fff;
  background-color: rgb(225, 213, 236);
}
.line-start {
  border-radius: 4px 0 0 4px;
}
.line-end {
  border-radius: 0 4px 4px 0;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  margin-top: 30px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
a {
  color: #555 !important;
  text-decoration: underline !important;
}
a:focus {
  color: #555 !important;
  text-decoration: underline !important;
}
</style>
