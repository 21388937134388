<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="false"
      :is_back="true"
      :title="personName"
      type="none"
      @header-action-click="headerActionClick"
    />
    <div class="message-peson">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div></div>
              <div class="row justify-content-start">
                <div style="flex: 0 0 auto; width: 60px">
                  <div v-if="person_url">
                    <img :src="person_url" class="img-user-icon" />
                  </div>
                  <div v-else>
                    <img
                      :src="require('@/assets/icon_user.webp')"
                      class="img-user-icon"
                    />
                  </div>
                </div>
                <div class="col-7">
                  <div style="font-size: 12px">{{ partner.name }}</div>
                  <div class="to-message-box">message</div>
                </div>
              </div>
              <div class="row justify-content-start">
                <div style="flex: 0 0 auto; width: 60px">
                  <div v-if="person_url">
                    <img :src="person_url" class="img-user-icon" />
                  </div>
                  <div v-else>
                    <img
                      :src="require('@/assets/icon_user.webp')"
                      class="img-user-icon"
                    />
                  </div>
                </div>
                <div class="col-7">
                  <div style="font-size: 12px">{{ partner.name }}</div>
                  <div class="to-message-box">
                    messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8 text-end">
                  <div class="text-start from-message-box">
                    messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8 text-end">
                  <div class="text-start from-message-box">messagemessag</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
    <div class="footer-send">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 mx-0 px-0">
            <div
              style="background-color: #784ba0; width: 100%; padding: 5px 10px"
            >
              <div
                class="footer-container"
                style="margin-top: 6px; margin-bottom: 12px"
              >
                <div style="width: 100%">
                  <textarea
                    v-model="sendMessage"
                    @input="adjustTextareaHeight"
                    class="form-control"
                    ref="textarea"
                    rows="1"
                    placeholder="メッセージを入力..."
                    style="
                      resize: none;
                      overflow-y: auto;
                      width: 100%;
                      border: 0;
                      height: 24px;
                    "
                  ></textarea>
                </div>
                <div
                  style="
                    width: 50px;
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 5px;
                    padding-left: 10px;
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 35px; color: #fff"
                  >
                    send
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      partner: {},
      room: {},
      room_id: "",
      personName: "",
      person_url: null,
      sendMessage: "",
      maxRows: 5,
      // 1:liked,2:like
      matchingStatus: 0,
    };
  },
  async created() {
    this.user = store.user;
    this.room_id = this.$route.params.matching_id;
    const docRef = doc(db, "chat_room", this.room_id);
    const docSnap = await getDoc(docRef);
    this.room = docSnap.data();
    console.log(this.room);

    var partner_uid = "";
    if (this.room.likeUid == this.user.uid) {
      this.matchingStatus = 2;
      partner_uid = this.room.likedUid;
    } else if (this.room.likedUid == this.user.uid) {
      this.matchingStatus = 1;
      partner_uid = this.room.likeUid;
    }

    const docUserRef = doc(db, "users", partner_uid);
    const docUserSnap = await getDoc(docUserRef);
    this.partner = docUserSnap.data();
    this.getUserIcon();
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  mounted() {
    // 初期表示時に高さを調整
    this.adjustTextareaHeight();
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.partner.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.person_url = url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    },
    headerActionClick(data) {
      if (data == "person") {
        console.log("user");
      }
    },
    adjustTextareaHeight() {
      const el = this.$refs.textarea;

      // 改行の数に基づき行数を計算
      const lineCount = el.value.split("\n").length;

      // 行数が maxRows 以下なら高さを自動調整
      if (lineCount <= this.maxRows) {
        el.style.height = "auto"; // 一度リセットしてから
        el.style.height = el.scrollHeight + "px"; // 内容に合わせて高さ設定
      } else {
        // 行数が maxRows を超えた場合は高さを固定
        const lineHeight = 24; // 行の高さ（ピクセル）を設定
        el.style.height = `${lineHeight * this.maxRows}px`;
      }
    },
  },
};
</script>

<style scoped>
.message-peson {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 70px;
  padding-bottom: 70px;
}
.footer-send {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer-container {
  display: flex;
}
.from-message-box {
  margin: 10px 0;
  padding: 6px 10px 12px 10px;
  border: 0px;
  border-radius: 10px 0 10px 10px;
  color: #fff;
  background-color: #784ba0;
  word-wrap: break-word;
  float: right;
  max-width: 100%;
}
.to-message-box {
  margin: 3px 0 10px 0;
  padding: 6px 10px 12px 10px;
  border: solid 1px #ccc;
  border-radius: 0 10px 10px 10px;
  word-wrap: break-word;
  float: left;
  max-width: 100%;
}
.img-user-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #ddd;
}
</style>
