<template>
  <div v-if="imageUrl" @click="linkUser">
    <img :src="imageUrl" />
  </div>
  <div v-else @click="linkUser">
    <img :src="require('@/assets/icon_user.webp')" />
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      imageUrl: null,
      userId: "",
      isIconUrl: false,
    };
  },
  props: ["uid", "is_icon"],
  created() {
    this.userId = this.uid;
    this.isIconUrl = this.is_icon == undefined ? true : this.is_icon;
    if (this.isIconUrl) {
      this.getUserIcon();
    }
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.userId}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      const docUserRef = doc(db, "users", this.userId);
      const docUserSnap = await getDoc(docUserRef);
      setUserDetail(docUserSnap.data());
      this.$router.push({
        name: "user-detail",
      });
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
